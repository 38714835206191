import {
  MINIMUM_HEATCALENDAR_LENGTH_DAYS,
  TOTAL_HEAT_LEVELS,
} from "./constants";

// returns a new date shifted a certain number of days (can be negative)
export function shiftDate(date: string | Date, numDays: number) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

export function getBeginningTimeForDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

// obj can be a parseable string, a millisecond timestamp, or a Date object
export function convertToDate(obj: string | Date) {
  return obj instanceof Date ? obj : new Date(obj);
}

export function getRange(count: number) {
  const arr = [];
  for (let idx = 0; idx < count; idx += 1) {
    arr.push(idx);
  }

  return arr;
}

export function toHeatCalendarDateFormat(date: string | Date): string {
  const d = new Date(date);
  return d.toISOString().split("T")[0] as string;
}

export function getOneYearAhead(date: string | Date) {
  const oneYearAgo = new Date(date);
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() + 1);
  return oneYearAgo;
}

export function getOneYearAgo(date: string | Date) {
  const oneYearAgo = new Date(date);
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  return oneYearAgo;
}

export function calculateStartDateAsOneYearAgo(endDate: Date) {
  return getOneYearAgo(endDate);
}

export function calculateStartDateAsThreeMonthAgo(endDate: Date) {
  return new Date(
    new Date(endDate).setDate(
      endDate.getDate() - MINIMUM_HEATCALENDAR_LENGTH_DAYS
    )
  );
}

// ranges is strictly a 4 number array
export type HeatRanges = [number, number, number, number];

// given a maxCount we want 4 distinct ranges
export function getHeatLevelRanges(maxCount: number): HeatRanges {
  const ranges: HeatRanges = [0, 0, 0, 0];

  const rangeStep = maxCount / TOTAL_HEAT_LEVELS;

  for (let i = 0; i < TOTAL_HEAT_LEVELS; i++) {
    // make it ceil so theres no decimals in the ranges
    ranges[i] = Math.ceil(rangeStep * i);
  }

  return ranges;
}

export function getHeatLevelBasesOnCount(count: number, ranges: HeatRanges) {
  if (count >= ranges[3]) {
    return "day-lvl4";
  }
  if (count >= ranges[2]) {
    return "day-lvl3";
  }
  if (count >= ranges[1]) {
    return "day-lvl2";
  }
  if (count > 0) {
    return "day-lvl1";
  }
  return "";
}
