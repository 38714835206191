import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StateSlice<T> {
  data: T[];
  isDataPending: boolean;
  dataFetchError: string | null;
  hasNextPage: boolean;
}

export function createAsyncArrayFetchSlice<T, QUERY_PARAMS>(name: string) {
  const initialState: StateSlice<T> = {
    data: [],
    isDataPending: false,
    dataFetchError: null,
    hasNextPage: false,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      fetchData(_state, _action: PayloadAction<Partial<QUERY_PARAMS>>) {
        //empty handled by saga
      },
      setIsPending(state) {
        state.isDataPending = true;
        state.dataFetchError = null;
      },
      setFetchError(state, action: PayloadAction<string | undefined>) {
        state.isDataPending = false;
        state.dataFetchError = action.payload || "Something went wrong";
      },
      setFetchSuccess(state, action: PayloadAction<T[]>) {
        state.isDataPending = false;
        state.data = action.payload as Draft<T[]>;
        state.dataFetchError = null;
      },
      setHasNextPage(state, action: PayloadAction<boolean>) {
        state.hasNextPage = action.payload;
      },
    },
  });
}
