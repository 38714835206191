import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { NotificationDigestPreferenceSetListResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { ArticlesSettings } from "../types";
import { fetchArticlesSettingsApi, patchArticlesSettingsApi } from "./api/api";

interface StateSlice {
  articlesSettings: ArticlesSettings | null;
  isPending: boolean;
  fetchError: string | null;

  isPatchPending: boolean;
}
const initialState: StateSlice = {
  articlesSettings: null,
  isPending: false,
  fetchError: null,

  isPatchPending: false,
};

const articlesSettingsSlice = createSlice({
  name: "articlesSettings",
  initialState: initialState,
  reducers: {
    fetchArticlesSettings() {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<ArticlesSettings>) {
      state.isPending = false;
      state.articlesSettings = action.payload;
      state.fetchError = null;
    },
    patchArticlesSettings(_state, _action: PayloadAction<ArticlesSettings>) {
      //empty handled by saga
    },
    setIsPatchPending(state, action: PayloadAction<boolean>) {
      state.isPatchPending = action.payload;
    },
  },
});

export default articlesSettingsSlice.reducer;

export const {
  fetchArticlesSettings,
  setPending,
  setFetchError,
  fetchSuccess,
  patchArticlesSettings,
  setIsPatchPending,
} = articlesSettingsSlice.actions;

// Async stuff - sagas

function* fetchArticlesSettingsSaga() {
  yield put(setPending());
  try {
    const response: AxiosResponse<NotificationDigestPreferenceSetListResponse> =
      yield call(fetchArticlesSettingsApi);

    yield put(fetchSuccess(response.data.payload as ArticlesSettings));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* patchArticlesSettingsSaga(action: PayloadAction<ArticlesSettings>) {
  try {
    yield put(setIsPatchPending(true));
    yield call(patchArticlesSettingsApi, action.payload);

    // refetch the profile after a successful update
    yield put(fetchArticlesSettings());
    yield put(setIsPatchPending(false));
  } catch (e) {
    yield put(setIsPatchPending(false));
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchArticlesSettings.toString(), fetchArticlesSettingsSaga);
  yield takeLatest(patchArticlesSettings.toString(), patchArticlesSettingsSaga);
}

export function* articlesSettingsSagas() {
  yield all([actionWatcher()]);
}
