import React, { ReactElement, useCallback, useState } from "react";
import {
  Button,
  CheckBox,
  GenericFormError,
  HighlightSubString,
  Input,
  Skeleton,
  Stack,
} from "@csis.com/components";
import { areArraysEqualSets } from "@csis.com/components/src/utils/utils";
import { checkIfTextContainsSubstring } from "../../HighlightSubString/utils/utils";
import ListMenuBody from "../../ListMenu/ListMenuBody";
import ListMenuItem from "../../ListMenu/ListMenuItem";
import { DropdownOption } from "../Dropdown";
import { isAllOptionsChecked } from "../utils";

function getCheckBoxTitle(text: string, searchString: string) {
  return <HighlightSubString text={text} searchString={searchString} />;
}
export interface MultiSelectListInterface<T> {
  name: string;
  options: DropdownOption<T>[];
  values?: T[];
  onOptionsSelected: (values: T[], e?: React.MouseEvent) => void;
  isSearchable?: boolean;
  dataTestId?: string;
  internalDropdown?: ReactElement;
  isLoading?: boolean;
  error?: string | null;
  onRetry?: () => void;
}

const MultiSelectList = <T,>({
  name,
  options,
  onOptionsSelected,
  isSearchable,
  values,
  dataTestId,
  internalDropdown,
  isLoading,
  error,
  onRetry,
}: MultiSelectListInterface<T>) => {
  const [stateValues, setStateValues] = useState<T[]>(values || []);
  const [searchString, setSearchString] = useState("");

  const handleClearAllClick = () => {
    setStateValues([]);
  };

  const handleSelectAllClick = () => {
    if (isAllOptionsChecked(options, stateValues)) {
      setStateValues([]);
    } else {
      setStateValues(options.map((option) => option.value));
    }
  };

  const handleCheckBoxClick = (value?: T) => {
    if (stateValues && Array.isArray(stateValues) && value) {
      if (stateValues.includes(value)) {
        setStateValues(
          stateValues.filter((stateValue) => stateValue !== value)
        );
      } else {
        setStateValues([...stateValues, value]);
      }
    }
  };

  const handleButtonClick = useCallback(() => {
    if (stateValues && Array.isArray(stateValues)) {
      onOptionsSelected(stateValues);
    }
  }, [onOptionsSelected, stateValues]);

  const renderListBody = () => {
    if (error) {
      return (
        <div className="dropdown__list__information-content">
          <Stack isVertical align="center" gutterSize="huge">
            <GenericFormError errorText={error} />
            {onRetry && (
              <Button
                name={`${name}-retry-btn`}
                dataTestId={`${name}-retry-btn`}
                text="retry"
                type="text"
                onButtonClick={onRetry}
              />
            )}
          </Stack>
        </div>
      );
    } else if (isLoading) {
      return (
        <div className="dropdown__list__information-content">
          <Stack isVertical align="stretch" gutterSize="big">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Stack>
        </div>
      );
    } else {
      return (
        <>
          {options
            .filter((option) =>
              checkIfTextContainsSubstring(String(option.label), searchString)
            )
            .map((option) => (
              <ListMenuItem key={String(option.value)}>
                <CheckBox
                  name={`${name}-${option.label}-${option.value}-checkbox`}
                  isDisabled={option.isDisabled}
                  onClick={() => handleCheckBoxClick(option.value)}
                  title={getCheckBoxTitle(String(option.label), searchString)}
                  isChecked={stateValues.indexOf(option.value) !== -1}
                  dataTestId={`${dataTestId}-${option.value}-checkbox`}
                />
              </ListMenuItem>
            ))}
        </>
      );
    }
  };

  return (
    <>
      <div className={"dropdown__list__check-all"}>
        <CheckBox
          name={`${name}-checkbox-select-all`}
          isChecked={isAllOptionsChecked(options, stateValues)}
          onClick={handleSelectAllClick}
          title={"Select All"}
        />
        <div
          onClick={handleClearAllClick}
          className={"dropdown__clear-selection"}
        >
          Clear
        </div>
      </div>
      {isSearchable && (
        <div className="dropdown__list__search">
          <Input
            name="dropdown-search"
            size="small"
            value={searchString}
            onChange={setSearchString}
            isFullWidth
            icon="global_search"
            autoFocus
            placeholder="Search..."
          />
        </div>
      )}
      {internalDropdown && (
        <div className="dropdown__list__internal-dropdown">
          {React.cloneElement(internalDropdown, {
            shouldRenderAsDescendant: true,
          })}
        </div>
      )}
      <ListMenuBody>
        {!isLoading && !error && options.length === 0 ? (
          <div className="dropdown__no-options">No entries found</div>
        ) : (
          renderListBody()
        )}
      </ListMenuBody>

      <div className={"dropdown__list__check-btn"}>
        <Button
          onButtonClick={handleButtonClick}
          size="small"
          name="dropdown-apply-filters-btn"
          isDisabled={Boolean(
            values &&
              Array.isArray(values) &&
              areArraysEqualSets(stateValues, values)
          )}
          isFullWidth
          text="Apply"
          dataTestId={`${dataTestId}-apply_button`}
        />
      </div>
    </>
  );
};

export default MultiSelectList;
