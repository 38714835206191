import React from "react";
import logo from "@csis.com/tip/src/img/logo.png";

export interface LoadingInterface {}
const Loading: React.FC<LoadingInterface> = () => {
  return (
    <div className={"loading"}>
      <div>
        <img src={logo} alt="logo" />
        <div className="loading__loader">
          <div className="loading__bar" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
