import { ColumnInterface } from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { ticketsKeys } from "../constants";
import { TicketsKey } from "../types";

export type TicketsColumns = ColumnInterface<TicketsKey, TranslationKey>[];

export const columns: TicketsColumns = [
  { key: ticketsKeys.TITLE, name: "title", isSortable: true },
  {
    key: ticketsKeys.STATUS,
    name: "status",
    isSortable: true,
    isCenterAligned: true,
  },
  {
    key: ticketsKeys.SEVERITY,
    name: "severity",
    isSortable: true,
    isCenterAligned: true,
  },
  { key: ticketsKeys.TYPE, name: "type", isSortable: true },
  { key: ticketsKeys.FLAGS, name: "flag" },

  { key: ticketsKeys.UPDATED, name: "last_updated", isSortable: true },
  {
    key: ticketsKeys.PARTICIPANTS,
    name: "ticket_participants",
  },
  { key: ticketsKeys.DISPLAY_ID, name: "id", isSortable: true, isHidden: true },
  {
    key: ticketsKeys.CREATED,
    name: "created",
    isSortable: true,
    isHidden: true,
  },
  { key: ticketsKeys.CLOSED, name: "closed", isSortable: true, isHidden: true },
];
