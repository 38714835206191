import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  CustomerFull,
  ListResponseCustomerFull,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { fetchCustomerApi } from "./api/api";

interface StateSlice {
  customer: CustomerFull[] | null;
  isFetchCustomerPending: boolean;
  getCustomerFetchError: string | null;
}
const initialState: StateSlice = {
  customer: null,
  isFetchCustomerPending: false,
  getCustomerFetchError: null,
};

const usageSlice = createSlice({
  name: "usage",
  initialState: initialState,
  reducers: {
    fetchCustomer() {
      //empty handled by saga
    },
    setIsFetchCustomerPending(state) {
      state.isFetchCustomerPending = true;
      state.getCustomerFetchError = null;
    },
    setCustomerFetchError(state, action: PayloadAction<string>) {
      state.isFetchCustomerPending = false;
      state.getCustomerFetchError = action.payload;
    },
    setFetchCustomerSuccess(state, action: PayloadAction<CustomerFull[]>) {
      state.isFetchCustomerPending = false;
      state.customer = action.payload;
      state.getCustomerFetchError = null;
    },
  },
});

export default usageSlice.reducer;

export const {
  fetchCustomer,
  setIsFetchCustomerPending,
  setCustomerFetchError,
  setFetchCustomerSuccess,
} = usageSlice.actions;

// Async stuff - sagas

function* fetchCustomerSaga() {
  yield put(setIsFetchCustomerPending());

  try {
    const response: AxiosResponse<ListResponseCustomerFull> = yield call(
      fetchCustomerApi
    );
    yield put(setFetchCustomerSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setCustomerFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchCustomer.toString(), fetchCustomerSaga);
}

export function* usageSagas() {
  yield all([actionWatcher()]);
}
