import classNames from "classnames";
import { ReactNode, memo, useCallback } from "react";
import Icon from "../../../icons/Icon";
import Stack from "../../../layout/Stack/Stack";
import { Cell } from "../types";

export interface BookmarkableCellInterface {
  isBookmarked?: boolean;
  onClick?: () => void;
  cellContent: Cell | ReactNode;
}

const BookmarkableCell = ({
  isBookmarked,
  onClick,
  cellContent,
}: BookmarkableCellInterface) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onClick) {
        onClick();
      }
    },
    [onClick]
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (event.key === "Enter" && onClick) {
        onClick();
      }
    },
    [onClick]
  );

  const classes = classNames("table__bookmark-icon");

  return (
    <td className="cell">
      <Stack gutterSize="big" align="center">
        <div
          onKeyPress={handleKeyPress}
          onClick={handleClick}
          className={classes}
          tabIndex={0}
          role={"button"}
          aria-label="bookmark"
        >
          <Icon size="large" kind={isBookmarked ? "star" : "star_outline"} />
        </div>
        <span className="table__bookmark-text">{String(cellContent)}</span>
      </Stack>
    </td>
  );
};

export default memo(BookmarkableCell);
