import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { InvestigationsResponse } from "../Search//api/types";
import { fetchInvestigationApi } from "../Search/api/api";
import { investigationsKeys } from "../Search/constants";
import { Investigation, QueryParams } from "../Search/types";

interface StateSlice {
  threatCloudInvestigations: Investigation[] | null;
  isPending: boolean;
  fetchError: string | null;
}
const initialState: StateSlice = {
  threatCloudInvestigations: null,
  isPending: false,
  fetchError: null,
};

const threatCloudInvestigationsSlice = createSlice({
  name: "threatCloudInvestigations",
  initialState: initialState,
  reducers: {
    fetchThreatCloudInvestigations(
      _state,
      _action: PayloadAction<{
        queryParams: QueryParams;
        onlyExternallyVisible?: boolean;
      }>
    ) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
      state.threatCloudInvestigations = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
      state.threatCloudInvestigations = null;
    },
    fetchSuccess(state, action: PayloadAction<Investigation[]>) {
      state.isPending = false;
      state.threatCloudInvestigations = action.payload;
      state.fetchError = null;
    },
    resetState(state) {
      state.isPending = false;
      state.threatCloudInvestigations = null;
      state.fetchError = null;
    },
  },
});

export default threatCloudInvestigationsSlice.reducer;

export const {
  fetchThreatCloudInvestigations,
  setPending,
  setFetchError,
  fetchSuccess,
  resetState,
} = threatCloudInvestigationsSlice.actions;

// Async stuff - sagas
function* fetchInvestigationsSaga(
  action: PayloadAction<{
    queryParams: QueryParams;
    onlyExternallyVisible?: boolean;
  }>
) {
  yield put(setPending());
  if (!action.payload.queryParams[investigationsKeys.ENTITY_TYPE]) {
    yield put(setFetchError("Please specify entity type"));
  } else {
    try {
      const response: AxiosResponse<InvestigationsResponse> = yield call(
        fetchInvestigationApi,
        action.payload.queryParams,
        action.payload.onlyExternallyVisible
      );

      yield put(fetchSuccess(response.data.payload));
    } catch (e) {
      const errorMessage = handleRequestError(e);
      yield put(setFetchError(errorMessage));
    }
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchThreatCloudInvestigations.toString(),
    fetchInvestigationsSaga
  );
}

export function* threatCloudInvestigationsSagas() {
  yield all([actionWatcher()]);
}
