import { LARGE, SMALL } from "../../sizes";

export const getIconSize = (buttonSize?: SMALL | LARGE) => {
  if (buttonSize === "small") {
    return "normal";
  }
  if (buttonSize === "large") {
    return "large";
  }
  return "big";
};
