import { FieldError } from "../openapi/data-contracts";

export type StringError = string;
export interface ErrorResponse {
  success: false;
  errors: (FieldError | StringError)[];
}

export const isCsisErrorResponse = (x: any): x is ErrorResponse =>
  (x as ErrorResponse).success === false &&
  (x as ErrorResponse).errors &&
  (x as ErrorResponse).errors.length > 0;
