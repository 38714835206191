import classNames from "classnames";
import React, { useRef } from "react";
import InlineFormError from "../Form/InlineFormError";
import IconButton from "../IconButton/IconButton";
import { AttachedFile } from "./types";

export interface InputFileInterface {
  isDisabled?: boolean;
  errorText?: string | null;
  isPending?: boolean;
  fileTypes?: string;
  maxFileSize?: number;
  name: string;
  files?: AttachedFile[];
  onFilesUpload?: (files: FileList) => void;
  onFileRemove?: (file: string) => void;
}

const InputFile: React.FC<InputFileInterface> = ({
  isDisabled,
  errorText,
  isPending,
  fileTypes,
  maxFileSize = 10,
  files,
  name,
  onFilesUpload,
  onFileRemove,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const classes = classNames("inputfile", {
    "inputfile--disabled": isDisabled,
  });

  const onHandleFilesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFilesUpload) {
      const target = event.target;
      const files = target.files;
      if (files) {
        onFilesUpload(files);
      }
    }
  };

  const onHandleFileRemove = (file: string) => {
    if (onFileRemove) {
      onFileRemove(file);
    }
  };

  // when the file input button is pressed, we want to trigger the upload dialog
  const handleKeyPress = React.useCallback((event: React.KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.key === " ") {
      ref.current?.click();
    }
  }, []);

  return (
    <div className={classes}>
      <label htmlFor="fileupload">
        <input
          ref={ref}
          id="fileupload"
          disabled={isDisabled}
          name={name}
          aria-label={"file-input"}
          type={"file"}
          accept={fileTypes}
          multiple={true}
          onChange={onHandleFilesUpload}
        />
        <div
          role="button"
          tabIndex={0}
          aria-controls="fileupload"
          className="inputfile__button"
          onKeyPress={handleKeyPress}
        >
          Choose file
        </div>
        {(!files || files.length === 0) && (
          <div className="inputfile__text">No file(s) selected</div>
        )}
      </label>
      {files &&
        files.map((file) => (
          <div
            className="inputfile__uploaded-file"
            data-test-id={"input-file-file-" + file.id}
          >
            {file.filename}
            <IconButton
              onButtonClick={() => onHandleFileRemove(file.id)}
              type="text"
              color="grey"
              isCircle
              icon={"close"}
              dataTestId={"input-file-remove-file-" + file.id}
            />
          </div>
        ))}
      {isPending && (
        <div className="f_tiny" data-test-id="file-uploading-indicator">
          Uploading...
        </div>
      )}
      <div className="inputfile__subtext">
        max. {maxFileSize} MB (press Ctrl+click to select multiple files)
      </div>
      <InlineFormError errorText={errorText} />
    </div>
  );
};

export default InputFile;
