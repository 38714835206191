import classNames from "classnames";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import Table from "../../atoms/Table/Table";
import {
  ColumnInterface,
  OnRowClick,
  RowInterface,
} from "../../atoms/Table/types";
import { LARGE, NORMAL, SMALL } from "../../sizes";
import ChartError from "../ChartError/ChartError";
import NoData from "../NoData/NoData";
import { formatBarCellWithMaxWidth } from "./formatters";
import { getFormattersForGraphColumns, getMaxValuePerColumn } from "./utils";

export interface TableBarChartInterface<ROW extends RowInterface> {
  name: string;
  rows: ROW[];
  columns: ColumnInterface<string, string>[];
  // these are the columns that have a "bar" inside the cell / see examples
  // by design the values must be 0 or >0
  graphColumns: string[];
  onRowClick?: OnRowClick<ROW>;
  isFullWidth?: boolean;
  size?: SMALL | NORMAL | LARGE;
  isLoading?: boolean;
  error?: string;
}

const TableBarChart = <T extends RowInterface>({
  name,
  rows,
  columns,
  graphColumns,
  onRowClick,
  isFullWidth,
  size = "normal",
  isLoading,
  error,
}: TableBarChartInterface<T>) => {
  const maxValuesPerColumn = getMaxValuePerColumn(rows, graphColumns);

  const formatters = getFormattersForGraphColumns(
    graphColumns,
    formatBarCellWithMaxWidth(maxValuesPerColumn)
  );

  const classes = classNames("tablebarchart", {
    "tablebarchart--full-width": isFullWidth,
  });

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Skeleton type="chart" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <ChartError error={error} />
      </div>
    );
  }

  return rows && rows.length ? (
    <div className={classes}>
      <Table
        columns={columns}
        rows={rows}
        name={name}
        formatters={formatters}
        size={size}
        isLoading={isLoading}
        ghostRows={5}
        hasUnlimitedCellWidth
        onRowClick={onRowClick}
      />
    </div>
  ) : (
    <div style={{ height: "100%", width: "100%" }}>
      <NoData />
    </div>
  );
};

export default TableBarChart;
