import classNames from "classnames";
import React from "react";
import { BIG, LARGE, NORMAL, SMALL } from "../../../sizes";

const Spinner: React.FC<{
  size?: SMALL | NORMAL | BIG | LARGE;
  color?: "csis";
}> = ({ size = "normal", color }) => {
  const classes = classNames("spinner", {
    "spinner--small": size === "small",
    "spinner--normal": size === "normal",
    "spinner--big": size === "big",
    "spinner--large": size === "large",
    "spinner--csis": color === "csis",
  });

  return <div className={classes} />;
};

export default Spinner;
