import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";
import { getNewColumnsBasedOnSort } from "../../components/shared/DataTableContainer/utils";
import { ReportsColumns } from "./ReportsList/columns";

export const getReports = (state: RootState) => state.reportsReducer.reports;

export const getIsReportsPending = (state: RootState) =>
  state.reportsReducer.isPending;

export const getReportsFetchError = (state: RootState) =>
  state.reportsReducer.fetchError;

export const getReportsResult = createSelector(
  [getReports, getIsReportsPending, getReportsFetchError],
  (reports, isPending, fetchError) => {
    return { reports, isPending, fetchError };
  }
);

export const getHasNextPage = (state: RootState) =>
  state.reportsReducer.hasNextPage;

export const getDownloadReportFetchError = (state: RootState) =>
  state.reportsReducer.reportDownloadError;

export const getDownloadReportPendingStatus = (state: RootState) =>
  state.reportsReducer.reportDownloadStatus;

export const getDeleteReportFetchError = (state: RootState) =>
  state.reportsReducer.reportDeleteError;

export const getDeleteReportPendingStatus = (state: RootState) =>
  state.reportsReducer.reportDeletePending;

export const getDeleteReportSuccess = (state: RootState) =>
  state.reportsReducer.reportDeleteSuccess;

export const getGenerateReportSuccess = (state: RootState) =>
  state.reportsReducer.generateReportSuccess;

export const getIsGenerateReportPending = (state: RootState) =>
  state.reportsReducer.isGenerateReportPending;

export const getGenerateReportError = (state: RootState) =>
  state.reportsReducer.generateReportError;

export const getGenerateReportsResult = createSelector(
  [
    getGenerateReportSuccess,
    getIsGenerateReportPending,
    getGenerateReportError,
  ],
  (generateReportSuccess, isGenerateReportPending, generateReportError) => {
    return {
      generateReportSuccess,
      isGenerateReportPending,
      generateReportError,
    };
  }
);

export const getReportsColumns = (state: RootState) =>
  state.reportsReducer.columns;

export const getCalculatedColumns = (
  state: RootState,
  sort?: string
): ReportsColumns => {
  const columns = getReportsColumns(state);
  return getNewColumnsBasedOnSort(columns, sort);
};
