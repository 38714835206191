import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ListResponseRequestOption,
  RequestOption,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { axiosCsisApi } from "../../../App";

interface StateSlice {
  newReportOptions: RequestOption[];
}
const initialState: StateSlice = {
  newReportOptions: [],
};

const newReportOptionsSlice = createSlice({
  name: "newReportOptions",
  initialState: initialState,
  reducers: {
    fetchNewReportOptions() {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<RequestOption[]>) {
      state.newReportOptions = action.payload;
    },
  },
});

export default newReportOptionsSlice.reducer;

export const { fetchNewReportOptions, fetchSuccess } =
  newReportOptionsSlice.actions;

// Async stuff - sagas

function* fetchNewReportOptionsSaga() {
  try {
    const response: AxiosResponse<ListResponseRequestOption> = yield call(
      axiosCsisApi.getRequestOptionApi20ReportRequestOptionGet
    );

    const newReportOptions = response.data.payload;
    yield put(fetchSuccess(newReportOptions));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchNewReportOptions.toString(), fetchNewReportOptionsSaga);
}

export function* newReportOptionsSagas() {
  yield all([actionWatcher()]);
}
