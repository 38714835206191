import { columns as alertsColumns } from "@csis.com/tip/src/pages/Alerts/AlertsSearch/Table/columns";
import { columns as cardsColumns } from "@csis.com/tip/src/pages/Products/CompromisedData/CreditCards/Search/Table/columns";
import { columns as credentialsColumns } from "@csis.com/tip/src/pages/Products/CompromisedData/VictimsInfo/Search/Table/columns";
import { columns as urlsColumns } from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/Table/columns";
import { columns as reportsColumns } from "@csis.com/tip/src/pages/Reports/ReportsList/columns";
import { columns as ticketColumns } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/Table/columns";
import { predefinedResultsPerPage } from "../models/pagination/constants";
import { HOUR_PREF_24 } from "./constants";
import { UserPreferences } from "./types";
import {
  generateColumnsOrderSetting,
  generateColumnsVisibilitySetting,
} from "./utils";

// default preferences
export const defaultPreferences: UserPreferences = {
  phishing: {
    columnsOrder: generateColumnsOrderSetting(urlsColumns),
    columnsVisibility: generateColumnsVisibilitySetting(urlsColumns),
    resultsPerPage: predefinedResultsPerPage.TEN,
    latestSearches: [],
  },
  tickets: {
    columnsOrder: generateColumnsOrderSetting(ticketColumns),
    columnsVisibility: generateColumnsVisibilitySetting(ticketColumns),
    resultsPerPage: predefinedResultsPerPage.TEN,
    latestSearches: [],
  },
  ticket: {
    isCommentsOldestToNewestSort: true,
  },
  alerts: {
    columnsOrder: generateColumnsOrderSetting(alertsColumns),
    columnsVisibility: generateColumnsVisibilitySetting(alertsColumns),
    resultsPerPage: predefinedResultsPerPage.TEN,
    latestSearches: [],
  },
  straydata_cards: {
    columnsOrder: generateColumnsOrderSetting(cardsColumns),
    columnsVisibility: generateColumnsVisibilitySetting(cardsColumns),
    resultsPerPage: predefinedResultsPerPage.TEN,
    latestSearches: [],
  },
  straydata_credentials: {
    columnsOrder: generateColumnsOrderSetting(credentialsColumns),
    columnsVisibility: generateColumnsVisibilitySetting(credentialsColumns),
    resultsPerPage: predefinedResultsPerPage.TEN,
    latestSearches: [],
  },
  threat_insights: {
    resultsPerPage: predefinedResultsPerPage.TWENTYFIVE,
    prefersDetailView: true,
    latestSearches: [],
  },
  reports: {
    columnsOrder: generateColumnsOrderSetting(reportsColumns),
    columnsVisibility: generateColumnsVisibilitySetting(reportsColumns),
    resultsPerPage: predefinedResultsPerPage.TEN,
    latestSearches: [],
  },

  general: {
    themePreference: "system",
    prefersHighContrast: false,
  },

  localization: {
    locale: "en-GB",
    dateTime: {
      dateFormat: "ISO",
      dateTimeStyle: {
        dateStyle: "medium",
        timeStyle: undefined,
        showRelativeTime: "sometimes",
      },
      hourCycle: HOUR_PREF_24,
    },
  },
};
