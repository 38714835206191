import classNames from "classnames";
import React from "react";
import { ColumnInterface } from "./types";

const TableCell = <COLUMN_KEY extends string>({
  index,
  column,
  cellContent,
}: {
  index: number;
  column: ColumnInterface<COLUMN_KEY, string>;
  cellContent: React.ReactNode;
}) => {
  const cellClasses = classNames("cell", {
    "cell--centered": column.isCenterAligned,
    "cell--right-aligned": column.isRightAligned,
  });

  return (
    <td
      key={"table_cell_" + column.key + index}
      className={cellClasses}
      role="cell"
    >
      {cellContent}
    </td>
  );
};

export default React.memo(TableCell);
