/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import React, { useRef, useState } from "react";
import Stack from "../../layout/Stack/Stack";
import { CSIS, GREY } from "../../colors";
import IconButton from "../IconButton/IconButton";
import { isFocusEnabled } from "./utils";

export interface InputPinInterface {
  name: string;
  pinLength: number; // the desired length of the pin
  value: string;
  onChange?: (newValue: string, name: string) => void;
  regexCriteria?: RegExp;
  iconColor?: GREY | CSIS;
}

const InputPin: React.FC<InputPinInterface> = ({
  name,
  pinLength,
  value = "",
  onChange,
  regexCriteria,
  iconColor = "csis",
}) => {
  const classes = classNames("inputpin", {});

  // an array for the input references
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);

  const valueAsArray = Array(pinLength)
    .fill("")
    .map((_, i) => value.toString()[i] || "");

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handleChange = (newChar: string, index: number) => {
    if (newChar !== "") {
      itemsRef.current[index + 1]?.focus();
    } else {
      // it means it came after here backspace key press
      itemsRef.current[index - 1]?.focus();
    }

    valueAsArray[index] = newChar;

    if (onChange) {
      onChange(valueAsArray.join(""), name);
    }
  };

  const handleMouseDown = (ev: React.MouseEvent, index: number) => {
    ev.preventDefault();

    // we want to only allow the input to focus on the first empty input
    // or the one before that. All others should be not focusable

    if (!value || value?.length === 0) {
      itemsRef.current[0]?.focus();
      return;
    }

    if (index < value?.length - 1) {
      itemsRef.current[value?.length]?.focus();
      return;
    }

    if (index === value?.length - 1) {
      itemsRef.current[value?.length - 1]?.focus();
      return;
    }

    if (index === value?.length) {
      itemsRef.current[index]?.focus();
      return;
    }

    if (index > value?.length) {
      itemsRef.current[value?.length]?.focus();
    }
  };

  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent, index: number) => {
      // special case to handle if the user is focused on an empty input
      // and they press backspace, we want to bring the focus to the previous input
      if (event.key === "Backspace" && valueAsArray[index] === "") {
        itemsRef.current[index - 1]?.focus();
      }
    },
    [valueAsArray]
  );

  return pinLength > 0 ? (
    <Stack align="center">
      <div className={classes}>
        {[...Array(pinLength)].map((_, index) => {
          return (
            <input
              key={"pin_item_" + index}
              ref={(el) => (itemsRef.current[index] = el)}
              name={"pin_item_" + index}
              maxLength={1}
              type={isPasswordShown ? "text" : "password"}
              value={valueAsArray[index]}
              onMouseDown={(ev) => handleMouseDown(ev, index)}
              onKeyUp={(ev) => handleKeyUp(ev, index)}
              onChange={(newValue) => {
                if (!regexCriteria) {
                  return handleChange(newValue.target.value, index);
                } else {
                  if (regexCriteria.test(newValue.target.value)) {
                    return handleChange(newValue.target.value, index);
                  } else {
                    return null;
                  }
                }
              }}
              tabIndex={isFocusEnabled(index, value) ? 0 : -1}
            />
          );
        })}
      </div>
      <IconButton
        icon={isPasswordShown ? "visibility" : "visibility_off"}
        type="text"
        tooltipText="Show/Hide Password"
        isCircle
        size="large"
        spacing="small"
        onButtonClick={() => {
          setIsPasswordShown(!isPasswordShown);
        }}
        color={iconColor}
      />
    </Stack>
  ) : null;
};

export default InputPin;
