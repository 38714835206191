import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Flag } from "./Flag";
import { fetchFlagsApi } from "./api/api";
import { FlagsResponse } from "./api/types";

interface StateSlice {
  flags: Flag[];
}
const initialState: StateSlice = {
  flags: [],
};

const flagsSlice = createSlice({
  name: "flags",
  initialState: initialState,
  reducers: {
    fetchSubscribedFlags() {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<Flag[]>) {
      state.flags = action.payload;
    },
  },
});

export default flagsSlice.reducer;

export const { fetchSubscribedFlags, fetchSuccess } = flagsSlice.actions;

// Async stuff - sagas

function* fetchSubscribedFlagsSaga() {
  try {
    const response: AxiosResponse<FlagsResponse> = yield call(fetchFlagsApi);

    const flags = response.data.payload;
    yield put(fetchSuccess(flags));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchSubscribedFlags.toString(), fetchSubscribedFlagsSaga);
}

export function* ticketsFlagsSagas() {
  yield all([actionWatcher()]);
}
