import { TableCellFormatter } from "../../atoms/Table/types";
import { isNullOrUndefined } from "../../utils/utils";

// this returns the width based on value and maxValue
// so if value=300 and maxValue=600 it returns width: 50%
// works and makes sense only for positive numbers
export function getBarWidth(value: number, maxValue: number) {
  if (maxValue !== 0) {
    const width = (value / maxValue) * 100;
    return { width: width + "%" };
  } else {
    return { width: 0 };
  }
}

// this is a function that takes a maxValuesPerColumn and returns a TableCellFormatter function
// then returns a cell that has background with width as wide as the value compared to all
// other values in that column
export function formatBarCellWithMaxWidth(maxValuesPerColumn: {
  [key: string]: number;
}): TableCellFormatter<string> {
  return (row, column) => {
    const value = row[column];
    if (!isNullOrUndefined(value) && typeof value === "number") {
      const maxWidth = maxValuesPerColumn[column] || 0; // Add a check to ensure maxValuesPerColumn[column] is defined
      return (
        <div
          className="tablebarchart__value-container"
          style={getBarWidth(value, maxWidth)}
        >
          <div>{String(row[column])}</div>
        </div>
      );
    }
    return <div>{String(row[column])}</div>;
  };
}
