import React from "react";
import Icon from "@csis.com/components/src/icons/Icon";

const TableError: React.FC<{
  error?: string;
  colSpan?: number;
}> = ({ error, colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="table-error">
          <div className="table-error__graphic">
            <Icon kind="block" color="grey-alt" />
          </div>
          <div className="table-error__title-container">
            <div className="table-error__title f_secondary f_semibold">
              {error ? error : "Something went wrong"}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
export default TableError;
