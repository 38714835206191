import { axiosCsisApi } from "@csis.com/tip/src/App";
import { mapUrlsStartAndEndDateToCorrectType } from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchLecacyStatsApi(queryParams: Partial<QueryParams>) {
  const params = mapUrlsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGet({
    ...params,
  });
}
