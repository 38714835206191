/* eslint-disable sonarjs/cognitive-complexity */
import React from "react";
import Button from "../../Button/Button";
import IconButton from "../../IconButton/IconButton";
import * as constants from "./constants";

const LeftArrow = ({
  selectedPage,
  dataTestId,
  onPreviousPageClick,
}: {
  selectedPage: number;
  dataTestId?: string;
  onPreviousPageClick?: () => void;
}) => {
  return (
    <IconButton
      spacing="normal"
      icon={"left-arrow"}
      color="grey"
      dataTestId={dataTestId + "-left-arrow"}
      onButtonClick={onPreviousPageClick ? onPreviousPageClick : () => null}
      isDisabled={selectedPage === 1}
    />
  );
};

const RightArrow = ({
  selectedPage,
  totalPages,
  dataTestId,
  onNextPageClick,
}: {
  selectedPage: number;
  totalPages?: number;
  dataTestId?: string;
  onNextPageClick?: () => void;
}) => {
  return (
    <IconButton
      spacing="normal"
      icon={"right-arrow"}
      color="grey"
      dataTestId={dataTestId + "-right-arrow"}
      onButtonClick={onNextPageClick ? onNextPageClick : () => null}
      isDisabled={selectedPage === totalPages}
    />
  );
};

const DottedItem = () => (
  <Button
    size="small"
    text={"..."}
    onButtonClick={() => {}}
    name="pagination-dotted-btn"
    type="text"
    color={"grey"}
  />
);

const PageItem = ({
  number,
  dataTestId,
  selectedPage,
  onPageClick,
}: {
  number: number;
  selectedPage: number;
  dataTestId?: string;
  onPageClick?: (page: number) => void;
}) => {
  const handlePageClick = () => {
    if (onPageClick) {
      onPageClick(number);
    }
  };
  return (
    <Button
      size="small"
      name="pagination-number-btn"
      text={number.toString()}
      dataTestId={dataTestId + "-page-" + number.toString()}
      onButtonClick={handlePageClick}
      color={selectedPage === number ? undefined : "grey"}
    />
  );
};

export interface PaginationInterface {
  totalPages?: number;
  selectedPage?: number;
  dataTestId?: string;
  onPageClick?: (page: number) => void;
  onPreviousPageClick?: () => void;
  onNextPageClick?: () => void;
}

const Pagination: React.FC<PaginationInterface> = ({
  totalPages,
  selectedPage = 1,
  dataTestId,
  onPageClick,
  onPreviousPageClick,
  onNextPageClick,
}) => {
  if (totalPages === 0 || totalPages === 1) {
    return null;
  }
  return (
    <div className="pagination" data-test-id={dataTestId}>
      <LeftArrow
        dataTestId={dataTestId}
        selectedPage={selectedPage}
        onPreviousPageClick={onPreviousPageClick}
      />
      <PageItem
        dataTestId={dataTestId}
        selectedPage={selectedPage}
        number={1}
        onPageClick={onPageClick}
      />
      {totalPages &&
        (totalPages === 3 ||
          totalPages === constants.MIN_VISIBLE_PAGES ||
          (totalPages > constants.MIN_VISIBLE_PAGES &&
            selectedPage < constants.MIN_VISIBLE_PAGES - 1)) && (
          <PageItem
            dataTestId={dataTestId}
            selectedPage={selectedPage}
            number={2}
            onPageClick={onPageClick}
          />
        )}
      {!totalPages && selectedPage <= 3 && (
        <PageItem
          dataTestId={dataTestId}
          selectedPage={selectedPage}
          number={2}
          onPageClick={onPageClick}
        />
      )}
      {totalPages &&
        (totalPages === constants.MIN_VISIBLE_PAGES ||
          (totalPages > constants.MIN_VISIBLE_PAGES &&
            selectedPage < constants.MIN_VISIBLE_PAGES - 1)) && (
          <PageItem
            dataTestId={dataTestId}
            selectedPage={selectedPage}
            number={3}
            onPageClick={onPageClick}
          />
        )}
      {!totalPages && (selectedPage === 2 || selectedPage === 3) && (
        <PageItem
          dataTestId={dataTestId}
          selectedPage={selectedPage}
          number={3}
          onPageClick={onPageClick}
        />
      )}
      {!totalPages && selectedPage === 3 && (
        <PageItem
          dataTestId={dataTestId}
          selectedPage={selectedPage}
          number={4}
          onPageClick={onPageClick}
        />
      )}
      {totalPages && totalPages > constants.MIN_VISIBLE_PAGES && <DottedItem />}
      {!totalPages && <DottedItem />}

      {totalPages &&
        totalPages > constants.MIN_VISIBLE_PAGES &&
        selectedPage > 2 &&
        selectedPage < totalPages - 1 && (
          <>
            <PageItem
              dataTestId={dataTestId}
              selectedPage={selectedPage}
              number={selectedPage - 1}
              onPageClick={onPageClick}
            />
            <PageItem
              dataTestId={dataTestId}
              selectedPage={selectedPage}
              number={selectedPage}
              onPageClick={onPageClick}
            />
            <PageItem
              dataTestId={dataTestId}
              selectedPage={selectedPage}
              number={selectedPage + 1}
              onPageClick={onPageClick}
            />
            <DottedItem />
          </>
        )}

      {!totalPages &&
        selectedPage > 2 &&
        selectedPage >= constants.MIN_VISIBLE_PAGES && (
          <>
            <PageItem
              dataTestId={dataTestId}
              selectedPage={selectedPage}
              number={selectedPage - 1}
              onPageClick={onPageClick}
            />
            <PageItem
              dataTestId={dataTestId}
              selectedPage={selectedPage}
              number={selectedPage}
              onPageClick={onPageClick}
            />
            <PageItem
              dataTestId={dataTestId}
              selectedPage={selectedPage}
              number={selectedPage + 1}
              onPageClick={onPageClick}
            />
            <DottedItem />
          </>
        )}

      {totalPages &&
        totalPages > constants.MIN_VISIBLE_PAGES &&
        selectedPage >= totalPages - 1 && (
          <PageItem
            dataTestId={dataTestId}
            selectedPage={selectedPage}
            number={totalPages - 1}
            onPageClick={onPageClick}
          />
        )}
      {totalPages && (
        <PageItem
          dataTestId={dataTestId}
          selectedPage={selectedPage}
          number={totalPages}
          onPageClick={onPageClick}
        />
      )}

      <RightArrow
        dataTestId={dataTestId}
        selectedPage={selectedPage}
        totalPages={totalPages}
        onNextPageClick={onNextPageClick}
      />
    </div>
  );
};

export default Pagination;
