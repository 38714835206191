import classNames from "classnames";
import React from "react";
import { TabsPanelInterface } from "./TabsPanel";

export interface TabsBodyInterface {
  selectedTab?: number;
  children: React.ReactNode;
}

const TabsBody: React.FC<TabsBodyInterface> = ({ selectedTab, children }) => {
  const classes = classNames("tabs__body", {});

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child as React.ReactElement<TabsPanelInterface>, {
      selectedTab: selectedTab,
      index: index,
    });
  });

  return <div className={classes}>{childrenWithProps}</div>;
};

export default TabsBody;
