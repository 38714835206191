import { axiosCsisApi } from "@csis.com/tip/src/App";
import { ArtifactType } from "../../../../../api/openapi/data-contracts";
import { ContentQueryParams } from "../types";

export async function fetchUrlApi(urlHash: string) {
  return axiosCsisApi.getUrlApi20PhishingUrlUrlHashGet(urlHash);
}

export async function fetchContentApi(
  urlHash: string,
  queryParams: ContentQueryParams
) {
  return axiosCsisApi.getContentsApi20PhishingContentUrlHashGet({
    urlHash,
    ...queryParams,
  });
}

export async function fetchArtifactApi(
  urlHash: string,
  artifactType: ArtifactType,
  contentId: number
) {
  return axiosCsisApi.getContentArtifactApi20PhishingContentUrlHashArtifactTypeContentIdGet(
    urlHash,
    artifactType,
    contentId,
    {
      format: artifactType === "sketch" ? "blob" : "json",
    }
  );
}
