export const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

export const DAYS_IN_WEEK = 7;

export const SQUARE_SIZE = 14;
export const MONTH_LABEL_GUTTER_SIZE = 6;
export const GUTTER_SIZE = 3;

export const MINIMUM_HEATCALENDAR_LENGTH_DAYS = 90;

export const TOTAL_HEAT_LEVELS = 4;
