export function getValidWidth(completed: number) {
  if (completed > 100) {
    return 100;
  }
  if (completed < 0) {
    return 0;
  }
  return completed;
}

export function getProgressWidth(completed: number) {
  return getValidWidth(completed) + "%";
}
