import React from "react";

export interface TabsPanelInterface {
  selectedTab?: number;
  index?: number;
  children: React.ReactNode;
}

const TabsPanel: React.FC<TabsPanelInterface> = ({
  selectedTab,
  index,
  children,
}) => {
  return selectedTab === index ? (
    <div className={"tabs__panel"} role="tabpanel">
      {children}
    </div>
  ) : null;
};
export default TabsPanel;
