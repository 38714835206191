import classNames from "classnames";
import React from "react";
import { CSIS, RED } from "../../colors";

export interface BadgeInterface {
  count: number;
  color: RED | CSIS;
  isCircle?: boolean;
}

const Badge: React.FC<BadgeInterface> = ({ count, color, isCircle }) => {
  const classes = classNames("badge", {
    "badge--red": color === "red",
    "badge--csis": color === "csis",
    "badge--circle": isCircle,
  });

  return <div className={classes}>{count}</div>;
};

export default Badge;
