import { calculateGroupByParamBasedOnDateRange } from "@csis.com/tip/src/components/shared/DatePicker/utils";
import { ticketsKeys } from "../constants";
import { QueryParams } from "../types";

// mapper for api requests to transform start_date and end_date to appropriate query params
export function mapTicketsStartAndEndDateToCorrectType(
  queryParams: Partial<QueryParams>
) {
  return Object.assign({}, queryParams, {
    [ticketsKeys.CREATED_AFTER]: queryParams[ticketsKeys.START_DATE],
    [ticketsKeys.CREATED_BEFORE]: queryParams[ticketsKeys.END_DATE],
  });
}

// given a queryParams object with a range (start_date - end_date)
// we add a new "group_by" query param based on the length of the range
// so if a range is small, we group the results in "day"
// if the range is large we group them by week etc
export function addGroupByParamBasedOnRange(queryParams: Partial<QueryParams>) {
  const groupBy = calculateGroupByParamBasedOnDateRange(
    queryParams[ticketsKeys.START_DATE],
    queryParams[ticketsKeys.END_DATE]
  );

  return Object.assign({}, queryParams, {
    group_by: groupBy,
  });
}
