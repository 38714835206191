import classNames from "classnames";
import React, { useState } from "react";

export interface ImageInterface {
  alt: string;
  source: string;
  hasPreview?: boolean;
  hasBorder?: boolean;
  hasTransparentBackground?: boolean;
  size?: "tiny" | "small" | "normal" | "big" | "full-width";
  fit?: "cover"; // object-fit css property
}

const Image: React.FC<ImageInterface> = ({
  alt,
  source,
  hasPreview,
  hasBorder,
  hasTransparentBackground,
  size,
  fit,
}) => {
  const classes = classNames("image", {
    "image--has-preview": hasPreview,
    "image--tiny": size === "tiny",
    "image--small": size === "small",
    "image--normal": size === "normal",
    "image--big": size === "big",
    "image--full-width": size === "full-width",
    "image--transparent": hasTransparentBackground,
    "image--has-border": hasBorder,
    "image--cover": fit === "cover",
  });
  const maskClasses = classNames("image__preview-mask", {
    "image__preview-mask--transparent": hasTransparentBackground,
  });

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const handleImageClick = () => {
    if (hasPreview) {
      setPreviewOpen(true);
    }
  };

  return (
    <>
      {hasPreview && isPreviewOpen && (
        <div className={maskClasses} onClick={() => setPreviewOpen(false)}>
          <img alt={alt} src={source} />
        </div>
      )}
      <img
        className={classes}
        alt={alt}
        src={source}
        onClick={handleImageClick}
      />
    </>
  );
};

export default Image;
