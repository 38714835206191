import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getThreatInsights = (state: RootState) =>
  state.insightsReducer.insights;

export const getIsThreatInsightsPending = (state: RootState) =>
  state.insightsReducer.isPending;

export const getThreatInsightsFetchError = (state: RootState) =>
  state.insightsReducer.fetchError;

export const getHasNextPage = (state: RootState) =>
  state.insightsReducer.hasNextPage;

export const getThreatInsightsResult = createSelector(
  [getThreatInsights, getIsThreatInsightsPending, getThreatInsightsFetchError],
  (insights, isPending, fetchError) => {
    return { insights, isPending, fetchError };
  }
);

export const getLatestFeaturedThreatInsights = (state: RootState) =>
  state.insightsReducer.latestFeatured;

export const getIsLatestFeaturedThreatInsightsPending = (state: RootState) =>
  state.insightsReducer.isLatestFeaturedFetchPending;

export const getLatestFeaturedThreatInsightsFetchError = (state: RootState) =>
  state.insightsReducer.latestFeaturedFetchError;

export const getLatestFeaturedThreatInsightsResult = createSelector(
  [
    getLatestFeaturedThreatInsights,
    getIsLatestFeaturedThreatInsightsPending,
    getLatestFeaturedThreatInsightsFetchError,
  ],
  (
    latestFeatured,
    isLatestFeaturedThreatInsightsPending,
    latestFeaturedFetchError
  ) => {
    return {
      latestFeatured,
      isLatestFeaturedThreatInsightsPending,
      latestFeaturedFetchError,
    };
  }
);
