import { axiosCsisApi } from "@csis.com/tip/src/App";
import { ArticlesSettings } from "../../types";

export async function fetchArticlesSettingsApi() {
  return axiosCsisApi.getArticleNotificationPreferencesApi10ProfilePreferenceNotificationArticleGet();
}

export async function patchArticlesSettingsApi(settings: ArticlesSettings) {
  return axiosCsisApi.patchArticleNotificationPreferenceApi10ProfilePreferenceNotificationArticlePatch(
    // @ts-ignore can be null - api type is wrong
    settings
  );
}
