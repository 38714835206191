import { useDispatch, useSelector } from "react-redux";
import { ORG_ID_PARAM_KEY } from "../MainApp";
import { isString } from "../models/helpers";
import {
  getAllValidOrgIds,
  getDefaultOrgId,
  getSelectedOrgId,
} from "../pages/Profile/Security/selectors";
import { setSelectedOrgId } from "../pages/Profile/Security/slice";
import { useUpdateAndReplaceQueryParams } from "../utils/updateAndReplaceQueryParams";

export const useHandleOrgIdInParams = () => {
  const dispatch = useDispatch();
  const selectedOrgId = useSelector(getSelectedOrgId);
  const defaultOrgId = useSelector(getDefaultOrgId);
  const allValidOrgIds = useSelector(getAllValidOrgIds);
  const updateAndReplaceQueryParams = useUpdateAndReplaceQueryParams();

  return (
    orgIdFromParams: string | string[] | null,
    setShowOrgIdChanged: (showOrgIdChanged: boolean) => void
  ) => {
    if (
      orgIdFromParams &&
      isString(orgIdFromParams) &&
      selectedOrgId !== orgIdFromParams
    ) {
      // we want to verify that the org Id from the params is a valid one because in theory it can be any string edited by the user
      if (allValidOrgIds.includes(orgIdFromParams)) {
        dispatch(
          setSelectedOrgId({
            organizationId: orgIdFromParams,
            shouldRedirectToMainPage: false,
          })
        );
      } else {
        if (defaultOrgId) {
          updateAndReplaceQueryParams(ORG_ID_PARAM_KEY, defaultOrgId);
          setShowOrgIdChanged(true);
          dispatch(
            setSelectedOrgId({
              organizationId: defaultOrgId,
              shouldRedirectToMainPage: false,
            })
          );
        }
      }
    }
  };
};
