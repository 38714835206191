import React from "react";
import { getSubstringStartAndEnd } from "./utils/utils";

// given a string, and a start and end indexes
// it returns the string with a "highlighted" part
// from start to end
const HighlightSubString = ({
  text,
  searchString,
}: {
  text: string;
  searchString: string;
}) => {
  const [substringStart, substringEnd] = getSubstringStartAndEnd(
    text,
    searchString
  );

  return (
    <>
      {text.substring(0, substringStart)}
      <span className="highlightsubstring__highlighted">
        {text.substring(substringStart, substringEnd)}
      </span>
      {text.substring(substringEnd, text.length)}
    </>
  );
};

export default HighlightSubString;
