import classNames from "classnames";
import React from "react";
import { BIG, SMALL } from "../../sizes";

function getCustomStyle(opensFromTop?: boolean, parentHeight?: number) {
  if (opensFromTop && parentHeight) {
    return { bottom: parentHeight + 4 };
  }
  return undefined;
}

// This component should never be used alone. It is always part of either Dropdown or IconMenu
// or any component that has a "select-option"-like behavior
// Therefore there is not a story on storybook
export interface ListMenuInterface {
  children: React.ReactNode;
  size?: SMALL | BIG;
  opensFromRight?: boolean;
  opensFromTop?: boolean;
  direction?: "row" | "column";
  parentHeight?: number;
  parentName: string;
  hasMinWidth?: boolean;
  hasMinHeight?: boolean;
  isMultiSelectable?: boolean;
  hasOverflowVisible?: boolean;
  dataTestId?: string;
}

const ListMenu: React.FC<ListMenuInterface> = ({
  children,
  size,
  opensFromRight,
  opensFromTop,
  direction,
  hasMinWidth,
  hasMinHeight,
  parentHeight,
  isMultiSelectable,
  parentName,
  hasOverflowVisible,
  dataTestId,
}) => {
  const classes = classNames("listmenu", {
    "listmenu--opens-from-right": opensFromRight,
    "listmenu--opens-from-top": opensFromTop,
    "listmenu--row": direction === "row",
    "listmenu--small": size === "small",
    "listmenu--big": size === "big",
    "listmenu--has-minheight": hasMinHeight,
    "listmenu--has-minwidth": hasMinWidth,
    "listmenu--has-overflowvisible": hasOverflowVisible,
  });
  return (
    <div
      aria-label="Select an option"
      id={parentName + "-listbox"}
      className={classes}
      style={getCustomStyle(opensFromTop, parentHeight)}
      role="listbox"
      aria-multiselectable={isMultiSelectable}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  );
};

export default ListMenu;
