export enum retainerKeys {
  NAME = "primary_contact_name",
  PHONE = "primary_phone_number",
  BUSINESS_UNIT_ID = "business_unit_id",
}

export enum RetainerType {
  IT = "IT",
  OT = "OT",
}
