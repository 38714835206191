import { areArraysEqualSets } from "../../utils/utils";
import * as types from "./types";

export function getAllRowValuesBasedOnColumn<ROW extends types.RowInterface>(
  rows: ROW[],
  selectedRows: types.SelectedRows
) {
  return rows.map((row) => {
    const rowValue = row[selectedRows.key];
    if (typeof rowValue === "string" || typeof rowValue === "number") {
      return rowValue;
    }
    return undefined;
  });
}

export function isAllRowsSelected<ROW extends types.RowInterface>(
  rows?: ROW[],
  selectedRows?: types.SelectedRows
) {
  if (selectedRows && rows && rows.length > 0) {
    const allRows = getAllRowValuesBasedOnColumn(rows, selectedRows);
    return areArraysEqualSets(allRows, selectedRows.rows);
  }
  return false;
}

export function isRowSelected(
  row?: types.RowInterface,
  selectedRows?: types.SelectedRows
) {
  if (row && selectedRows) {
    const column = selectedRows.key;

    const rowValue = row[column];
    if (typeof rowValue === "number" || typeof rowValue === "string") {
      return selectedRows.rows.includes(rowValue);
    }
  }
  return false;
}

export function isCellBookmarked(
  cell?: types.Cell | React.ReactNode,
  bookmarkedRows?: types.BookmarkedRows
) {
  if (
    cell &&
    bookmarkedRows &&
    (typeof cell === "number" || typeof cell === "string")
  ) {
    return bookmarkedRows.rows.includes(cell);
  }
  return false;
}

export function getAriaSort<COLUMN_KEY extends string>(
  column: types.ColumnInterface<COLUMN_KEY, string>
) {
  if (column.isSortable && column.sort) {
    if (column.sort === "asc") {
      return "ascending";
    }
    if (column.sort === "desc") {
      return "descending";
    }
  }
  return "none";
}

export function getVisibleColumnsArrayLength<COLUMN_KEY extends string>(
  columns: types.ColumnInterface<COLUMN_KEY, string>[]
) {
  return columns.filter((c) => !c.isHidden).length;
}

// given the index of a column and the columns,
// returns true if the index is the last element in the array
// filtering any hidden columns
export function isLastColumn<COLUMN_KEY extends string>(
  i: number,
  columns: types.ColumnInterface<COLUMN_KEY, string>[]
) {
  return i + 1 === getVisibleColumnsArrayLength(columns);
}
