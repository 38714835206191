// validates dates of format // 2022-05-03
export function isValidDay(dateString: string) {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  return regEx.test(dateString);
}

export function getStartDateAndEndDateForADay(
  dateString: string
): [Date, Date] {
  const startDate = new Date(dateString);
  const endDate = new Date(dateString);
  endDate.setUTCHours(24, 0, 0, 0);
  return [startDate, endDate];
}

// validates weeks of format // 2022-W37
export function isValidWeekString(weekString: string) {
  const regEx = /^\d{4}-W\d{2}$/;
  return regEx.test(weekString);
}

function getWeekNumberFromWeekString(weekString: string) {
  return parseInt(weekString.split("W")[1] as string);
}

function getYearFromWeekString(weekString: string) {
  return parseInt(weekString.split("-")[0] as string);
}

// given a week string like 2023-W02
// returns the first date of that week
export function getFirstDayOfAWeek(weekString: string) {
  const y = getYearFromWeekString(weekString);
  const w = getWeekNumberFromWeekString(weekString);

  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export function getStartDateAndEndDateForAWeek(date: Date): [Date, Date] {
  const startDate = new Date(date);
  const endDate = new Date(date);
  endDate.setUTCHours(7 * 24, 0, 0, 0);
  return [startDate, endDate];
}
