import { IconKind } from "@csis.com/components/src/icons/Icon";
import { URLStatus } from "@csis.com/tip/src/api/openapi/data-contracts";
import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const StatusValues = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  MALICIOUS: "malicious",
  UNDETERMINED: "undetermined",
} as const;

export type Status = URLStatus;

export const isStatus = (x: any): x is Status =>
  Object.values(StatusValues).includes(x);

export const isArrayOfStatuses: Guard<Status[]> = isArrayOf(isStatus);

// this is the part we localize
export const StatusTranslationKeys: { [key in Status]: TranslationKey } = {
  [StatusValues.ACTIVE]: "active",
  [StatusValues.INACTIVE]: "inactive",
  [StatusValues.MALICIOUS]: "malicious",
  [StatusValues.UNDETERMINED]: "undetermined",
} as const;

export const StatusIcons: { [key in Status]: IconKind } = {
  [StatusValues.ACTIVE]: "cloud",
  [StatusValues.INACTIVE]: "cloud_off",
  [StatusValues.MALICIOUS]: "warning",
  [StatusValues.UNDETERMINED]: "questionmark",
} as const;

export const StatusColors = {
  [StatusValues.ACTIVE]: "csis",
  [StatusValues.INACTIVE]: "secondary",
  [StatusValues.MALICIOUS]: "red",
  [StatusValues.UNDETERMINED]: "secondary",
} as const;
