/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import React, { ReactElement, useCallback } from "react";
import { IconKind } from "../../icons/Icon";
import { BLUE, GREY, RED } from "../../colors";
import { SMALL } from "../../sizes";
import { renderModal } from "../ListMenu/renderModal";
import { useClickOutside } from "../helpers/useClickOutside";
import DropdownHeader from "./DropdownHeader";
import DropdownList from "./DropdownList";

export interface DropdownOption<T> {
  value: T;
  label: string | number;
  isIdented?: boolean;
  isDisabled?: boolean;
  isVisible?: boolean;
  tooltipText?: string;
  icon?: IconKind;
}

interface CommonDropdownTypes<T, N extends string> {
  id?: string;
  name: N;
  dataTestId?: string;
  options: DropdownOption<T>[];
  type?: "primary" | "text" | "shadow";
  size?: SMALL;
  color?: RED | BLUE | GREY;
  isDisabled?: boolean;
  headerTitle: string;
  headerIcon?: IconKind;
  isUppercase?: boolean; // very rarely we need the title to be lowercase
  isFullWidth?: boolean;
  opensFromTop?: boolean;
  opensFromRight?: boolean;
  isSearchable?: boolean;
  tabIndex?: number;
  internalDropdown?: ReactElement;
  shouldRenderAsDescendant?: boolean; // when the dropdown is a child of another dropdown
  isLoading?: boolean;
  error?: string | null;
  onRetry?: () => void;
}

export interface SingleValueDropdownInterface<T, N extends string>
  extends CommonDropdownTypes<T, N> {
  value?: T;
  onSelect?: (value: T, name: N) => void;
  isMultiSelect?: false;
}

export interface MultiValueDropdownInterface<T, N extends string>
  extends CommonDropdownTypes<T, N> {
  values?: T[];
  onSelect?: (values: T[], name: N) => void;
  isMultiSelect: true;
}

export type DropdownInterface<T, N extends string> =
  | SingleValueDropdownInterface<T, N>
  | MultiValueDropdownInterface<T, N>;

const Dropdown = <T, N extends string>(props: DropdownInterface<T, N>) => {
  const {
    name,
    id = name,
    options,
    isFullWidth,
    color,
    isDisabled,
    isSearchable = options.length > 8 ? true : props.isSearchable,
    type = "text",
    isUppercase = true,
    headerTitle,
    size,
    opensFromTop,
    opensFromRight,
    dataTestId,
    headerIcon,
    tabIndex = 0,
    internalDropdown,
    shouldRenderAsDescendant,
    isLoading,
    error,
    onRetry,
  } = props;

  const [isComponentVisible, setIsComponentVisible, dropdownRef, modalRef] =
    useClickOutside();

  const onOptionSelected = useCallback(
    (value: T, event?: React.MouseEvent) => {
      if (event) {
        event.stopPropagation();
      }
      setIsComponentVisible(false);

      if (!props.isMultiSelect && props.onSelect) {
        props.onSelect(value, name);
      }
    },
    [setIsComponentVisible, props, name]
  );

  const onOptionsSelected = useCallback(
    (values: T[], event?: React.MouseEvent) => {
      if (event) {
        event.stopPropagation();
      }
      setIsComponentVisible(false);

      if (props.isMultiSelect && props.onSelect) {
        props.onSelect(values, name);
      }
    },
    [setIsComponentVisible, props, name]
  );

  // when the dropdown is focused we can open/close it with space
  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (isComponentVisible) {
        return;
      }
      if (event.key === " " || event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        if (!isComponentVisible) {
          setIsComponentVisible(true);
        }
      }
    },
    [isComponentVisible, setIsComponentVisible]
  );

  const classes = classNames("dropdown", {
    "dropdown--primary": type === "primary",
    "dropdown--text": type === "text",
    "dropdown--shadow": type === "shadow",
    "dropdown--disabled": isDisabled,
    "dropdown--full-width": isFullWidth,
    "dropdown--small": size === "small",
    "dropdown--red": color === "red",
    "dropdown--blue": color === "blue",
    "dropdown--grey": color === "grey",
    "dropdown--uppercase": isUppercase,
  });

  return (
    <div
      data-test-id={dataTestId}
      ref={dropdownRef}
      className={classes}
      aria-label={name}
      role={"button"}
      aria-expanded={isComponentVisible}
      onKeyPress={handleKeyPress}
      aria-controls={id + "-listbox"}
      tabIndex={isDisabled ? -1 : tabIndex}
    >
      <DropdownHeader<T>
        setIsComponentVisible={setIsComponentVisible}
        isComponentVisible={isComponentVisible}
        options={options}
        values={props.isMultiSelect ? props.values : undefined}
        value={props.isMultiSelect ? undefined : props.value}
        headerTitle={headerTitle}
        headerIcon={headerIcon}
        isMultiSelect={props.isMultiSelect}
        isFullWidth={isFullWidth}
        isDisabled={isDisabled}
      />

      <div>
        {isComponentVisible ? (
          shouldRenderAsDescendant ? (
            <DropdownList<T>
              parentId={id}
              name={name}
              options={options}
              values={props.isMultiSelect ? props.values : undefined}
              value={props.isMultiSelect ? undefined : props.value}
              isMultiSelect={props.isMultiSelect}
              onOptionSelected={onOptionSelected}
              onOptionsSelected={onOptionsSelected}
              headerTitle={headerTitle}
              isSearchable={isSearchable}
              size={size}
              opensFromTop={opensFromTop}
              dataTestId={dataTestId}
              opensFromRight={opensFromRight}
              parentHeight={dropdownRef.current?.clientHeight}
              internalDropdown={internalDropdown}
              isLoading={isLoading}
              error={error}
              onRetry={onRetry}
            />
          ) : (
            renderModal(
              <DropdownList<T>
                parentId={id}
                name={name}
                options={options}
                values={props.isMultiSelect ? props.values : undefined}
                value={props.isMultiSelect ? undefined : props.value}
                isMultiSelect={props.isMultiSelect}
                onOptionSelected={onOptionSelected}
                onOptionsSelected={onOptionsSelected}
                headerTitle={headerTitle}
                isSearchable={isSearchable}
                size={size}
                opensFromTop={opensFromTop}
                dataTestId={dataTestId}
                opensFromRight={opensFromRight}
                parentHeight={dropdownRef.current?.clientHeight}
                internalDropdown={internalDropdown}
                isLoading={isLoading}
                error={error}
                onRetry={onRetry}
              />,
              dropdownRef,
              modalRef,
              opensFromRight,
              isFullWidth
            )
          )
        ) : null}
      </div>
    </div>
  );
};

export default Dropdown;
