export const predefinedResultsPerPage = {
  TEN: 10,
  TWENTYFIVE: 25,
  FIFTY: 50,
  HUNDRED: 100,
  FIVEHUNDRED: 500,
  THOUSAND: 1000,
} as const;

export type PredefinedResultsPerPage =
  typeof predefinedResultsPerPage[keyof typeof predefinedResultsPerPage];

export const isPredefinedResultsPerPage = (
  x: any
): x is PredefinedResultsPerPage =>
  Object.values(predefinedResultsPerPage).includes(x);
