/* eslint-disable sonarjs/no-duplicate-string */
import React from "react";
import { Card, Link, Reminder, Stack } from "@csis.com/components";
import { LinkWithOrgId } from "@csis.com/tip/src/components/LinkWithOrgId/LinkWithOrgId";
import { AllowedTipUrl } from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";

function ViewAllButton({ viewAllUrl }: { viewAllUrl: AllowedTipUrl }) {
  const { t } = useTranslations();
  return (
    <div>
      <Link text={t("view_all").toUpperCase()} href={viewAllUrl} size="small" />
    </div>
  );
}
function ProductCard({
  children,
  dataTestId,
}: {
  children: React.ReactNode;
  dataTestId?: string;
}) {
  return (
    <Card dataTestId={dataTestId} isFullWidth>
      <Stack isVertical align="stretch" gutterSize="large" isExpanded>
        {children}
      </Stack>
    </Card>
  );
}

function ProductCardHeader({
  title,
  subTitle,
  viewAllUrl,
}: {
  title: string;
  subTitle?: string;
  viewAllUrl?: AllowedTipUrl;
}) {
  return (
    <div>
      <div className="hide-on-mobile">
        <Stack align="center" justify="space-between">
          <Stack gutterSize="small">
            <div className="f_secondary f_big f_semibold">{title}</div>
            {subTitle && (
              <>
                <div>-</div>
                <div className=" f_big">{subTitle}</div>
              </>
            )}
          </Stack>
          {viewAllUrl && <ViewAllButton viewAllUrl={viewAllUrl} />}
        </Stack>
      </div>
      <div className="show-only-on-mobile">
        <Stack justify="space-between">
          <Stack gutterSize="small" isVertical>
            <div className="f_secondary f_big f_semibold">{title}</div>
            {subTitle && <div className=" f_big">{subTitle}</div>}
          </Stack>
          {viewAllUrl && <ViewAllButton viewAllUrl={viewAllUrl} />}
        </Stack>
      </div>
    </div>
  );
}

function ProductCardTable({ children }: { children: React.ReactNode }) {
  return (
    <Stack isVertical align="stretch" justify="space-between" isExpanded>
      <div className={"product-card__table"}>{children}</div>
    </Stack>
  );
}

function ProductCardChart({ children }: { children: React.ReactNode }) {
  return (
    <Stack isVertical align="stretch" justify="space-between" isExpanded>
      <div className={"product-card__chart"}>
        <div>{children}</div>
      </div>
    </Stack>
  );
}

function ProductCardCarousel({ children }: { children: React.ReactNode }) {
  return <div className={"product-card__carousel"}>{children}</div>;
}

function ProductCardFooter({ text, url = "/" }: { text: string; url: string }) {
  return (
    <div className={"product-card__footer"}>
      <LinkWithOrgId href={url} withStyling={false} isFullWidth>
        <Reminder text={text} color={"csis"} />
      </LinkWithOrgId>
    </div>
  );
}

ProductCard.Header = ProductCardHeader;
ProductCard.Table = ProductCardTable;
ProductCard.Chart = ProductCardChart;
ProductCard.Carousel = ProductCardCarousel;
ProductCard.Footer = ProductCardFooter;
export default ProductCard;
