import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  BulkUrlSubmitResponse,
  DetailResponseBulkUrlSubmitResponse,
  DetailResponseURLPreviewPage,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { LatestSearches } from "@csis.com/tip/src/components/shared/SearchComboInput/AdvancedSearchCard/types";
import { PredefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";
import {
  getUserPreferences,
  getUserPreferencesForPhish,
} from "@csis.com/tip/src/userPreferences/selectors";
import {
  updateUserPreferences,
  fetchSuccess as userPreferencesFetchSuccess,
} from "@csis.com/tip/src/userPreferences/slice";
import {
  SearchPagePreferences,
  UserPreferences,
} from "@csis.com/tip/src/userPreferences/types";
import {
  generateColumnsBasedOnSettings,
  generateColumnsOrderSetting,
  generateColumnsVisibilitySetting,
} from "@csis.com/tip/src/userPreferences/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { UrlsColumns, columns } from "./Table/columns";
import { bulkPostUrlsApi, exportUrlsCsvApi, fetchUrlsApi } from "./api/api";
import { getUrlsColumns } from "./selectors";
import { QueryParams, UrlsRow } from "./types";

interface StateSlice {
  urls: UrlsRow[] | null;
  hasNextPage: boolean;
  isPending: boolean;
  fetchError: string | null;
  columns: UrlsColumns;
  isCsvExportPending: boolean;

  isBulkPostUrlsPending: boolean;
  bulkPostUrlsSuccess: boolean;
  bulkPostUrlsResponse: BulkUrlSubmitResponse | null;
  bulkPostUrlsError: string | null;
}
const initialState: StateSlice = {
  urls: null,
  hasNextPage: false,
  isPending: true, // always set this to true as init so we avoid "flashing" on the first load
  fetchError: null,
  columns: columns,
  isCsvExportPending: false,

  isBulkPostUrlsPending: false,
  bulkPostUrlsSuccess: false,
  bulkPostUrlsResponse: null,
  bulkPostUrlsError: null,
};

const urlsSlice = createSlice({
  name: "urls",
  initialState: initialState,
  reducers: {
    fetchUrls(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
      state.urls = [];
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
      state.urls = [];
    },
    fetchSuccess(state, action: PayloadAction<UrlsRow[]>) {
      state.isPending = false;
      state.fetchError = null;
      state.urls = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reorderColumns(state, action: PayloadAction<UrlsColumns>) {
      state.columns = action.payload;
    },
    setResultsPerPageUserPreference(
      _state,
      _action: PayloadAction<PredefinedResultsPerPage>
    ) {
      //empty handled by saga
    },

    exportUrlsCsv(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setCsvExportPending(state) {
      state.isCsvExportPending = true;
    },
    setCsvExportComplete(state) {
      state.isCsvExportPending = false;
    },

    bulkPostUrls(_state, _action: PayloadAction<string[]>) {},
    setBulkPostUrlsPending(state) {
      state.isBulkPostUrlsPending = true;
      state.bulkPostUrlsSuccess = false;
      state.bulkPostUrlsError = null;
      state.bulkPostUrlsResponse = null;
    },
    setBulkPostUrlsError(state, action: PayloadAction<string>) {
      state.isBulkPostUrlsPending = false;
      state.bulkPostUrlsError = action.payload;
      state.bulkPostUrlsSuccess = false;
      state.bulkPostUrlsResponse = null;
    },
    setBulkPostUrlsSuccess(
      state,
      action: PayloadAction<BulkUrlSubmitResponse>
    ) {
      state.isBulkPostUrlsPending = false;
      state.bulkPostUrlsError = null;
      state.bulkPostUrlsSuccess = true;
      state.bulkPostUrlsResponse = action.payload;
    },
    resetBulkPostUrlsState(state) {
      state.isBulkPostUrlsPending = false;
      state.bulkPostUrlsError = null;
      state.bulkPostUrlsSuccess = false;
      state.bulkPostUrlsResponse = null;
    },

    setLatestSearchesUserPreference(
      _state,
      _action: PayloadAction<LatestSearches>
    ) {
      //empty handled by saga
    },
  },
});

export default urlsSlice.reducer;

export const {
  fetchUrls,
  setPending,
  setFetchError,
  fetchSuccess,
  setHasNextPage,
  reorderColumns,
  setResultsPerPageUserPreference,
  setLatestSearchesUserPreference,
  exportUrlsCsv,
  setCsvExportPending,
  setCsvExportComplete,

  bulkPostUrls,
  setBulkPostUrlsPending,
  setBulkPostUrlsError,
  setBulkPostUrlsSuccess,
  resetBulkPostUrlsState,
} = urlsSlice.actions;

// Async stuff - sagas

function* fetchUrlsSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setPending());
  try {
    const response: AxiosResponse<DetailResponseURLPreviewPage> = yield call(
      fetchUrlsApi,
      action.payload
    );

    yield put(fetchSuccess(response.data.payload.page));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* exportUrlsCsvSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setCsvExportPending());

  try {
    const response: AxiosResponse<Blob> = yield call(
      exportUrlsCsvApi,
      action.payload
    );

    const blob = response.data;
    downloadBlobForUser(blob, "urls_csv.csv");

    yield put(setCsvExportComplete());
  } catch (e) {
    yield put(setCsvExportComplete());
  }
}

function* bulkPostUrlsSaga(action: PayloadAction<string[]>) {
  yield put(setBulkPostUrlsPending());

  try {
    const response: AxiosResponse<DetailResponseBulkUrlSubmitResponse> =
      yield call(bulkPostUrlsApi, action.payload);

    yield put(setBulkPostUrlsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setBulkPostUrlsError(errorMessage));
  }
}

function* reactToColumnsUpdateAndUpdateUserPreferencesSaga(
  action: PayloadAction<UrlsColumns>
) {
  // the user updated the columns so, we "update"
  // the prefs server side on the background
  const newColumns = action.payload;
  const newColumnsOrderSettings = generateColumnsOrderSetting(newColumns);
  const newColumnsVisibilitySettings =
    generateColumnsVisibilitySetting(newColumns);
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.phishing = {
      ...newPrefs.phishing,
      columnsVisibility: newColumnsVisibilitySettings,
      columnsOrder: newColumnsOrderSettings,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* reactToUserPreferencesResponse() {
  const userPrefs: SearchPagePreferences | undefined = yield select(
    getUserPreferencesForPhish
  );
  const columns: UrlsColumns = yield select(getUrlsColumns);

  if (userPrefs && userPrefs.columnsOrder && userPrefs.columnsVisibility) {
    // generate columns based on the saved user preferences
    const columnsFromSettings = generateColumnsBasedOnSettings(
      userPrefs.columnsOrder,
      userPrefs.columnsVisibility,
      columns
    );

    yield put(reorderColumns(columnsFromSettings));
  }
}

// eslint-disable-next-line require-yield
function* setResultsPerPageUserPreferenceSaga(
  action: PayloadAction<PredefinedResultsPerPage>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.phishing = {
      ...newPrefs.phishing,
      resultsPerPage: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* setLatestSearchesUserPreferenceSaga(
  action: PayloadAction<LatestSearches>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.phishing = {
      ...newPrefs.phishing,
      latestSearches: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchUrls.toString(), fetchUrlsSaga);
  yield takeLatest(exportUrlsCsv.toString(), exportUrlsCsvSaga);
  yield takeLatest(bulkPostUrls.toString(), bulkPostUrlsSaga);
  yield takeLatest(
    userPreferencesFetchSuccess.toString(),
    reactToUserPreferencesResponse
  );
  yield takeLatest(
    reorderColumns.toString(),
    reactToColumnsUpdateAndUpdateUserPreferencesSaga
  );
  yield takeLatest(
    setResultsPerPageUserPreference.toString(),
    setResultsPerPageUserPreferenceSaga
  );
  yield takeLatest(
    setLatestSearchesUserPreference.toString(),
    setLatestSearchesUserPreferenceSaga
  );
}

export function* urlsSagas() {
  yield all([actionWatcher()]);
}
