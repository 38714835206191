/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import {
  Bar,
  BarChart as BarChartRechart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useCallback } from "react";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import ChartError from "../ChartError/ChartError";
import NoData from "../NoData/NoData";
import * as constants from "../constants";
import { ChartColor } from "../types";
import { getChartColor } from "../utils";

export type BarChartData = {
  [key: string]: number | string | [number, number];
}[];

export interface BarChartInterface {
  data: BarChartData;
  xAxisKey: string;
  yAxisKeys: {
    key: string;
    name?: string;
    color?: ChartColor;
  }[];
  isStacked?: boolean;
  isAnimationActive?: boolean;
  isVertical?: boolean;
  isLoading?: boolean;
  error?: string | null;
  onClick?: (chartElement: unknown) => void;
}

const BarChart: React.FC<BarChartInterface> = ({
  data,
  isStacked,
  xAxisKey,
  yAxisKeys,
  isAnimationActive = true,
  isVertical,
  isLoading,
  error,
  onClick,
}) => {
  const handleClick = useCallback(
    (chartElement: unknown) => {
      if (onClick) {
        onClick(chartElement);
      }
    },
    [onClick]
  );

  if (isLoading) {
    return (
      <ResponsiveContainer>
        <Skeleton type="chart" />
      </ResponsiveContainer>
    );
  }

  if (error) {
    return <ChartError error={error} />;
  }

  const classes = classNames("csis-bar-chart", {
    "csis-bar-chart--clickable": Boolean(onClick),
  });

  return data && data.length ? (
    <ResponsiveContainer>
      <BarChartRechart
        className={classes}
        layout={isVertical ? "vertical" : "horizontal"}
        data={data}
      >
        <XAxis
          dy={constants.axisMargin}
          tick={{
            fontSize: constants.fontSize,
            fontWeight: constants.fontWeight,
          }}
          type={isVertical ? "number" : "category"}
          axisLine={!isVertical}
          hide={isVertical}
          dataKey={!isVertical ? xAxisKey : undefined}
        />
        <YAxis
          // unfortunatelly the yaxis label is not responsive for big labels
          // so we have to do this ugly thing
          width={isVertical ? 300 : undefined}
          dx={-constants.axisMargin}
          tick={{
            fontSize: constants.fontSize,
            fontWeight: constants.fontWeight,
          }}
          interval={0}
          axisLine={isVertical || false}
          type={isVertical ? "category" : "number"}
          dataKey={isVertical ? xAxisKey : undefined}
        />
        <Tooltip isAnimationActive={false} content={CustomTooltip} />
        <CartesianGrid
          strokeWidth={constants.cartesianStrokeWidth}
          vertical={false}
          horizontal={!isVertical}
        />
        {yAxisKeys.map((yAxisKey, index) => (
          <Bar
            key={yAxisKey.key}
            isAnimationActive={isAnimationActive}
            className={getChartColor(index, yAxisKey.color)}
            maxBarSize={constants.maxBarSize}
            dataKey={yAxisKey.key}
            name={yAxisKey.name ? yAxisKey.name : yAxisKey.key}
            stackId={isStacked ? xAxisKey : undefined}
            onClick={handleClick}
          />
        ))}
      </BarChartRechart>
    </ResponsiveContainer>
  ) : (
    <NoData />
  );
};

export default BarChart;
