import { SQUARE_SIZE, TOTAL_HEAT_LEVELS } from "./constants";
import { HeatRanges } from "./utils";

export default function HeatLegend({
  ranges,
  maxCount,
}: {
  ranges: HeatRanges;
  maxCount: number;
}) {
  function getHeatLegendSquare(lvl: number) {
    return (
      <div
        style={{ width: SQUARE_SIZE, height: SQUARE_SIZE }}
        title={
          // it doesnt make sense to show distinct heat levels if the maxcount is less than 4
          maxCount >= TOTAL_HEAT_LEVELS
            ? lvl === ranges.length
              ? "Values between " + ranges[lvl - 1] + " and " + maxCount
              : "Values between " + ranges[lvl - 1] + " and " + ranges[lvl]
            : undefined
        }
        className={
          "heatcalendar__day heatcalendar__day-with-content day-lvl" + lvl
        }
      />
    );
  }

  return (
    <div className="heatcalendar__legend-container">
      <div className="heatcalendar__legend">
        <div>Less</div>
        {getHeatLegendSquare(1)}
        {getHeatLegendSquare(2)}
        {getHeatLegendSquare(3)}
        {getHeatLegendSquare(4)}
        <div>More</div>
      </div>
    </div>
  );
}
