import { useEffect, useRef } from "react";

export function isNull(x: any): x is null {
  return x === null;
}

export function isUndefined(x: any): x is undefined {
  return x === undefined;
}

// Strictly return true for only undefined or null
// "", 0 and false do not return true!
export function isNullOrUndefined(x: any): x is null | undefined {
  return isNull(x) || isUndefined(x);
}

export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

// returns true if the arrays have the EXACT same elements regardless of order
export function areArraysEqualSets<A>(arr1: A[], arr2: A[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return (
    arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item))
  );
}

// Can truncate text in the middle (xxx...xxx)
// Or at the end (xxx...)
// NOTE! by design it never truncates strings less than length === 6
export function truncateText({
  text,
  maxChars,
  truncateAtTheEnd,
}: {
  text: string;
  maxChars: number;
  truncateAtTheEnd?: boolean;
}): string {
  const MINIMUM_CHARS = 6;

  if (
    maxChars &&
    maxChars !== 0 &&
    text.length > maxChars &&
    maxChars > MINIMUM_CHARS
  ) {
    if (truncateAtTheEnd) {
      return text.length > maxChars
        ? `${text.substring(0, maxChars)}...`
        : text;
    } else {
      const textBeforeTrunc = text.slice(0, Math.floor(maxChars / 2));
      const textAfterTrunc = text.slice(-Math.ceil(maxChars / 2) + 3); //plus 3 for the added 3 dots
      return `${textBeforeTrunc}...${textAfterTrunc}`;
    }
  }

  return text;
}

// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// see all the pros in the url above and why this is better than vanilla setInterval

type IntervalFunction = () => void;

export function useInterval(callback: IntervalFunction, delay: number | null) {
  const savedCallback = useRef<IntervalFunction | null>(null);
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay === null) {
      return;
    }
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export function getDateDifferenceInDaysBetweenTwoDates(
  endDate: Date,
  startDate: Date
) {
  const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;
  const timeDiff = endDate.valueOf() - startDate.valueOf();
  return Math.ceil(timeDiff / MILLISECONDS_IN_ONE_DAY);
}

export function getDateDifferenceInHoursBetweenTwoDates(
  endDate: Date,
  startDate: Date
) {
  const MILLISECONDS_IN_ONE_HOUR = 60 * 60 * 1000;
  const timeDiff = endDate.valueOf() - startDate.valueOf();
  return Math.ceil(timeDiff / MILLISECONDS_IN_ONE_HOUR);
}

function getUserLocale(): string {
  return navigator.languages
    ? navigator.languages[0]
    : navigator.language ||
        // @ts-ignore
        navigator?.userLanguage ||
        "en-GB";
}

export function formatBigNumbers(number: number) {
  const userLocale = getUserLocale();

  // "fr" locale uses  U+202F : NARROW NO-BREAK SPACE [NNBSP] to format big numbers so 9999999 -> 9 999 999
  return number.toLocaleString(userLocale);
}

// Generic function to toggle an item in an array
export function toggleArrayItem<T>(array: T[], item: T): T[] {
  return array.includes(item)
    ? array.filter((value) => value !== item)
    : [...array, item];
}
