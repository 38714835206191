import classNames from "classnames";
import { useState } from "react";
import { IconButton, Stack } from "@csis.com/components";
import OrgsDropdown from "./OrgsDropdown";
import SidebarHeader from "./SidebarHeader";
import SidebarMenu from "./SidebarMenu";
import { SidebarInterface } from "./types";

const Sidebar = ({
  menuItems,
  route = "",
  isAdmin,
  isMenusPending,
  enabledMenus,
  enabledMenusNew,
  selectedOnboardingStep,
  logo,
  homeUrl,
  organizations,
  selectedOrgId,
  onSelectedOrgChange,
}: SidebarInterface) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const classes = classNames("sidebar", {
    "sidebar--minimized": isMinimized,
  });

  return (
    <>
      <div className="sidebar-mobile">
        <div className="sidebar-mobile__icon">
          <IconButton
            type="text"
            size="large"
            isCircle
            spacing="normal"
            icon={"menu_collapsed"}
            onButtonClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
            dataTestId="sidebar-mobile__icon"
          />
        </div>
        {isMobileMenuVisible && (
          <div className="sidebar-mobile__dialog">
            <SidebarHeader
              isMinimized={!isMobileMenuVisible}
              setIsMinimized={() => setIsMobileMenuVisible(false)}
              isAdmin={isAdmin}
              logo={logo}
              homeUrl={homeUrl}
            />
            <OrgsDropdown
              organizations={organizations}
              selectedOrgId={selectedOrgId}
              onSelectedOrgChange={onSelectedOrgChange}
            />
            <div
              onClick={() => {
                setIsMobileMenuVisible(false);
              }}
            >
              <SidebarMenu
                menuItems={menuItems}
                route={route}
                isAdmin={isAdmin}
                isMenusPending={isMenusPending}
                enabledMenus={enabledMenus}
                enabledMenusNew={enabledMenusNew}
              />
            </div>
          </div>
        )}
      </div>
      <div className={classes}>
        <Stack gutterSize="big" isVertical align="stretch">
          <SidebarHeader
            isMinimized={isMinimized}
            setIsMinimized={setIsMinimized}
            isAdmin={isAdmin}
            logo={logo}
            homeUrl={homeUrl}
          />
          <OrgsDropdown
            organizations={organizations}
            selectedOrgId={selectedOrgId}
            onSelectedOrgChange={onSelectedOrgChange}
          />
        </Stack>
        <SidebarMenu
          menuItems={menuItems}
          route={route}
          isAdmin={isAdmin}
          isMenusPending={isMenusPending}
          enabledMenus={enabledMenus}
          enabledMenusNew={enabledMenusNew}
          selectedOnboardingStep={selectedOnboardingStep}
        />
      </div>
    </>
  );
};

export default Sidebar;
