import { axiosCsisApi } from "@csis.com/tip/src/App";

export async function fetchCsrfTokenApi() {
  return axiosCsisApi.csrfTokenRouteApi10CsrfTokenGet();
}

export async function resetPasswordEmailPostApi(
  {
    login,
  }: {
    login: string;
  },
  csrfToken: string
) {
  return axiosCsisApi.createPasswordResetApi10ResetPasswordResetEmailPost(
    {
      login: login,
    },
    { headers: { "X-CSRF-Token": csrfToken } }
  );
}
