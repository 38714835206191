import classNames from "classnames";
import React from "react";
import Skeleton from "../Skeleton/Skeleton";

export interface ListItemValueInterface {
  isPending?: boolean;
  children: React.ReactNode;
}

const ListItemValue: React.FC<ListItemValueInterface> = ({
  isPending,
  children,
}) => {
  const classes = classNames("list__item__value", {});

  return <div className={classes}>{isPending ? <Skeleton /> : children}</div>;
};

export default ListItemValue;
