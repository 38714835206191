import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  addGroupByParamBasedOnRange,
  mapAlertsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Alerts/AlertsSearch/api/utils";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import {
  AlerterHistogramEntry,
  AlerterTotalEntry,
  QueryParams,
} from "../types";

const alertsStatisticsAlertersSlice = createAsyncArrayFetchSlice<
  AlerterTotalEntry,
  QueryParams
>("alertsStatisticsAlerter");

export const alertsStatisticsAlerterReducer =
  alertsStatisticsAlertersSlice.reducer;

export const {
  fetchData: fetchAlerterDistribution,
  setIsPending: setIsAlerterDistributionPending,
  setFetchError: setAlerterDistributionFetchError,
  setFetchSuccess: setFetchAlerterDistributionSuccess,
} = alertsStatisticsAlertersSlice.actions;

const alertsStatisticsOverTimeSlice = createAsyncArrayFetchSlice<
  AlerterHistogramEntry,
  QueryParams
>("alertsStatisticsAlerterOverTime");

export const alertsStatisticsAlerterOverTimeReducer =
  alertsStatisticsOverTimeSlice.reducer;

export const {
  fetchData: fetchAlerterDistributionOverTime,
  setIsPending: setIsAlerterDistributionOverTimePending,
  setFetchError: setAlerterDistributionOverTimeFetchError,
  setFetchSuccess: setFetchAlerterDistributionOverTimeSuccess,
} = alertsStatisticsOverTimeSlice.actions;

// Async stuff - sagas

function* fetchAlerterDistributionSaga(action: PayloadAction<QueryParams>) {
  yield put(setIsAlerterDistributionPending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);

    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      axiosCsisApi.getStatisticsAlerterApi10AlertStatisticsAlerterGet,
      {
        ...mapAlertsStartAndEndDateToCorrectType(action.payload),
        organization_id: selectedOrgId,
      }
    );

    yield put(setFetchAlerterDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setAlerterDistributionFetchError());
  }
}

function* fetchAlerterDistributionOverTimeSaga(
  action: PayloadAction<QueryParams>
) {
  yield put(setIsAlerterDistributionOverTimePending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);

    const params = {
      ...addGroupByParamBasedOnRange(
        mapAlertsStartAndEndDateToCorrectType(action.payload)
      ),
      organization_id: selectedOrgId,
    };
    const response: AxiosResponse<StatisticsHistogramCountListResponse> =
      yield call(
        axiosCsisApi.getStatisticsAlerterHistogramApi10AlertStatisticsAlerterHistogramGet,
        params
      );

    yield put(
      setFetchAlerterDistributionOverTimeSuccess(response.data.payload)
    );
  } catch (e) {
    yield put(setAlerterDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchAlerterDistribution.toString(),
    fetchAlerterDistributionSaga
  );
  yield takeLatest(
    fetchAlerterDistributionOverTime.toString(),
    fetchAlerterDistributionOverTimeSaga
  );
}

export function* alertsStatisticsAlerterSagas() {
  yield all([actionWatcher()]);
}
