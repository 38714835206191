import { DatePickerOption, TimePeriod, timePeriods } from "./types";

export const timePeriodLabels = {
  [timePeriods.TODAY]: "Today",
  [timePeriods.LAST_7_DAYS]: "Last 7 days",
  [timePeriods.LAST_14_DAYS]: "Last 14 days",
  [timePeriods.LAST_30_DAYS]: "Last 30 days",
  [timePeriods.LAST_90_DAYS]: "Last 90 days",
  [timePeriods.LAST_365_DAYS]: "Last 365 days",
  [timePeriods.YTD]: "Year to date",
  [timePeriods.ALL_TIME]: "All time",
  [timePeriods.CUSTOM]: "Custom",
  [timePeriods.PREVIOUS_WEEK]: "Previous Week",
  [timePeriods.PREVIOUS_MONTH]: "Previous Month",
  [timePeriods.PREVIOUS_QUARTER]: "Previous Quarter",
};

export const timePeriodTooltips = {
  [timePeriods.PREVIOUS_WEEK]: "Most recently completed calendar week",
  [timePeriods.PREVIOUS_MONTH]: "Most recently completed calendar month",
  [timePeriods.PREVIOUS_QUARTER]: "Most recently completed calendar quarter",
};

// all available options for the date picker
export const datePickerOptions: Record<TimePeriod, DatePickerOption> = {
  [timePeriods.TODAY]: {
    value: timePeriods.TODAY,
    label: timePeriodLabels[timePeriods.TODAY],
  },
  [timePeriods.LAST_7_DAYS]: {
    value: timePeriods.LAST_7_DAYS,
    label: timePeriodLabels[timePeriods.LAST_7_DAYS],
  },
  [timePeriods.LAST_14_DAYS]: {
    value: timePeriods.LAST_14_DAYS,
    label: timePeriodLabels[timePeriods.LAST_14_DAYS],
  },
  [timePeriods.LAST_30_DAYS]: {
    value: timePeriods.LAST_30_DAYS,
    label: timePeriodLabels[timePeriods.LAST_30_DAYS],
  },
  [timePeriods.LAST_90_DAYS]: {
    value: timePeriods.LAST_90_DAYS,
    label: timePeriodLabels[timePeriods.LAST_90_DAYS],
  },
  [timePeriods.LAST_365_DAYS]: {
    value: timePeriods.LAST_365_DAYS,
    label: timePeriodLabels[timePeriods.LAST_365_DAYS],
  },
  [timePeriods.YTD]: {
    value: timePeriods.YTD,
    label: timePeriodLabels[timePeriods.YTD],
  },
  [timePeriods.ALL_TIME]: {
    value: timePeriods.ALL_TIME,
    label: timePeriodLabels[timePeriods.ALL_TIME],
  },
  [timePeriods.CUSTOM]: {
    value: timePeriods.CUSTOM,
    label: timePeriodLabels[timePeriods.CUSTOM],
  },
  [timePeriods.PREVIOUS_WEEK]: {
    value: timePeriods.PREVIOUS_WEEK,
    label: timePeriodLabels[timePeriods.PREVIOUS_WEEK],
    tooltipText: timePeriodTooltips[timePeriods.PREVIOUS_WEEK],
  },
  [timePeriods.PREVIOUS_MONTH]: {
    value: timePeriods.PREVIOUS_MONTH,
    label: timePeriodLabels[timePeriods.PREVIOUS_MONTH],
    tooltipText: timePeriodTooltips[timePeriods.PREVIOUS_MONTH],
  },
  [timePeriods.PREVIOUS_QUARTER]: {
    value: timePeriods.PREVIOUS_QUARTER,
    label: timePeriodLabels[timePeriods.PREVIOUS_QUARTER],
    tooltipText: timePeriodTooltips[timePeriods.PREVIOUS_QUARTER],
  },
};

export const DEFAULT_OPTIONS: DatePickerOption[] = [
  datePickerOptions[timePeriods.TODAY],
  datePickerOptions[timePeriods.LAST_7_DAYS],
  datePickerOptions[timePeriods.LAST_30_DAYS],
  datePickerOptions[timePeriods.LAST_365_DAYS],
  datePickerOptions[timePeriods.YTD],
  datePickerOptions[timePeriods.ALL_TIME],
  datePickerOptions[timePeriods.CUSTOM],
];
