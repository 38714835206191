import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { FilterElement } from "./FiltersSection/FiltersSection";

// given filters and a search string,
// it returns a string like this: "key:value key2:value2 searchstring" etc
// it also accepts an array of "filter keys" that their values should be "quoted"
export function getSearchString<K extends string>(
  filterElements: FilterElement<K>[],
  searchString: string,
  quotedKeys?: Array<string>
) {
  let newSearchString = "";
  filterElements.forEach((filter) => {
    if (quotedKeys?.includes(filter.key)) {
      newSearchString += `${filter.key}:"${filter.value}" `;
    } else {
      newSearchString += `${filter.key}:${filter.value} `;
    }
  });
  return (newSearchString + searchString).replace(/\s\s+/g, " ");
}

// "global" utils that are used by SearchBar component files

export function removeFilterStringFromSearchString(
  filterString: string,
  searchString: string
) {
  return searchString.replace(filterString, "");
}

export function trimSpaces(string: string) {
  const trimmedString = string.replace(/(^\s+|\s+$)/g, "");
  if (trimmedString && trimmedString !== "") {
    return trimmedString;
  }
  return undefined;
}

export type FilterOption<K> = {
  key: K;
  keyTranslationKey: TranslationKey;
  valueTranslationKeys?: { [key: string]: TranslationKey };
  valueTransformFunction?: (value: string) => string;
};

// given a QueryParams Object
// and a FilterOptions "definition" of what filters we want returned
// this function returns an array of FilterElements
export function extractElementsFromQueryParams<
  Q extends {
    [key: string]: string[] | string | number[] | number | boolean | undefined;
  },
  K extends string
>({
  queryParams,
  filterOptions,
}: {
  queryParams: Q;
  filterOptions: FilterOption<K>[];
}): FilterElement<K>[] {
  const filterElements: FilterElement<K>[] = [];

  filterOptions.forEach((filterOption) => {
    const filterValue = queryParams[filterOption.key];

    if (filterValue) {
      // some filterValues are Arrays, so we have to extract each value
      if (Array.isArray(filterValue)) {
        filterValue.forEach((filter) => {
          filterElements.push({
            key: filterOption.key,
            keyTranslationKey: filterOption.keyTranslationKey,
            valueTranslationKey: filterOption.valueTranslationKeys?.[filter],
            value: filter,
            valueTransformFunction: filterOption.valueTransformFunction,
          });
        });
      } else {
        // if a filter is not an array, we just push the value
        filterElements.push({
          key: filterOption.key,
          keyTranslationKey: filterOption.keyTranslationKey,
          value: String(filterValue),
          valueTransformFunction: filterOption.valueTransformFunction,
        });
      }
    }
  });

  return filterElements;
}
