import { FC } from "react";
import { Stack, Tag } from "@csis.com/components";
import { ParamDataType } from "./types";

export const ParamList: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <>
      <span className="f_big f_semibold">Parameters:</span>
      <dl>{children}</dl>
    </>
  );
};

export const ParamListTerm: FC<{
  term: string;
  types: ParamDataType[] | string[] | undefined;
  isOptional?: Boolean;
  paramLocation?: "query" | "header" | "path" | "cookie" | string;
}> = ({ term, types, isOptional = false, paramLocation = "query" }) => {
  const typesStr = types?.join(" | ");

  const uppercasedParamLocation =
    paramLocation.slice(0, 1).toUpperCase() + paramLocation.slice(1);

  return (
    <dt>
      <Stack>
        <span className="f_bold">{term}</span>
        <span className="f_csis">
          {typesStr || "No types provided for this operation"}
        </span>
        <Tag color="csis" size="small" isCircle>
          {uppercasedParamLocation}
        </Tag>
        {isOptional ? (
          <Tag color="grey" size="small" isCircle>
            Optional
          </Tag>
        ) : (
          <Tag color="orange" size="small" isCircle>
            Required
          </Tag>
        )}
      </Stack>
    </dt>
  );
};

export const ParamListTermDetails: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <dd>{children}</dd>;
};
