import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const urlsKeys = {
  HASH: "url_hash",
  URL: "url",
  STATUS: "status",
  TARGETED_BRAND: "brand",
  IP: "ip",
  ASN: "asn",
  DOMAIN: "domain",
  HOSTNAME: "hostname",
  COUNTRY: "country_code",
  CREATED: "created",
  CREATED_AFTER: "created_after",
  CREATED_BEFORE: "created_before",
  START: "start",
  END: "end",
  FIRST_SEEN: "first_seen",
  LAST_SEEN: "last_seen",
  ORIGIN: "origin",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [
  urlsKeys.URL,
  urlsKeys.STATUS,
  urlsKeys.IP,
  urlsKeys.TARGETED_BRAND,
  urlsKeys.ASN,
  urlsKeys.COUNTRY,
  urlsKeys.HOSTNAME,
  urlsKeys.DOMAIN,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;
