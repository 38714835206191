import { axiosCsisApi } from "@csis.com/tip/src/App";
import { GetExportCsvApi20InvestigationExportCsvGetParams } from "@csis.com/tip/src/api/openapi/data-contracts";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { investigationsKeys } from "../constants";
import { QueryParams } from "../types";

export async function fetchInvestigationApi(
  queryParams: Partial<QueryParams>,
  onlyExternallyVisible: boolean = true
) {
  const entityType = queryParams[investigationsKeys.ENTITY_TYPE];
  const entityValue = queryParams[investigationsKeys.ENTITY_VALUE];

  if (entityType && entityValue) {
    return axiosCsisApi.getEntityApi20InvestigationEntityGet(
      {
        entity_type: entityType,
        entity_value: entityValue.map((value) => value.toString()),
        only_externally_visible: onlyExternallyVisible,
      },
      STRINGIFY_QUERY_PARAMS
    );
  }
  return null;
}

export async function exportInvestigationCsvApi(
  queryParams: Partial<QueryParams>,
  onlyExternallyVisible: boolean = true
) {
  const params = {
    ...queryParams,
    only_externally_visible: onlyExternallyVisible,
  } as GetExportCsvApi20InvestigationExportCsvGetParams;

  return axiosCsisApi.getExportCsvApi20InvestigationExportCsvGet(params, {
    format: "blob",
    ...STRINGIFY_QUERY_PARAMS,
  });
}
