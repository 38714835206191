import { RootState } from "@csis.com/tip/src/configureStore";

export const getIsPasswordResetSuccess = (state: RootState) =>
  state.passwordResetReducer.isSuccess;

export const getIsPasswordResetPending = (state: RootState) =>
  state.passwordResetReducer.isPasswordResetPending;

export const getPasswordResetError = (state: RootState) =>
  state.passwordResetReducer.passwordResetError;
