import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Carousel,
  Dialog,
  Icon,
  IconButton,
  Image,
  Reminder,
  Stack,
} from "@csis.com/components";
import { isNull } from "@csis.com/components/src/utils/utils";
import logoDefault from "@csis.com/tip/src/img/logo.png";
import { AccessibleServices } from "../../api/openapi/data-contracts";
import {
  getProfileResult,
  getUserSubscribedProductsNew,
} from "../../pages/Profile/Security/selectors";
import { setOnboardingStep } from "./slice";
import { SelectedOnboardingStep } from "./types";

const OnboardingHelp = () => {
  const dispatch = useDispatch();
  const userSubscribedProducts = useSelector(getUserSubscribedProductsNew);

  const [isTutorialDialogOpen, setIsTutorialDialogOpen] = useState(false);
  const { profile } = useSelector(getProfileResult);

  useEffect(() => {
    if (isNull(profile?.last_login)) {
      setIsTutorialDialogOpen(true);
    }
  }, [profile]);

  const handleCloseOnboarding = () => {
    dispatch(setOnboardingStep(null));
    setIsTutorialDialogOpen(false);
  };

  const stepToServiceMap: Record<string, keyof AccessibleServices> = {
    // add more here once ready
    "sidebar-reports": "reports",
  };

  // get the onboarding steps based on the user's subscribed products
  // filter out products that are not subscribed
  const onboardingSteps: SelectedOnboardingStep[] = [
    null, // first page is the welcome page
    "sidebar-dashboard",
    "sidebar-tickets",
    "sidebar-alerts",
    "sidebar-reports",
    "topbar-account",
    null, // last page is the thank you page
  ].filter((step): step is SelectedOnboardingStep => {
    if (step === null) return true;
    // get the service key based on the step if it exists
    const service = stepToServiceMap[step];
    if (!service) return true;
    // if the userSubscribedProducts is not available, assume false and dont show the tutorial step
    if (!userSubscribedProducts) {
      return false;
    }
    // if userSubscribedProducts has the product key as false, dont show the tutorial step
    return userSubscribedProducts?.[service] !== false;
  });

  const handleStepChange = (newIndex: number) => {
    const step = onboardingSteps[newIndex];
    if (step) {
      dispatch(setOnboardingStep(step));
    } else {
      dispatch(setOnboardingStep(null));
    }
  };

  return (
    <div className="onboardinghelp hide-on-mobile">
      <IconButton
        type="text"
        icon="info"
        tooltipText="Portal Guide"
        onButtonClick={() => setIsTutorialDialogOpen(true)}
        isCircle
        size="large"
        spacing="small"
        color="secondary"
      />
      {isTutorialDialogOpen && (
        <Dialog
          name="onboarding-dialog"
          size="big"
          dataTestId={"onboarding-dialog"}
          onClose={handleCloseOnboarding}
          color="gradient"
        >
          <Carousel
            arrowsPosition="bottom"
            onChange={handleStepChange}
            infiniteMode={false}
            items={[
              <div className="onboardinghelp__section">
                <Stack
                  gutterSize="huge"
                  isExpanded
                  isVertical
                  align="center"
                  justify="center"
                >
                  <Stack isVertical align="center">
                    <Image
                      hasTransparentBackground
                      source={logoDefault}
                      size="small"
                      alt={"csis_logo"}
                    />
                  </Stack>
                  <p className="f_big f_semibold">
                    Welcome to the CSIS Threat Intelligence Portal!
                  </p>
                  <p>
                    This portal guide will walk you through everything you need
                    to know to utilize our full range of cybersecurity tools and
                    resources.
                  </p>
                  <Reminder
                    withDot={false}
                    hasRoundedCorners={true}
                    underlineOnHover={false}
                    text={
                      <div>
                        You can revisit this guide anytime by clicking on the{" "}
                        <Icon kind="info" isInline color="grey" /> button
                        located at the top right corner of the page.
                      </div>
                    }
                    color={"orange"}
                  />
                </Stack>
              </div>,
              <div className="onboardinghelp__section">
                <Stack
                  gutterSize="large"
                  isExpanded
                  isVertical
                  align="center"
                  justify="center"
                >
                  <Icon kind="dashboard" size="gigantic" />
                  <div className="f_semibold f_large f_center f_uppercase">
                    Dashboard
                  </div>
                  <p>
                    The DASHBOARD is your control center, providing a
                    streamlined overview of service statistics.
                  </p>
                  <p>
                    Submenus are located in the sidebar on the left-hand side of
                    the page. From here you can access all active services for
                    your organization.
                  </p>
                </Stack>
              </div>,
              <div className="onboardinghelp__section">
                <Stack
                  gutterSize="large"
                  isExpanded
                  isVertical
                  align="center"
                  justify="center"
                >
                  <Icon kind="tickets" size="gigantic" />
                  <div className="f_semibold f_large f_center f_uppercase">
                    Tickets
                  </div>
                  <p>
                    The TICKETS tab is designated for service delivery and
                    serves as your communication link with the CSIS team.
                  </p>
                  <p>
                    To create a ticket - simply navigate to the top right corner
                    of the Dashboard or Tickets pages, choose your inquiry's
                    type, severity, and description, and submit.
                  </p>
                </Stack>
              </div>,
              <div className="onboardinghelp__section">
                <Stack
                  gutterSize="large"
                  isExpanded
                  isVertical
                  align="center"
                  justify="center"
                >
                  <Icon kind="alert_circle" size="gigantic" />
                  <div className="f_semibold f_large f_center f_uppercase">
                    Alerts
                  </div>
                  <p>
                    If you purchase services that are delivered via alerts,
                    you'll find these notifications in the ALERTS tab.
                  </p>
                </Stack>
              </div>,
              <div className="onboardinghelp__section" key="reports">
                <Stack
                  gutterSize="large"
                  isExpanded
                  isVertical
                  align="center"
                  justify="center"
                >
                  <Icon kind="reports" size="gigantic" />
                  <div className="f_semibold f_large f_center f_uppercase">
                    Reports
                  </div>
                  <p>
                    The REPORTS section is your hub for creating statistical
                    reports and accessing those already created for you by the
                    CSIS team.
                  </p>
                </Stack>
              </div>,
              <div className="onboardinghelp__section">
                <Stack
                  gutterSize="large"
                  isExpanded
                  isVertical
                  align="center"
                  justify="center"
                >
                  <Icon kind="account" size="gigantic" />
                  <div className="f_semibold f_large f_center f_uppercase">
                    Account Menu
                  </div>
                  <p>
                    From the ACCOUNT menu, you can modify your basic
                    information, update your password, set up One-Time Password
                    (OTP), and customize your notification preferences.
                  </p>
                </Stack>
              </div>,
              <div className="onboardinghelp__section">
                <Stack isVertical justify="center" isExpanded align="center">
                  <div>
                    <p className="f_semibold">
                      Should you have any questions, feel free to reach out to
                      our CSIS support team by creating a ticket.
                    </p>
                  </div>
                  <Stack justify="center">
                    <Button
                      text="get started"
                      name="get-started-tutorial-dialog"
                      onButtonClick={handleCloseOnboarding}
                    />
                  </Stack>
                </Stack>
              </div>,
            ].filter((section) => {
              if (section?.key) {
                const sectionKey = section.key as keyof AccessibleServices;
                if (!userSubscribedProducts) {
                  return false;
                } else {
                  return Boolean(userSubscribedProducts[sectionKey]);
                }
              }
              return true;
            })}
          />
        </Dialog>
      )}
    </div>
  );
};

export default OnboardingHelp;
