import React, { useState } from "react";
import Icon, { IconKind } from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import Divider from "../Divider/Divider";
import IconButton from "../IconButton/IconButton";
import Skeleton from "../Skeleton/Skeleton";
import {
  CommentAvatar,
  CommentAvatarInfo,
  isCommentAvatar,
} from "./CommentAvatar";

export interface CommentInterface {
  icon: IconKind | CommentAvatarInfo;
  title: string;
  badge?: string;
  date: string | JSX.Element;
  alignSelf?: "left" | "right";
  content: string;
  isCollapsed?: boolean;
  isLoading?: boolean;
  downloadAttachmentButtons?: JSX.Element[] | boolean;
}

const Comment: React.FC<CommentInterface> = ({
  icon,
  title,
  date,
  badge,
  alignSelf = "left",
  content,
  isCollapsed,
  isLoading,
  downloadAttachmentButtons,
}) => {
  const [isContentCollapsed, setIsContentCollapsed] = useState(isCollapsed);

  return (
    <Stack isReverse={alignSelf === "right"}>
      {isCommentAvatar(icon) ? (
        <CommentAvatar avatar={icon} />
      ) : (
        <Icon kind={icon} size="huge" />
      )}

      <div className={"comment"}>
        {isLoading ? (
          <Skeleton type="multiline" />
        ) : (
          <>
            <div className="comment__header">
              <Stack justify="space-between" align="center">
                <Stack gutterSize="normal" align="center">
                  <div className="f_csis f_semibold">{title}</div>
                  <div>-</div>
                  <div className="f_secondary f_small">{date}</div>
                </Stack>
                <IconButton
                  isCircle
                  size="big"
                  icon={isContentCollapsed ? "unfold_more" : "unfold_less"}
                  type="text"
                  onButtonClick={() =>
                    setIsContentCollapsed(!isContentCollapsed)
                  }
                />
              </Stack>
            </div>
            <div className="comment__header--mobile">
              <Stack gutterSize="normal" isVertical>
                <div className="f_csis f_semibold">{title}</div>
                <div className="f_secondary f_small">{date}</div>
              </Stack>
            </div>
          </>
        )}
        <Stack isVertical>
          {isContentCollapsed ? null : (
            <>
              <div className="comment__body">{content}</div>
              {Array.isArray(downloadAttachmentButtons) &&
                downloadAttachmentButtons.length > 0 && (
                  <>
                    {content && <Divider />}
                    <Stack isExpanded isVertical gutterSize="tiny" align="end">
                      {downloadAttachmentButtons}
                    </Stack>
                  </>
                )}
              {badge && (
                <img className="comment__badge" src={badge} alt="badge" />
              )}
            </>
          )}
        </Stack>
      </div>
    </Stack>
  );
};

export default Comment;
