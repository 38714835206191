import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";

export interface CollapsableGroupInterface {
  label: string;
  value?: string | JSX.Element;
  isCollapsed?: boolean;
  color?: "red" | "grey";
  isInline?: boolean;
  level?: number;
  // if we pass this, it means the component becomes "controlled" from the consumer
  onToggle?: () => void;
  inlineValue?: React.ReactNode;
  isVertical?: boolean;
  dataTestId?: string;
  children?: React.ReactNode;
}

const CollapsableGroup: React.FC<CollapsableGroupInterface> = ({
  children,
  label,
  value,
  isCollapsed,
  level = 0,
  isInline,
  color,
  onToggle,
  inlineValue,
  isVertical = false,
  dataTestId,
}) => {
  const [isGroupCollapsed, setIsGroupCollapsed] = useState(isCollapsed);

  useEffect(() => {
    setIsGroupCollapsed(isCollapsed);
  }, [isCollapsed]);

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child as React.ReactElement<any>, {
      level: level ? level + 1 : 1,
    });
  });

  const classes = classNames("list__collapsable-group list__row", {
    "list__row--level-0": level === 0,
    "list__row--level-1": level === 1,
    "list__row--level-2": level === 2,
    "list__row--level-3": level >= 3,
    "list__collapsable-group--red": color === "red",
    "list__collapsable-group--grey": color === "grey",
  });

  function handleToggleCollapse() {
    if (onToggle) {
      onToggle();
    } else {
      setIsGroupCollapsed(!isGroupCollapsed);
    }
  }

  return (
    <>
      <li className={classes}>
        <Stack
          gutterSize="small"
          align="stretch"
          isExpanded
          isVertical={isVertical}
        >
          <div
            className="list__collapsable-group__label"
            onClick={handleToggleCollapse}
            data-test-id={dataTestId ? dataTestId + "-label" : undefined}
          >
            <Stack align="center">
              <Icon kind={isGroupCollapsed ? "right-arrow" : "down-arrow"} />
              <div>{label}</div>
            </Stack>
          </div>
          <div
            className="list__collapsable-group__value"
            onClick={isGroupCollapsed ? handleToggleCollapse : undefined}
          >
            {!isGroupCollapsed && isInline ? inlineValue : value}
          </div>
        </Stack>
      </li>
      {!isGroupCollapsed && !isInline && childrenWithProps}
    </>
  );
};

export default CollapsableGroup;
