import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { EntityType } from "./EntityType";
import { fetchEntityTypesApi } from "./api/api";
import { EntitiesResponse } from "./api/types";

interface StateSlice {
  entities: EntityType[];
}
const initialState: StateSlice = {
  entities: [],
};

const entitiesSlice = createSlice({
  name: "entities",
  initialState: initialState,
  reducers: {
    fetchEntities(
      _state,
      _action: PayloadAction<{ onlyExternallyVisible: boolean } | undefined>
    ) {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<EntityType[]>) {
      state.entities = action.payload;
    },
  },
});

export default entitiesSlice.reducer;

export const { fetchEntities, fetchSuccess } = entitiesSlice.actions;

// Async stuff - sagas

function* fetchEntitiesSaga(
  action: PayloadAction<{ onlyExternallyVisible: boolean } | undefined>
) {
  try {
    const response: AxiosResponse<EntitiesResponse> = yield call(
      fetchEntityTypesApi,
      action.payload?.onlyExternallyVisible
    );

    yield put(fetchSuccess(response.data.payload));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchEntities.toString(), fetchEntitiesSaga);
}

export function* investigationsEntitiesSagas() {
  yield all([actionWatcher()]);
}
