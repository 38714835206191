import React from "react";
import { Icon, Stack } from "@csis.com/components";
import { BIG } from "../../sizes";
import { DropdownOption } from "../Dropdown/Dropdown";
import ListMenu from "../ListMenu/ListMenu";
import ListMenuBody from "../ListMenu/ListMenuBody";
import ListMenuItem from "../ListMenu/ListMenuItem";

export interface IconMenuListInterface {
  options: (DropdownOption<number | string> | React.ReactElement)[];
  onOptionSelected: (value: number | string) => void;
  opensFromRight?: boolean;
  size?: BIG;
  opensFromTop?: boolean;
  parentHeight?: number;
  parentName: string;
}

const IconMenuList: React.FC<IconMenuListInterface> = ({
  options,
  onOptionSelected,
  size,
  opensFromRight,
  opensFromTop,
  parentHeight,
  parentName,
}) => {
  return (
    <ListMenu
      opensFromTop={opensFromTop}
      opensFromRight={opensFromRight}
      size={size}
      parentHeight={parentHeight}
      parentName={parentName}
    >
      <ListMenuBody>
        {options.map((option, index) => {
          if (React.isValidElement(option)) {
            return option;
          } else if (typeof option !== "undefined" && "value" in option) {
            return (
              <ListMenuItem
                onClick={() => onOptionSelected(option.value)}
                key={option.value || index}
              >
                <Stack gutterSize="normal" align="center">
                  {option.icon && <Icon kind={option.icon} size="large" />}
                  <span>{option.label}</span>
                </Stack>
              </ListMenuItem>
            );
          }
          return null;
        })}
      </ListMenuBody>
    </ListMenu>
  );
};

export default IconMenuList;
