import React from "react";
import { isNullOrUndefined } from "../../utils/utils";
import Draggable from "../Draggable/Draggable";

export interface DraggableListItemInterface {
  index?: number;
  onDragStart: (value: { index: number; height: number }) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  children: React.ReactNode;
}

const DraggableListItem: React.FC<DraggableListItemInterface> = ({
  children,
  index,
  onDragStart,
  onDragEnd,
}) => {
  const handleDragStart = (
    _: React.DragEvent<HTMLDivElement>,
    element: HTMLDivElement
  ) => {
    if (!isNullOrUndefined(index)) {
      onDragStart({
        index: index,
        height: element.getBoundingClientRect().height,
      });
    }
  };

  return (
    <Draggable
      isFullWidth
      onDragStart={handleDragStart}
      onDragEnd={onDragEnd}
      hideOnDrag={true}
    >
      {children}
    </Draggable>
  );
};

export default DraggableListItem;
