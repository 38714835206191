import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { fork } from "redux-saga/effects";
import newsReducer, {
  newsSagas,
} from "@csis.com/tip/src/components/News/slice";
import mdfeOnboardingReducer, {
  mdfeOnboardingSagas,
} from "@csis.com/tip/src/mdr/onboarding/mdfe/slice";
import alertsAlertersReducer, {
  alertsAlertersSagas,
} from "@csis.com/tip/src/pages/Alerts/models/Alerter/slice";
import passwordResetEmailReducer, {
  passwordResetEmailSagas,
} from "@csis.com/tip/src/pages/PasswordReset/PasswordResetEmail/slice";
import passwordResetReducer, {
  passwordResetSagas,
} from "@csis.com/tip/src/pages/PasswordReset/slice";
import compromisedDataCCReportReducer, {
  compromisedDataCCReportSagas,
} from "@csis.com/tip/src/pages/Products/CompromisedData/CreditCards/Report/slice";
import compromisedDataCCReducer, {
  compromisedDataCCSagas,
} from "@csis.com/tip/src/pages/Products/CompromisedData/CreditCards/Search/slice";
import compromisedDataVictimsInfoReportReducer, {
  compromisedDataVictimsInfoReportSagas,
} from "@csis.com/tip/src/pages/Products/CompromisedData/VictimsInfo/Report/slice";
import compromisedDataVictimsInfoReducer, {
  compromisedDataVictimsInfoSagas,
} from "@csis.com/tip/src/pages/Products/CompromisedData/VictimsInfo/Search/slice";
import investigationsEntitiesReducer, {
  investigationsEntitiesSagas,
} from "@csis.com/tip/src/pages/Products/Investigations/EntityType/slice";
import urlsReducer, {
  urlsSagas,
} from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/slice";
import alertsSettingsReducer, {
  alertsSettingsSagas,
} from "@csis.com/tip/src/pages/Profile/NotificationSettings/Alerts/slice";
import articlesSettingsReducer, {
  articlesSettingsSagas,
} from "@csis.com/tip/src/pages/Profile/NotificationSettings/Articles/slice";
import cardsSettingsReducer, {
  cardsSettingsSagas,
} from "@csis.com/tip/src/pages/Profile/NotificationSettings/Cards/slice";
import credentialsSettingsReducer, {
  credentialsSettingsSagas,
} from "@csis.com/tip/src/pages/Profile/NotificationSettings/Credentials/slice";
import ticketsSettingsReducer, {
  ticketsSettingsSagas,
} from "@csis.com/tip/src/pages/Profile/NotificationSettings/Tickets/slice";
import profileReducer, {
  profileSagas,
} from "@csis.com/tip/src/pages/Profile/Security/slice";
import newReportOptionsReducer, {
  newReportOptionsSagas,
} from "@csis.com/tip/src/pages/Reports/NewReportDialog/slice";
import reportTypesReducer, {
  reportTypesSagas,
} from "@csis.com/tip/src/pages/Reports/models/ReportType/slice";
import reportsReducer, {
  reportsSagas,
} from "@csis.com/tip/src/pages/Reports/slice";
import insightReducer, {
  insightSagas,
} from "@csis.com/tip/src/pages/ThreatInsights/Article/slice";
import insightsReducer, {
  insightsSagas,
} from "@csis.com/tip/src/pages/ThreatInsights/Search/slice";
import insightsCategoryReducer, {
  insightsCategoriesSagas,
} from "@csis.com/tip/src/pages/ThreatInsights/models/Category/slice";
import userPreferencesReducer, {
  userPreferencesSagas,
} from "@csis.com/tip/src/userPreferences/slice";
import onboardingReducer from "./components/OnboardingHelp/slice";
import alertReducer, { alertSagas } from "./pages/Alerts/Alert/slice";
import alertsReducer, { alertsSagas } from "./pages/Alerts/AlertsSearch/slice";
import {
  alertsStatisticsAlerterOverTimeReducer,
  alertsStatisticsAlerterReducer,
  alertsStatisticsAlerterSagas,
} from "./pages/Alerts/Statistics/Alerter/slice";
import {
  alertsStatisticsSeverityOverTimeReducer,
  alertsStatisticsSeverityReducer,
  alertsStatisticsSeveritySagas,
} from "./pages/Alerts/Statistics/Severity/slice";
import {
  alertsStatisticsStatusOverTimeReducer,
  alertsStatisticsStatusReducer,
  alertsStatisticsStatusSagas,
} from "./pages/Alerts/Statistics/Status/slice";
import emergencyCaseConfirmationReducer, {
  EmergencyCaseConfirmationSagas,
} from "./pages/EmergencyCaseConfirmation/slice";
import businessUnitReducer, {
  businessUnitsSagas,
} from "./pages/EmergencyCases/BusinessUnitSelect/slice";
import emergencyCaseReducer, {
  emergencyCaseSaga,
} from "./pages/EmergencyCases/EmergencyCase/slice";
import {
  emergencyCasesAdminReducer,
  emergencyCasesReducer,
  emergencyCasesSaga,
} from "./pages/EmergencyCases/EmergencyCaseSearch/slice";
import newEmergencyCaseReducer, {
  newEmergencyCaseSaga,
} from "./pages/EmergencyCases/NewEmergencyCaseDialog/slice";
import loginReducer, { loginSagas } from "./pages/Login/slice";
import cardsStatisticsMatcherReducer, {
  cardsStatisticsMatcherSagas,
} from "./pages/Products/CompromisedData/CreditCards/Statistics/Matcher/slice";
import cardsStatisticsSourceReducer, {
  cardsStatisticsSourceSagas,
} from "./pages/Products/CompromisedData/CreditCards/Statistics/Source/slice";
import cardsStatisticsStatusReducer, {
  cardsStatisticsStatusSagas,
} from "./pages/Products/CompromisedData/CreditCards/Statistics/Status/slice";
import credentialsStatisticsMatcherReducer, {
  credentialsStatisticsMatcherSagas,
} from "./pages/Products/CompromisedData/VictimsInfo/Statistics/Matcher/slice";
import credentialsStatisticsSourceReducer, {
  credentialsStatisticsSourceSagas,
} from "./pages/Products/CompromisedData/VictimsInfo/Statistics/Source/slice";
import credentialsStatisticsStatusReducer, {
  credentialsStatisticsStatusSagas,
} from "./pages/Products/CompromisedData/VictimsInfo/Statistics/Status/slice";
import emergencyResponseReducer, {
  emergencyResponseRetainerSaga,
} from "./pages/Products/EmergencyResponseRetainer/slice";
import investigationsReducer, {
  investigationsSagas,
} from "./pages/Products/Investigations/Search/slice";
import threatCloudInvestigationsReducer, {
  threatCloudInvestigationsSagas,
} from "./pages/Products/Investigations/ThreatCloud/slice";
import urlsLegacyStatsReducer, {
  urlsLegacyStatsSagas,
} from "./pages/Products/Phishing/Statistics/LegacyStats/slice";
import urlsStatisticsStatusReducer, {
  urlsStatisticsStatusSagas,
} from "./pages/Products/Phishing/Statistics/Status/slice";
import urlReducer, { urlSagas } from "./pages/Products/Phishing/Url/slice";
import usageReducer, {
  usageSagas,
} from "./pages/Products/Phishing/Usage/slice";
import phishingAsnsReducer, {
  phishingAsnsSagas,
} from "./pages/Products/Phishing/models/Asn/slice";
import phishingBrandsReducer, {
  phishingBrandsSagas,
} from "./pages/Products/Phishing/models/Brand/slice";
import phishingCountriesReducer, {
  phishingCountriesSagas,
} from "./pages/Products/Phishing/models/Country/slice";
import clientRolesReducer, {
  clientRolesSagas,
} from "./pages/Profile/Organization/ApiClients/ClientRoles/slice";
import {
  apiClientsReducer,
  apiClientsSagas,
  apiClientsUpdateReducer,
  clientSecretSliceReducer,
} from "./pages/Profile/Organization/ApiClients/slice";
import auditLogReducer, {
  auditLogSagas,
} from "./pages/Profile/Organization/AuditLog/slice";
import {
  escalationContactsReducer,
  escalationContactsSagas,
  escalationContactsUpdateReducer,
} from "./pages/Profile/Organization/UsersAndAccess/EscalationContacts/slice";
import {
  ipRangesReducer,
  ipRangesSagas,
  ipRangesUpdateReducer,
} from "./pages/Profile/Organization/UsersAndAccess/IpRanges/slice";
import rolesReducer, {
  rolesSagas,
} from "./pages/Profile/Organization/UsersAndAccess/Roles/slice";
import {
  userGroupsReducer,
  userGroupsSagas,
  userGroupsUpdateReducer,
  userGroupsUpdateUsersReducer,
} from "./pages/Profile/Organization/UsersAndAccess/UserGroups/slice";
import {
  usersReducer,
  usersSagas,
  usersUpdateReducer,
} from "./pages/Profile/Organization/UsersAndAccess/Users/slice";
import retainerReducer, {
  retainerSagas,
} from "./pages/Retainers/Retainer/slice";
import retainersReducer, {
  retainersSagas,
} from "./pages/Retainers/RetainersSearch/slice";
import retainerTypesReducer, {
  retainersTypesSagas,
} from "./pages/Retainers/models/RetainerType/slice";
import newTicketReducer, {
  newTicketSagas,
} from "./pages/Tickets/NewTicketDialog/slice";
import {
  ticketsStatisticsSeverityOverTimeReducer,
  ticketsStatisticsSeverityReducer,
  ticketsStatisticsSeveritySagas,
} from "./pages/Tickets/Statistics/Severity/slice";
import ticketsStatisticsStatusReducer, {
  ticketsStatisticsStatusSagas,
} from "./pages/Tickets/Statistics/Status/slice";
import ticketsStatisticsTypeReducer, {
  ticketsStatisticsTypeSagas,
} from "./pages/Tickets/Statistics/TicketType/slice";
import ticketReducer, { ticketSagas } from "./pages/Tickets/Ticket/slice";
import ticketsReducer, {
  ticketsSagas,
} from "./pages/Tickets/TicketsSearch/slice";
import ticketsFlagsReducer, {
  ticketsFlagsSagas,
} from "./pages/Tickets/models/Flag/slice";
import translationReducer from "./translations/slice";

const rootReducer = combineReducers({
  loginReducer,
  translationReducer,
  ticketReducer,
  ticketsReducer,
  newTicketReducer,
  ticketsStatisticsSeverityReducer,
  ticketsStatisticsSeverityOverTimeReducer,
  ticketsStatisticsTypeReducer,
  ticketsStatisticsStatusReducer,
  ticketsFlagsReducer,
  alertReducer,
  alertsReducer,
  alertsStatisticsSeverityReducer,
  alertsStatisticsSeverityOverTimeReducer,
  alertsStatisticsStatusReducer,
  alertsStatisticsStatusOverTimeReducer,
  alertsStatisticsAlerterReducer,
  alertsStatisticsAlerterOverTimeReducer,
  urlsReducer,
  urlReducer,
  phishingBrandsReducer,
  phishingAsnsReducer,
  phishingCountriesReducer,
  urlsStatisticsStatusReducer,
  urlsLegacyStatsReducer,
  compromisedDataCCReducer,
  compromisedDataCCReportReducer,
  cardsStatisticsStatusReducer,
  cardsStatisticsSourceReducer,
  cardsStatisticsMatcherReducer,
  compromisedDataVictimsInfoReducer,
  compromisedDataVictimsInfoReportReducer,
  credentialsStatisticsStatusReducer,
  credentialsStatisticsSourceReducer,
  credentialsStatisticsMatcherReducer,
  usageReducer,
  profileReducer,
  investigationsReducer,
  threatCloudInvestigationsReducer,
  insightsReducer,
  insightReducer,
  alertsSettingsReducer,
  ticketsSettingsReducer,
  reportTypesReducer,
  newReportOptionsReducer,
  articlesSettingsReducer,
  credentialsSettingsReducer,
  cardsSettingsReducer,
  insightsCategoryReducer,
  investigationsEntitiesReducer,
  alertsAlertersReducer,
  mdfeOnboardingReducer,
  reportsReducer,
  userPreferencesReducer,
  passwordResetReducer,
  newsReducer,
  emergencyResponseReducer,
  retainersReducer,
  retainerReducer,
  passwordResetEmailReducer,
  emergencyCaseConfirmationReducer,
  retainerTypesReducer,
  emergencyCasesReducer,
  emergencyCasesAdminReducer,
  newEmergencyCaseReducer,
  businessUnitReducer,
  emergencyCaseReducer,
  userGroupsReducer,
  userGroupsUpdateReducer,
  userGroupsUpdateUsersReducer,
  rolesReducer,
  escalationContactsReducer,
  escalationContactsUpdateReducer,
  ipRangesReducer,
  ipRangesUpdateReducer,
  usersReducer,
  usersUpdateReducer,
  auditLogReducer,
  apiClientsReducer,
  apiClientsUpdateReducer,
  clientRolesReducer,
  clientSecretSliceReducer,
  onboardingReducer,
});

export function* rootSaga() {
  yield fork(loginSagas);
  yield fork(ticketsSagas);
  yield fork(alertsSagas);
  yield fork(alertsStatisticsSeveritySagas);
  yield fork(alertsStatisticsStatusSagas);
  yield fork(alertsStatisticsAlerterSagas);
  yield fork(urlsSagas);
  yield fork(urlSagas);
  yield fork(urlsStatisticsStatusSagas);
  yield fork(urlsLegacyStatsSagas);
  yield fork(phishingBrandsSagas);
  yield fork(phishingAsnsSagas);
  yield fork(phishingCountriesSagas);
  yield fork(usageSagas);
  yield fork(alertSagas);
  yield fork(ticketSagas);
  yield fork(ticketsFlagsSagas);
  yield fork(newTicketSagas);
  yield fork(reportTypesSagas);
  yield fork(ticketsStatisticsSeveritySagas);
  yield fork(ticketsStatisticsTypeSagas);
  yield fork(ticketsStatisticsStatusSagas);
  yield fork(compromisedDataCCSagas);
  yield fork(compromisedDataCCReportSagas);
  yield fork(cardsStatisticsStatusSagas);
  yield fork(cardsStatisticsSourceSagas);
  yield fork(cardsStatisticsMatcherSagas);
  yield fork(compromisedDataVictimsInfoSagas);
  yield fork(compromisedDataVictimsInfoReportSagas);
  yield fork(credentialsStatisticsStatusSagas);
  yield fork(credentialsStatisticsSourceSagas);
  yield fork(credentialsStatisticsMatcherSagas);
  yield fork(profileSagas);
  yield fork(newReportOptionsSagas);
  yield fork(investigationsSagas);
  yield fork(threatCloudInvestigationsSagas);
  yield fork(insightsSagas);
  yield fork(insightSagas);
  yield fork(alertsSettingsSagas);
  yield fork(ticketsSettingsSagas);
  yield fork(articlesSettingsSagas);
  yield fork(credentialsSettingsSagas);
  yield fork(cardsSettingsSagas);
  yield fork(insightsCategoriesSagas);
  yield fork(alertsAlertersSagas);
  yield fork(investigationsEntitiesSagas);
  yield fork(mdfeOnboardingSagas);
  yield fork(reportsSagas);
  yield fork(userPreferencesSagas);
  yield fork(passwordResetSagas);
  yield fork(newsSagas);
  yield fork(emergencyResponseRetainerSaga);
  yield fork(retainersSagas);
  yield fork(retainerSagas);
  yield fork(passwordResetEmailSagas);
  yield fork(EmergencyCaseConfirmationSagas);
  yield fork(retainersTypesSagas);
  yield fork(emergencyCasesSaga);
  yield fork(newEmergencyCaseSaga);
  yield fork(businessUnitsSagas);
  yield fork(emergencyCaseSaga);
  yield fork(userGroupsSagas);
  yield fork(rolesSagas);
  yield fork(escalationContactsSagas);
  yield fork(ipRangesSagas);
  yield fork(usersSagas);
  yield fork(auditLogSagas);
  yield fork(apiClientsSagas);
  yield fork(clientRolesSagas);
}
export type RootState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

export default function createStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
  });
  sagaMiddleware.run(rootSaga);
  return store;
}
