import { useSelector } from "react-redux";
import { Link } from "@csis.com/components";
import { LinkInterface } from "@csis.com/components/src/atoms/Link/Link";
import { ORG_ID_PARAM_KEY } from "../../MainApp";
import { getSelectedOrgId } from "../../pages/Profile/Security/selectors";

// This component is a simple "wrapper" of the atom Link
// that adds the organizationId to the query params
// use this to keep the "state" of the organizationId between pages
// (is mainly needed for when opening in a new tab)
export const LinkWithOrgId = (
  props: LinkInterface & {
    children?: React.ReactNode;
  }
) => {
  const selectedOrgId = useSelector(getSelectedOrgId);

  return (
    <Link
      {...props}
      href={`${props.href}${
        props.href.includes("?") ? "&" : "?"
      }${ORG_ID_PARAM_KEY}=${selectedOrgId}`}
    >
      {props.children}
    </Link>
  );
};
