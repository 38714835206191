import * as qs from "query-string";
import { useHistory, useLocation } from "react-router";

// Function that returns a function that updates the query params
// when called with a key and value
// while KEEPING the existing query params unchanged
// for now it is only used to "inject" the organizationId in the query params when the user navigates to a page
export const useUpdateAndReplaceQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = qs.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "index",
  });

  return (key: string, value: string) => {
    // set the new value
    searchParams[key] = value;

    history.replace({
      search: qs.stringify(searchParams, {
        arrayFormat: "index",
      }),
    });
  };
};
