import classNames from "classnames";
import React from "react";
import { GIGANTIC_1, GIGANTIC_2, SIZE } from "../../sizes";

export interface StackInterface {
  isVertical?: boolean;
  isReverse?: boolean;
  isWrap?: boolean;
  children: React.ReactNode;
  gutterSize?: SIZE | GIGANTIC_1 | GIGANTIC_2 | "none";
  align?: "center" | "stretch" | "end"; // flex equivalent of align-items
  justify?: "left" | "right" | "center" | "space-between" | "space-evenly"; // flex equivalent of justify-content
  isExpanded?: boolean;
}

const Stack: React.FC<StackInterface> = ({
  isVertical,
  isWrap,
  isReverse,
  children,
  gutterSize = "normal",
  align,
  justify,
  isExpanded,
}) => {
  const classes = classNames("stack", {
    "stack--horizontal": !isVertical && !isReverse && !isWrap,
    "stack--vertical": isVertical && !isReverse,
    "stack--horizontal-reverse": isReverse && !isVertical,
    "stack--vertical-reverse": isReverse && isVertical,
    "stack--wrap": isWrap,
    "stack--align-center": align === "center",
    "stack--align-stretch": align === "stretch",
    "stack--align-end": align === "end",
    "stack--justify-space-between": justify === "space-between",
    "stack--justify-space-evenly": justify === "space-evenly",
    "stack--justify-right": justify === "right",
    "stack--justify-center": justify === "center",
    "stack--expanded": isExpanded,
  });

  const gutterSizeClasses = ` stack--gutter-${gutterSize}`;

  return <div className={classes + gutterSizeClasses}>{children}</div>;
};

export default Stack;
