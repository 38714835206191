import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Icon,
  List,
  ListItem,
  ListItemLabel,
  ListItemValue,
  Stack,
  Toast,
} from "@csis.com/components";
import Page from "@csis.com/tip/src/components/shared/Page/Page";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import DownloadAttachmentButton from "../../../components/shared/DownloadAttachmentButton/DownloadAttachmentButton";
import { getRetainerType } from "../RetainersSearch/utils";
import EditRetainerAttachmentsDialog from "./EditRetainerAttachmentsDialog/EditRetainerAttachmentsDialog";
import EditRetainerDialog from "./EditRetainerDialog/EditRetainerDialog";
import { retainerKeys } from "./constants";
import { getRetainerResult } from "./selectors";
import { downloadAttachment, fetchRetainerById } from "./slice";
import { getRetainerAttachments } from "./utils";

function useDialog(setIsEditActionCompleted: any) {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActionCompletedSuccessfully = useCallback(() => {
    setOpen(false);
    setIsEditActionCompleted(true);
  }, [setIsEditActionCompleted]);

  return {
    isOpen,
    handleOpen,
    handleClose,
    handleActionCompletedSuccessfully,
  };
}

const RetainerPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const { id } = useParams() as {
    id?: string;
  };
  const { retainer, isRetainerPending } = useSelector(getRetainerResult);

  useEffect(() => {
    if (id) {
      dispatch(fetchRetainerById(id));
    }
  }, [dispatch, id]);

  const [isUpdateActionCompleted, setIsUpdateActionCompleted] =
    useState<boolean>(false);

  const attachments = useMemo(() => {
    return getRetainerAttachments(retainer?.attachments);
  }, [retainer?.attachments]);

  const handleDownloadAttachment = (
    retainerId: string,
    attachmentId: string,
    filename: string
  ) => {
    dispatch(
      downloadAttachment({
        retainerId,
        attachmentId,
        filename,
      })
    );
  };

  const editRetainerDialog = useDialog(setIsUpdateActionCompleted);
  const editRetainerAttachmentsDialog = useDialog(setIsUpdateActionCompleted);
  return (
    <Page>
      <Stack gutterSize="gigantic" isVertical>
        <Stack gutterSize="big" isVertical align="stretch">
          <Stack gutterSize="huge" align="center" justify="space-between">
            <div className="f_secondary f_big f_semibold">
              Company Information
            </div>

            <Button
              icon="edit"
              type="text"
              text="Edit"
              onButtonClick={editRetainerDialog.handleOpen}
              name="edit-retainer-btn"
            />
          </Stack>
          <List>
            <ListItem>
              <ListItemLabel>Company name:</ListItemLabel>
              <ListItemValue isPending={isRetainerPending}>
                {retainer?.business_unit.name}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Retainer Type:</ListItemLabel>
              <ListItemValue isPending={isRetainerPending}>
                {getRetainerType(retainer?.flags)}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Primary Retainer contact name:</ListItemLabel>
              <ListItemValue isPending={isRetainerPending}>
                {retainer?.[retainerKeys.NAME]}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Primary Retainer phone number:</ListItemLabel>
              <ListItemValue isPending={isRetainerPending}>
                {retainer?.[retainerKeys.PHONE]}
              </ListItemValue>
            </ListItem>
          </List>
        </Stack>

        <Stack gutterSize="big" isVertical align="stretch">
          <Stack gutterSize="large" align="center" justify="space-between">
            <Stack align="center">
              <div className="f_secondary f_big f_semibold">
                Related Retainer items:
              </div>
              <Icon
                kind="help_filled"
                color="secondary"
                tooltipText={
                  "Kindly attach your Retainer Onboarding Questionnaire along with any other relevant documentation."
                }
              />
            </Stack>
            <Button
              icon="edit"
              type="text"
              text="Edit"
              onButtonClick={editRetainerAttachmentsDialog.handleOpen}
              name="edit-retainer-attachments-btn"
            />
          </Stack>

          <List>
            <ListItem>
              <ListItemLabel>Attachments:</ListItemLabel>
              <ListItemValue isPending={isRetainerPending}>
                {attachments.length
                  ? attachments.map((attachment) => (
                      <DownloadAttachmentButton
                        key={attachment.id}
                        id={attachment.id}
                        fileName={attachment.filename}
                        fileSize={attachment.size}
                        onClick={() =>
                          handleDownloadAttachment(
                            retainer?.external_id || "",
                            attachment.id,
                            attachment.filename
                          )
                        }
                      />
                    ))
                  : "No files attached"}
              </ListItemValue>
            </ListItem>
          </List>
        </Stack>
      </Stack>
      {editRetainerDialog.isOpen && (
        <EditRetainerDialog
          onDialogClose={editRetainerDialog.handleClose}
          onSuccess={editRetainerDialog.handleActionCompletedSuccessfully}
        />
      )}
      {editRetainerAttachmentsDialog.isOpen && (
        <EditRetainerAttachmentsDialog
          retainerId={retainer?.external_id || ""}
          onDialogClose={editRetainerAttachmentsDialog.handleClose}
          onSuccess={
            editRetainerAttachmentsDialog.handleActionCompletedSuccessfully
          }
        />
      )}
      <Toast
        isShown={isUpdateActionCompleted}
        onHide={() => setIsUpdateActionCompleted(false)}
        icon="check"
        text={t("retainer_information_update_success")}
      />
    </Page>
  );
};

export default RetainerPage;
