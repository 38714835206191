import { FC } from "react";
import { CodeBlock, Stack, Tag } from "@csis.com/components";
import { HttpMethod } from "../Section/types";

export const EndpointSection: FC<{
  children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack isVertical gutterSize="large" align="stretch">
      {children}
    </Stack>
  );
};

export interface EndpointHeaderInterface {
  title?: string;
  titleId?: string;
  headingLvl?: 2 | 3 | 4 | 5 | 6;
  endpoint: string;
  method: HttpMethod;
  isDeprecated?: boolean;
  baseUrl?: string;
}

export const EndpointSectionHeader: FC<EndpointHeaderInterface> = ({
  title,
  titleId,
  headingLvl,
  endpoint,
  method,
  isDeprecated = false,
  baseUrl,
}) => {
  const renderHeader = () => {
    switch (headingLvl) {
      case 2:
        return (
          <h2 id={titleId} className="f_large f_semibold">
            {title}
          </h2>
        );
      case 3:
        return (
          <h3 id={titleId} className="f_large f_semibold">
            {title}
          </h3>
        );
      case 4:
        return (
          <h4 id={titleId} className="f_big f_semibold">
            {title}
          </h4>
        );
      case 5:
        return (
          <h5 id={titleId} className="f_big f_semibold">
            {title}
          </h5>
        );
      case 6:
        return (
          <h6 id={titleId} className="f_big f_semibold">
            {title}
          </h6>
        );
      default:
        return (
          <span id={titleId} className="f_large f_semibold">
            {title}
          </span>
        );
    }
  };

  const selectColor = (method: HttpMethod) => {
    switch (method) {
      case "GET":
        return "green";
      case "POST":
        return "blue";
      case "PATCH":
        return "yellow";
      case "PUT":
        return "orange";
      case "DELETE":
        return "red";
      default:
        return "grey";
    }
  };

  return (
    <Stack isVertical gutterSize="big">
      <Stack>
        {renderHeader()}
        {isDeprecated && (
          <Tag color="red" isCircle>
            Deprecated
          </Tag>
        )}
      </Stack>
      <Stack isVertical gutterSize="big">
        <Stack>
          <div className="f_semibold">Endpoint: </div>
          <CodeBlock
            color="grey"
            text={baseUrl ? `${baseUrl}${endpoint}` : endpoint}
            isCopyable
          />
        </Stack>
        <Stack>
          <div className="f_semibold">Method:</div>
          <Tag color={selectColor(method)} size="small" isCircle>
            {method}
          </Tag>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const EndpointSectionContent: FC<{
  children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack isVertical align="stretch" gutterSize="large">
      {children}
    </Stack>
  );
};
