import React from "react";
import { IconButton, Stack } from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getHumanFilesize } from "@csis.com/tip/src/utils/filesizeCalculations";

const DownloadAttachmentButton = ({
  id,
  fileName,
  fileSize,
  size,
  onClick,
}: {
  id: string;
  fileName: string;
  fileSize: number; // in bytes!
  size?: "small";
  onClick: () => void;
}) => {
  const { t } = useTranslations();

  return (
    <div className="download-attachment-button">
      <Stack align="center">
        <div
          role="button"
          tabIndex={0}
          className={
            size === "small"
              ? "f_small f_underline-onhover cursor_pointer"
              : "f_underline-onhover cursor_pointer"
          }
          onClick={onClick}
        >
          {fileName} {fileSize ? "(" + getHumanFilesize(fileSize) + ")" : null}
        </div>
        <IconButton
          icon="download"
          isCircle
          tooltipText={t("download")}
          color="grey"
          type="text"
          spacing="tiny"
          dataTestId={"download-attachment-" + id}
          onButtonClick={onClick}
        />
      </Stack>
    </div>
  );
};

export default React.memo(DownloadAttachmentButton);
