import { RootState } from "@csis.com/tip/src/configureStore";

export const getIsLoggedIn = (state: RootState) =>
  state.loginReducer.isLoggedIn;

export const getRedirectAuthUrl = (state: RootState) =>
  state.loginReducer.redirectAuthUrl;

export const getRedirectUrl = (state: RootState) =>
  state.loginReducer.redirectUrl;
