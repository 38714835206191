import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Language } from "./types";

export interface StateSlice {
  selectedLanguage: Language;
}

const initialState: StateSlice = {
  selectedLanguage: "en",
};

const translationSlice = createSlice({
  name: "translation",
  initialState: initialState,
  reducers: {
    changeLanguage(state, action: PayloadAction<Language>) {
      state.selectedLanguage = action.payload;
    },
  },
});

export default translationSlice.reducer;
