import * as constants from "./constants";
import { ChartColor } from "./types";

export function getChartColor(index: number, color?: ChartColor): ChartColor {
  const defaultColor =
    constants.DEFAULT_COLORS?.[index % constants.DEFAULT_COLORS.length] ||
    constants.DEFAULT_COLORS[0];

  return color
    ? color
    : // if no color is specified, we return the mod of the array length
      // so we will return a different color per key and if the keys exceed the DEFAULT_COLORS
      // we will just recycle them
      defaultColor;
}
