import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { StatisticsCountListResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { QueryParams, StatusTotalEntry } from "../types";
import { fetchStatusStatisticsApi } from "./api/api";

const cardsStatisticsSlice = createAsyncArrayFetchSlice<
  StatusTotalEntry,
  QueryParams
>("cardsStatisticsStatus");

export default cardsStatisticsSlice.reducer;

export const {
  fetchData: fetchStatusDistribution,
  setIsPending: setIsStatusDistributionPending,
  setFetchError: setStatusDistributionFetchError,
  setFetchSuccess: setFetchStatusDistributionSuccess,
} = cardsStatisticsSlice.actions;

// Async stuff - sagas

function* fetchStatusDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsStatusDistributionPending());

  try {
    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      fetchStatusStatisticsApi,
      action.payload
    );

    yield put(setFetchStatusDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setStatusDistributionFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchStatusDistribution.toString(),
    fetchStatusDistributionSaga
  );
}

export function* cardsStatisticsStatusSagas() {
  yield all([actionWatcher()]);
}
