import { axiosCsisApi } from "@csis.com/tip/src/App";
import { ResendMessageType } from "@csis.com/tip/src/api/openapi/data-contracts";
import { EmergencyCaseUpdateBody } from "./types";

export async function fetchEmergencyCaseByIdAPI(id: string) {
  return axiosCsisApi.getEmergencyResponseCasesByExternalIdApi10AdminEmergencyResponseCaseErCaseExternalIdGet(
    id
  );
}

export async function updateEmergencyCaseAPI(
  id: string,
  EmergencyCase: EmergencyCaseUpdateBody
) {
  return axiosCsisApi.updateExistingEmergencyResponseCaseApi10AdminEmergencyResponseCaseErCaseExternalIdPatch(
    id,
    EmergencyCase
  );
}

export async function fetchAttachmentAPI(
  emergencyCaseId: string,
  attachmentId: string
) {
  return axiosCsisApi.downloadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdGet(
    emergencyCaseId,
    attachmentId,
    {
      format: "blob",
    }
  );
}

export async function deleteEmergencyCaseAttachmentAPI(
  emergencyCaseId: string,
  attachmentId: string
) {
  return axiosCsisApi.deleteErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdDelete(
    emergencyCaseId,
    attachmentId
  );
}

export async function updateEmergencyCaseAttachmentsAPI(
  caseId: string,
  formData: File[]
) {
  return axiosCsisApi.uploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost(
    caseId,
    {
      files: formData,
    }
  );
}

export async function postResendCaseLinkAPI(
  erCaseExternalId: string,
  messageType: ResendMessageType
) {
  return await axiosCsisApi.resendNewCaseMessageApi10AdminEmergencyResponseCaseErCaseExternalIdMessageMessageTypePost(
    erCaseExternalId,
    messageType
  );
}
