import ReactDOM from "react-dom";
import { calculateModalPosition } from "./utils";

export const renderModal = (
  content: React.ReactNode,
  parentRef: React.RefObject<HTMLDivElement>,
  modalRef: React.RefObject<HTMLDivElement>,
  opensFromRight?: boolean,
  isFullWidth?: boolean
) => {
  const position = calculateModalPosition(parentRef);

  return ReactDOM.createPortal(
    <div
      ref={modalRef}
      className="listmenu__modal-container"
      style={{
        top: `${position.top}px`,
        left: opensFromRight
          ? `${position.left + position.width}px`
          : `${position.left}px`,
        width: isFullWidth ? `${position.width}px` : "auto",
      }}
    >
      {content}
    </div>,
    document.body
  );
};
