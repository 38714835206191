import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";
import { mapUrlsStartAndEndDateToCorrectType2 } from "./utils";

export async function fetchUrlsApi(queryParams: Partial<QueryParams>) {
  const params = mapUrlsStartAndEndDateToCorrectType2(queryParams);

  return axiosCsisApi.getUrlsApi20PhishingUrlGet(
    {
      ...params,
    },
    STRINGIFY_QUERY_PARAMS
  );
}

export async function exportUrlsCsvApi(queryParams: Partial<QueryParams>) {
  const params = mapUrlsStartAndEndDateToCorrectType2(queryParams);

  return axiosCsisApi.exportUrlsApi20PhishingUrlExportCsvGet(
    {
      ...params,
    },
    {
      format: "blob",
      ...STRINGIFY_QUERY_PARAMS,
    }
  );
}

export async function bulkPostUrlsApi(url: string[]) {
  return axiosCsisApi.postUrlsApi20PhishingUrlPost(
    { url },
    STRINGIFY_QUERY_PARAMS
  );
}
