import classNames from "classnames";
import React from "react";

export interface ListMenuBodyInterface {
  children: React.ReactNode;
}

const ListMenuBody: React.FC<ListMenuBodyInterface> = ({ children }) => {
  const classes = classNames("listmenu__body", {});
  return <div className={classes}>{children}</div>;
};

export default ListMenuBody;
