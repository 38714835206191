import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { EmergencyCasesColumns } from "./Table/columns";

export const getEmergencyCases = (state: RootState) =>
  state.emergencyCasesReducer.data;

export const getIsEmergencyCasesPending = (state: RootState) =>
  state.emergencyCasesReducer.isDataPending;

export const getEmergencyCasesFetchError = (state: RootState) =>
  state.emergencyCasesReducer.dataFetchError;

export const getEmergencyCasesResult = createSelector(
  [getEmergencyCases, getIsEmergencyCasesPending, getEmergencyCasesFetchError],
  (emergencyCases, isEmergencyCasesPending, emergencyCasesFetchError) => {
    return {
      emergencyCases,
      isEmergencyCasesPending,
      emergencyCasesFetchError,
    };
  }
);

export const getEmergencyCasesColumns = (state: RootState) =>
  state.emergencyCasesAdminReducer.columns;

export const getCalculatedColumns = (
  state: RootState,
  sort?: string
): EmergencyCasesColumns => {
  const columns = getEmergencyCasesColumns(state);
  return getNewColumnsBasedOnSort(columns, sort);
};

export const getHasNextPage = (state: RootState) =>
  state.emergencyCasesReducer.hasNextPage;

export const getAdminUsers = (state: RootState) =>
  state.emergencyCasesAdminReducer.adminUsers;

export const getIsAdminUsersPending = (state: RootState) =>
  state.emergencyCasesAdminReducer.isAdminUsersPending;

export const getFetchAdminUsersError = (state: RootState) =>
  state.emergencyCasesAdminReducer.fetchAdminUsersError;

export const getAdminUsersResult = createSelector(
  [getAdminUsers, getIsAdminUsersPending, getFetchAdminUsersError],
  (responsibleUsers, isResponsibleUsersPending, fetchResponsibleUsersError) => {
    return {
      responsibleUsers,
      isResponsibleUsersPending,
      fetchResponsibleUsersError,
    };
  }
);
