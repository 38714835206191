import { pickTextColorBasedOnBgColor } from "./utils";

export type CommentAvatarInfo = { text: string; color: string };

export function isCommentAvatar(x: any): x is CommentAvatarInfo {
  return x.text !== undefined && x.color !== undefined;
}

export const CommentAvatar = ({ avatar }: { avatar: CommentAvatarInfo }) => {
  return (
    <div
      className="comment__avatar"
      title={avatar.text}
      style={{
        backgroundColor: avatar.color,
        color: pickTextColorBasedOnBgColor(avatar.color),
      }}
    >
      {avatar.text.slice(0, 2)}
    </div>
  );
};
