import { isNullOrUndefined } from "@csis.com/components/src/utils/utils";
import { Localization } from "../../../userPreferences/types";
import {
  getCsisUtcDate,
  getFormattedDateBasedOnPrefs,
} from "../../../utils/dateFormatters";

// Modes
// define the "mode of a DateSpan"
// undefined - default:
// this should be the default date span we use in most places
// it is not made for "limited/small" spaces and it can be whatever the user has chosen in the user preferences

// "short"
// this span should be used in places with limited space
// so it should either be relative time or "short date" like dd/mm/yyyy - never full timestamp

// "extended"
// Use this, when space is not an issue and we want to show as much info as possible like datetime + relative
// for example single product pages

// "table"
// this is the special span we use only for table search pages
// it shows either just date(timestamp) or date(timestamp) + relative

// "timestamp"
// we strictly want timestamp

export type DateSpanMode =
  | "short"
  | "short-no-relative"
  | "extended"
  | "table"
  | "timestamp";

export function DateSpan({
  date,
  mode,
  localization,
}: {
  date?: string | null;
  mode?: DateSpanMode;
  localization: Localization;
}) {
  if (isNullOrUndefined(date)) {
    return <span>-</span>;
  }
  const formattedDate = getFormattedDateBasedOnPrefs(date, localization, mode);

  return <span title={getCsisUtcDate(date)}>{formattedDate}</span>;
}
