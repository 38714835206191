import { BarChartData } from "@csis.com/components/src/charts/BarChart/BarChart";
import { PieChartData } from "@csis.com/components/src/charts/PieChart/PieChart";
import {
  StatisticsCount,
  StatisticsHistogramCount,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

// function that returns a getPieLabel function
// that is used in transformTotalDataToPiechartData
export function getPieLabelGenerator(
  t: (key: TranslationKey) => string,
  translations: { [key: string]: TranslationKey }
) {
  // @ts-expect-error
  return (value: string) => t(translations[value]);
}

// These functions transform statistics raw data from the API
// to fit our chart components

export function transformTotalDataToPiechartData(
  totalData: StatisticsCount[],
  getPieLabel: (value: string) => string
): PieChartData {
  return totalData.map((entry) => {
    return {
      key: entry.name,
      name: getPieLabel(entry.name),
      amount: entry.count,
    };
  });
}

export function transformHistogramDataToBarchartData(
  histogramData: StatisticsHistogramCount[]
): BarChartData {
  return histogramData.map((entry) => {
    const entries = entry.entries.map((e) => {
      return { [e.name]: e.count };
    });

    return Object.assign(
      {
        name: entry.timeframe,
      },
      ...entries
    );
  });
}

// returns the "keys" of the histogram data in an array
// we need this when the keys are dynamic
export function extractKeysFromHistogramData(
  histogramData: StatisticsHistogramCount[]
): string[] {
  const keys: string[] = [];

  histogramData.forEach((entry) => {
    entry.entries.forEach((e) => {
      keys.indexOf(e.name) === -1 && keys.push(e.name);
    });
  });

  return keys;
}
