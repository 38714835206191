import React from "react";

export interface PageInterface {
  classes?: string;
  padding?: "normal" | "none";
  children: React.ReactNode;
}
const Page: React.FC<PageInterface> = ({ padding, classes, children }) => {
  let allClasses = classes ? classes + " page" : "page";
  allClasses += padding === "none" ? " page--no-padding" : "";

  return (
    <div role="main" className={allClasses}>
      {children}
    </div>
  );
};

export default Page;
