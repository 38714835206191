// given a payment card number it formats it with "spaces" between every 4 numbers
// so 1234567890123456 -> 1234 5678 9012 3456
export function formatPaymentCard(value: string) {
  const removedWhitespaceValue = value
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "");

  // for now and for simplicity format only 16 char wide cards (which is most of the cases anyway)
  if (removedWhitespaceValue.length === 16) {
    const matches = removedWhitespaceValue.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || "";
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  }
  return value;
}
