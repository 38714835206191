import classNames from "classnames";
import React from "react";
import { CSIS, GREY } from "../../colors";

export interface AccordionItemBodyInterface {
  isItemOpen?: boolean;
  isTransparent?: boolean; // passed by the parent! do not pass manually
  headerColor?: CSIS | GREY; // passed by the parent! do not pass manually
  children: React.ReactNode;
}

const AccordionItemBody: React.FC<AccordionItemBodyInterface> = ({
  children,
  isItemOpen,

  isTransparent,
}) => {
  const classes = classNames("accordion__item__body", {
    "accordion__item__body--transparent": isTransparent,
  });
  return isItemOpen ? <div className={classes}>{children}</div> : null;
};

export default AccordionItemBody;
