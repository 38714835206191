import { axiosCsisApi } from "@csis.com/tip/src/App";
import { OtpPostData } from "../OtpDialog/types";
import { PasswordData, Profile } from "../types";

export async function fetchProfileApi() {
  return axiosCsisApi.getProfileApi10ProfileGet();
}

export async function patchProfileApi(profile: Partial<Profile>) {
  return axiosCsisApi.postProfileApi10ProfilePatch(profile);
}

export async function fetchOtpInfoApi() {
  return axiosCsisApi.getOtpApi10ProfileOtpGet();
}

export async function changePasswordApi(passwordData: PasswordData) {
  return axiosCsisApi.postPasswordApi10ProfilePasswordPost(passwordData);
}

export async function createTokenApi() {
  return axiosCsisApi.postTokenApi10ProfileTokenPost();
}

export async function disableTokenApi() {
  return axiosCsisApi.deleteTokenApi10ProfileTokenDelete();
}

export async function generateOtpApi(otpPostData: OtpPostData) {
  return axiosCsisApi.postOtpApi10ProfileOtpPost(otpPostData);
}
