import React from "react";
import { getProgressWidth, getValidWidth } from "./utils";

export interface ProgressBarInterface {
  completed: number;
}

const ProgressBar: React.FC<ProgressBarInterface> = ({ completed }) => {
  return (
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow={getValidWidth(completed)}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-label="progress-bar"
    >
      <div
        className="progress-bar__progress"
        style={{ width: getProgressWidth(completed) }}
      />
    </div>
  );
};

export default ProgressBar;
