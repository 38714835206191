// these keys are used in all search pages
export const searchKeys = {
  SORT: "sort",
  PER_PAGE: "limit",
  OFFSET: "offset",
  SEARCH_STRING: "query_text",
  START_DATE: "start_date",
  END_DATE: "end_date",
  SHOW_ALL_TIME: "all_time",
} as const;
