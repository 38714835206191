import classNames from "classnames";
import { useSelector } from "react-redux";
import { Button, IconButton } from "@csis.com/components";
import { getSelectedOnboardingStep } from "../../../components/OnboardingHelp/selectors";

const NewTicketButton = ({ onClick }: { onClick: () => void }) => {
  const selectedOnboardingStep = useSelector(getSelectedOnboardingStep);

  const classes = classNames("new-ticket-dialog__btn", {
    "onboarding-highlight": selectedOnboardingStep === "sidebar-tickets",
  });

  return (
    <>
      <div className={classes}>
        <Button
          text="Create Ticket"
          type="text"
          onButtonClick={onClick}
          icon="create_ticket"
          name="new-ticket-btn"
          dataTestId="new-ticket-btn"
        />
      </div>
      <div className="new-ticket-dialog__btn--mobile">
        <IconButton
          tooltipText="Create Ticket"
          onButtonClick={onClick}
          icon="create_ticket"
          type="text"
          isCircle
          size="large"
          dataTestId="new-ticket-btn-mob"
        />
      </div>
    </>
  );
};

export default NewTicketButton;
