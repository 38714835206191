import * as tableTypes from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { reportsKeys } from "../constants";
import { ReportsKey } from "../types";

export type ReportsColumns = tableTypes.ColumnInterface<
  ReportsKey,
  TranslationKey
>[];

export const columns: ReportsColumns = [
  { key: reportsKeys.DISPLAY_NAME, name: "title", isSortable: true },
  { key: reportsKeys.TIMEFRAME_START, name: "timeframe" },
  { key: reportsKeys.CREATED_AT, name: "created", isSortable: true },
  { key: reportsKeys.TYPE, name: "type", isSortable: true },
  { key: reportsKeys.REPORT_STATUS, name: "status" },
];
