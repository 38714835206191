import { ColumnInterface } from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { emergencyCaseKeys } from "../constants";
import { EmergencyCaseKey } from "../types";

export type EmergencyCasesColumns = ColumnInterface<
  EmergencyCaseKey,
  TranslationKey
>[];

export const columns: EmergencyCasesColumns = [
  {
    key: emergencyCaseKeys.CREATED_AT,
    name: "created",
    isSortable: true,
  },
  {
    key: emergencyCaseKeys.BUSINESS_UNIT,
    name: "business_unit_name",
    isSortable: true,
  },
  {
    key: emergencyCaseKeys.NEW_CUSTOMER_NAME,
    name: "new_customer_name",
  },
  {
    key: emergencyCaseKeys.CONTACT_NAME,
    name: "primary_contact_name",
  },
  {
    key: emergencyCaseKeys.IS_RETAINER_CASE,
    name: "is_retainer_case",
  },
  {
    key: emergencyCaseKeys.EMERGENCY_RESPONSE_CASE_TYPE,
    name: "type",
    isSortable: true,
    isCenterAligned: true,
  },
  {
    key: emergencyCaseKeys.REACTION_TIME,
    name: "reaction_time",
    isCenterAligned: true,
  },
  {
    key: emergencyCaseKeys.RESPONSIBLE,
    name: "assignee",
    isSortable: true,
    isCenterAligned: true,
  },
  {
    key: emergencyCaseKeys.EMERGENCY_RESPONSE_CASE_STATUS,
    name: "status",
    isSortable: true,
    isCenterAligned: true,
  },
];
