import classNames from "classnames";
import React from "react";
import { Technique } from "./types";
import { formatTacticText } from "./utils";

export interface TechniqueInterface {
  technique: Technique;
  isCompact: boolean;
  showLevels: boolean;
  isSelected: boolean;
  getHeatLevelClassNameBasesOnCount: (count?: number) => string;
  onTechniqueCellClick: (technique: Technique) => void;
}

const TechniqueCell = ({
  technique,
  isCompact,
  showLevels,
  isSelected,
  getHeatLevelClassNameBasesOnCount,
  onTechniqueCellClick,
}: TechniqueInterface) => {
  let classes = classNames("mitreattackmatrix__technique", {
    "mitreattackmatrix__technique--selected": isSelected,
  });
  classes += " " + getHeatLevelClassNameBasesOnCount(technique.count);

  return (
    <div
      tabIndex={0}
      title={technique.name}
      className={classes}
      onClick={() => onTechniqueCellClick(technique)}
      onFocus={() => onTechniqueCellClick(technique)}
    >
      {showLevels && (
        <div className="mitreattackmatrix__technique__count">
          {technique.count || 0}
        </div>
      )}
      <div>{formatTacticText(technique.name, isCompact)}</div>
    </div>
  );
};

export default TechniqueCell;
