import React from "react";
import { Stack, Tag } from "@csis.com/components";
import Icon from "../../icons/Icon";

export interface PercentageChangeInterface {
  value: number;
}

const PercentageChange = ({ value }: PercentageChangeInterface) => {
  return (
    <Tag color={value >= 0 ? "green" : "red"}>
      <Stack align="center" justify="space-evenly">
        {value > 0 ? "+" : ""}
        {value}%
        {value !== 0 && (
          <Icon
            color={value > 0 ? "green" : "red"}
            kind={value > 0 ? "up-arrow-with-line" : "down-arrow-with-line"}
            size="big"
          />
        )}
      </Stack>
    </Tag>
  );
};

export default React.memo(PercentageChange);
