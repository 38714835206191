/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.min.css";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Stack } from "@csis.com/components";
import Button from "@csis.com/components/src/atoms/Button/Button";
import { DateRange } from "../types";
import * as utils from "../utils";
import {
  getEndDateAsDayAfter,
  getEndDateAsDayBefore,
  getStartDate,
} from "./utils";

export interface CalendarViewInterface {
  range?: DateRange;
  date?: Date;
  canSelectRange?: boolean;
  onDateSelected: (value: Date | DateRange) => void;
  dataTestId?: string;
  maxDate?: Date | null;
  formatDate?: (date?: Date | null) => string;
}

export default function CalendarView({
  range,
  date,
  canSelectRange,
  onDateSelected,
  dataTestId,
  maxDate,
  formatDate,
}: CalendarViewInterface) {
  const [startDate, setStartDate] = useState<Date>(
    getStartDate(date, range?.[0], canSelectRange)
  );

  const [endDate, setEndDate] = useState<Date | null>(
    range?.[1] && utils.isValidDate(range?.[1])
      ? getEndDateAsDayBefore(range?.[1])
      : null
  );

  const onDateRangeChange = (date: Date | DateRange) => {
    if (canSelectRange) {
      if (Array.isArray(date)) {
        const [start, end] = date;
        setStartDate(start);
        setEndDate(end || null);
      } else {
        if (utils.isValidDate(date)) {
          setStartDate(date);
          onDateSelected(date);
        }
      }
    }
  };

  const handleApplyDateRange = () => {
    if (startDate && endDate && endDate instanceof Date) {
      const endDateAsTomorrowMidnight = getEndDateAsDayAfter(endDate);
      onDateSelected([startDate, endDateAsTomorrowMidnight]);
    }
  };

  const handleFormatDate = (date: Date) => {
    if (formatDate) {
      return formatDate(date);
    } else {
      return date.toLocaleDateString("en-GB");
    }
  };

  const classes = classNames(
    "datepicker__calendarview datepicker__calendarview--csis",
    {
      "datepicker__calendarview--with-range": canSelectRange,
    }
  );

  return (
    <div className={classes} data-test-id={dataTestId}>
      <Stack isVertical align="center">
        <Stack align="center" gutterSize="normal">
          {/* @ts-ignore */}
          <DatePicker
            selected={startDate}
            inline
            onChange={onDateRangeChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            selectsRange={canSelectRange}
            showYearDropdown
            maxDate={maxDate}
            yearDropdownItemNumber={10}
            scrollableYearDropdown
            dateFormatCalendar="MMMM"
          />
        </Stack>

        {canSelectRange && (
          <Stack align="center" gutterSize="big">
            <div className="f_csis f_semibold">
              {startDate ? handleFormatDate(startDate) : "Start Date"}
            </div>
            <div>-</div>
            <div className="f_csis f_semibold">
              {endDate ? (
                handleFormatDate(endDate)
              ) : (
                <>
                  {startDate < new Date() ? (
                    <Button
                      text="Select today"
                      size="small"
                      onButtonClick={() => {
                        setEndDate(new Date());
                      }}
                      name="select today button"
                    />
                  ) : (
                    "End Date"
                  )}
                </>
              )}
            </div>

            <Button
              name="calendarview-apply-btn"
              size="small"
              text="Apply"
              isDisabled={
                !Boolean(startDate && endDate && endDate instanceof Date)
              }
              onButtonClick={handleApplyDateRange}
              dataTestId={"calendarview-btn-apply"}
            />
          </Stack>
        )}
      </Stack>
    </div>
  );
}
