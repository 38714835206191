/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, GridItem, Stack, Toast } from "@csis.com/components";
import { DateRange } from "@csis.com/components/src/atoms/DatePicker/types";
import Page from "@csis.com/tip/src/components/shared/Page/Page";
import {
  getSelectedOrgId,
  getUserSubscribedProducts,
  getUserSubscribedProductsNew,
} from "@csis.com/tip/src/pages/Profile/Security/selectors";
import { NewTicketDialog } from "@csis.com/tip/src/pages/Tickets/NewTicketDialog/NewTicketDialog";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import {
  getIsoDateString,
  getOneMonthAgoAsDateIsoString,
  getStartAndEndDate,
  getTomorrowMidnightAsDateIsoString,
} from "@csis.com/tip/src/utils/dateCalculations";
import ArticlesOverviewCard from "./Overview/ArticlesOverviewCard/ArticlesOverviewCard";
import TicketsOverviewCard from "./Overview/TicketsOverviewCard/TicketsOverviewCard";
import OverviewTopBar from "./Overview/TopBar/TopBar";
import AlertsProductCard from "./ProductCards/AlertsProductCard";
import CardsProductCard from "./ProductCards/CardsProductCard";
import CredentialsProductCard from "./ProductCards/CredentialsProductCard";
import PhishingProductCard from "./ProductCards/PhishingProductCard";
import TicketsProductCard from "./ProductCards/TicketsProductCard";

function LandingPage() {
  const { t } = useTranslations();

  const [overviewStartDate, setOverviewStartDate] = useState<
    string | undefined
  >(getOneMonthAgoAsDateIsoString());
  const [overviewEndDate, setOverviewEndDate] = useState<string | undefined>(
    getTomorrowMidnightAsDateIsoString()
  );

  const userSubscribedProducts = useSelector(getUserSubscribedProducts);
  const userSubscribedProductsNew = useSelector(getUserSubscribedProductsNew);

  const handleOverviewDateRangeSelected = (
    value: Date | DateRange | undefined
  ) => {
    const [startDate, endDate] = getStartAndEndDate(value);
    setOverviewStartDate(startDate ? getIsoDateString(startDate) : undefined);
    setOverviewEndDate(endDate ? getIsoDateString(endDate) : undefined);
  };

  const [isNewTicketDialogShown, setNewTicketDialogShown] =
    useState<boolean>(false);

  const [isNewTicketCreatedSuccessfully, setIsNewTicketCreatedSuccessfully] =
    useState<boolean>(false);

  const handleCreateTicketSuccess = useCallback(() => {
    setNewTicketDialogShown(false);
    setIsNewTicketCreatedSuccessfully(true);
  }, []);

  const selectedOrgId = useSelector(getSelectedOrgId);

  return (
    <Page classes="landing-page">
      <Stack isVertical align="stretch" gutterSize="huge">
        <OverviewTopBar
          startDate={overviewStartDate}
          endDate={overviewEndDate}
          onDateRangeSelected={handleOverviewDateRangeSelected}
          setNewTicketDialogShown={setNewTicketDialogShown}
        />
        <Grid gutterSize="huge">
          <GridItem
            span={userSubscribedProductsNew?.threat_insights ? 6 : 12}
            responsiveSize="large"
          >
            <TicketsOverviewCard
              startDate={overviewStartDate}
              endDate={overviewEndDate}
            />
          </GridItem>
          {userSubscribedProductsNew?.threat_insights && (
            <GridItem span={6} responsiveSize="large">
              {userSubscribedProductsNew?.threat_insights && (
                <ArticlesOverviewCard
                  startDate={overviewStartDate}
                  endDate={overviewEndDate}
                />
              )}
            </GridItem>
          )}
        </Grid>
        <Grid gutterSize="huge">
          <GridItem span={6} responsiveSize="large">
            <TicketsProductCard
              startDate={overviewStartDate}
              endDate={overviewEndDate}
            />
          </GridItem>
          <GridItem span={6} responsiveSize="large">
            <AlertsProductCard
              startDate={overviewStartDate}
              endDate={overviewEndDate}
            />
          </GridItem>
        </Grid>

        {(userSubscribedProductsNew?.payment_cards ||
          userSubscribedProductsNew?.credentials) && (
          <Grid gutterSize="huge">
            {userSubscribedProductsNew?.payment_cards && (
              <GridItem
                span={userSubscribedProductsNew?.credentials ? 6 : 12}
                responsiveSize="large"
              >
                <CardsProductCard
                  startDate={overviewStartDate}
                  endDate={overviewEndDate}
                />
              </GridItem>
            )}
            {userSubscribedProductsNew?.credentials && (
              <GridItem
                span={userSubscribedProductsNew?.payment_cards ? 6 : 12}
                responsiveSize="large"
              >
                <CredentialsProductCard
                  startDate={overviewStartDate}
                  endDate={overviewEndDate}
                />
              </GridItem>
            )}
          </Grid>
        )}
        <Grid gutterSize="huge">
          {userSubscribedProductsNew?.phishing && (
            <GridItem span={12} responsiveSize="large">
              <PhishingProductCard
                startDate={overviewStartDate}
                endDate={overviewEndDate}
              />
            </GridItem>
          )}
        </Grid>
      </Stack>
      {isNewTicketDialogShown && (
        <NewTicketDialog
          setNewTicketDialogShown={setNewTicketDialogShown}
          onSuccess={handleCreateTicketSuccess}
        />
      )}
      <Toast
        isShown={isNewTicketCreatedSuccessfully}
        onHide={() => setIsNewTicketCreatedSuccessfully(false)}
        icon="check"
        text={t("ticket_successfully_created")}
      />
    </Page>
  );
}

export default LandingPage;
