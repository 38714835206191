import { IconKind } from "@csis.com/components/src/icons/Icon";
import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const StatusValues = {
  NEW: "new",
  OPEN: "open",
  RESOLVED: "resolved",
} as const;

export type Status = typeof StatusValues[keyof typeof StatusValues];

export const isStatus = (x: any): x is Status =>
  Object.values(StatusValues).includes(x);

export const isArrayOfStatuses: Guard<Status[]> = isArrayOf(isStatus);

// this is the part we localize
export const StatusTranslationKeys: { [key in Status]: TranslationKey } = {
  [StatusValues.NEW]: "new",
  [StatusValues.OPEN]: "open",
  [StatusValues.RESOLVED]: "resolved",
} as const;

export const StatusIcons: { [key in Status]: IconKind } = {
  [StatusValues.NEW]: "new",
  [StatusValues.OPEN]: "in_progress",
  [StatusValues.RESOLVED]: "closed",
} as const;
