import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Carousel, Table } from "@csis.com/components";
import {
  OnRowClick,
  TableCellFormatter,
} from "@csis.com/components/src/atoms/Table/types";
import { truncateText } from "@csis.com/components/src/utils/utils";
import { isString } from "@csis.com/tip/src/models/helpers";
import { AlertsColumns } from "@csis.com/tip/src/pages/Alerts/AlertsSearch/Table/columns";
import {
  severityCell,
  statusCell,
} from "@csis.com/tip/src/pages/Alerts/AlertsSearch/Table/tableCellFormatters";
import { alertsKeys } from "@csis.com/tip/src/pages/Alerts/AlertsSearch/constants";
import { getAlertsResult } from "@csis.com/tip/src/pages/Alerts/AlertsSearch/selectors";
import { fetchAlerts } from "@csis.com/tip/src/pages/Alerts/AlertsSearch/slice";
import {
  AlertRow,
  QueryParams,
} from "@csis.com/tip/src/pages/Alerts/AlertsSearch/types";
import { SeverityBarChart } from "@csis.com/tip/src/pages/Alerts/Statistics/Severity/SeverityBarChart";
import { SeverityLineChart } from "@csis.com/tip/src/pages/Alerts/Statistics/Severity/SeverityLineChart";
import { SeverityPieChart } from "@csis.com/tip/src/pages/Alerts/Statistics/Severity/SeverityPieChart";
import { getSeveritiesDistributionResult } from "@csis.com/tip/src/pages/Alerts/Statistics/Severity/selectors";
import {
  fetchSeveritiesDistribution,
  fetchSeveritiesDistributionOverTime,
} from "@csis.com/tip/src/pages/Alerts/Statistics/Severity/slice";
import { getStatusDistributionResult } from "@csis.com/tip/src/pages/Alerts/Statistics/Status/selectors";
import { fetchStatusDistribution } from "@csis.com/tip/src/pages/Alerts/Statistics/Status/slice";
import { SeveritiesTotalEntry } from "@csis.com/tip/src/pages/Alerts/Statistics/types";
import { StatusValues } from "@csis.com/tip/src/pages/Alerts/models/Status";
import urlTemplates from "@csis.com/tip/src/routes/urlTemplates";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import AlertsOverview from "./AlertsOverview";
import ProductCard from "./ProductCard/ProductCard";
import { ELEMENTS_PER_TABLE, REQUEST_DELAY_MS } from "./constants";

const columns: AlertsColumns = [
  { key: alertsKeys.TITLE, name: "title" },
  { key: alertsKeys.SEVERITY, name: "severity" },
  { key: alertsKeys.STATUS, name: "status", isCenterAligned: true },
];

const slidesInfo = [
  { subtitle: "Total Alerts", viewAllUrl: urls.alerts_search },
  { subtitle: "Severities Distributions", viewAllUrl: urls.alerts_statistics },
  { subtitle: "Severities Over Time", viewAllUrl: urls.alerts_statistics },
  { subtitle: "Severities Trends", viewAllUrl: urls.alerts_statistics },
  { subtitle: "Latest Alerts", viewAllUrl: urls.alerts_search },
];

export default function AlertsProductCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedOrgId = useSelector(getSelectedOrgId);

  const [requestDelay, setRequestDelay] = useState<number>(REQUEST_DELAY_MS);
  const { t } = useTranslations();

  useEffect(() => {
    const queryParams: Partial<QueryParams> = {
      [alertsKeys.START_DATE]: startDate,
      [alertsKeys.END_DATE]: endDate,
    };

    // during the first render, we want to fetch the data that is immediately visible and delay the rest
    dispatch(fetchSeveritiesDistribution(queryParams));
    dispatch(fetchStatusDistribution(queryParams));
    const timer = setTimeout(() => {
      dispatch(fetchSeveritiesDistributionOverTime(queryParams));
      dispatch(fetchAlerts(queryParams));
      setRequestDelay(0);
    }, requestDelay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate, selectedOrgId]);

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  function handleCarouselChange(newSlideIndex: number) {
    setCurrentSlideIndex(newSlideIndex);
  }

  const { alerts, isAlertsPending, alertsFetchError } =
    useSelector(getAlertsResult);

  const handleRowClick: OnRowClick<AlertRow> = useCallback(
    (row) => {
      const alertId = row[alertsKeys.ALERT_ID];
      if (isString(alertId)) {
        history.push(urlTemplates._alerts_alert_id(alertId));
      }
    },
    [history]
  );

  const formatters = useMemo(
    () => ({
      [alertsKeys.TITLE]: titleCell(),
      [alertsKeys.SEVERITY]: severityCell(t),
      [alertsKeys.STATUS]: statusCell(t),
    }),
    [t]
  );

  function titleCell(): TableCellFormatter<string> {
    return (row, column) => {
      const title = row[column];
      if (isString(title)) {
        const truncatedTitle = truncateText({
          text: title,
          maxChars: 40,
          truncateAtTheEnd: true,
        });
        return <span title={title}>{truncatedTitle}</span>;
      }
      return <>{title}</>;
    };
  }

  const { statusDistributions } = useSelector(getStatusDistributionResult);

  const { severitiesDistributions, isSeveritiesDistributionPending } =
    useSelector(getSeveritiesDistributionResult);

  const totalAlertsNew =
    statusDistributions?.find((element) => element.name === StatusValues.NEW)
      ?.count || 0;

  const alertsInProgressUrl = getNewLocationUrl(urls.alerts_search, {
    [alertsKeys.STATUS]: [StatusValues.NEW],
    [alertsKeys.START_DATE]: startDate,
    [alertsKeys.END_DATE]: endDate,
  });

  const handleRowClickAlertSeverity: OnRowClick<SeveritiesTotalEntry> =
    useCallback(
      (row) => {
        const severity = row.name;

        const alertsSeveritySelectedUrl = getNewLocationUrl(
          urls.alerts_search,
          {
            [alertsKeys.SEVERITY]: [severity],
            [alertsKeys.START_DATE]: startDate,
            [alertsKeys.END_DATE]: endDate,
          }
        );

        history.push(alertsSeveritySelectedUrl);
      },
      [endDate, history, startDate]
    );

  const alertsTotalUrl = getNewLocationUrl(urls.alerts_search, {
    [alertsKeys.START_DATE]: startDate,
    [alertsKeys.END_DATE]: endDate,
  });

  let totalAlerts = 0;
  statusDistributions.map((element) => (totalAlerts += element.count));

  return (
    <ProductCard dataTestId="alerts-product-card">
      <ProductCard.Header
        title={t("alerts")}
        subTitle={slidesInfo[currentSlideIndex]?.subtitle}
        viewAllUrl={slidesInfo[currentSlideIndex]?.viewAllUrl}
      />

      <ProductCard.Carousel>
        <Carousel
          onChange={handleCarouselChange}
          name="alerts-carousel"
          hasAutoplay
          isLoading={isSeveritiesDistributionPending}
          items={[
            <AlertsOverview
              totalAlerts={totalAlerts}
              alertsTotalUrl={alertsTotalUrl}
              severitiesDistributions={severitiesDistributions}
              isSeveritiesDistributionPending={isSeveritiesDistributionPending}
              handleRowClick={handleRowClickAlertSeverity}
            />,
            <SeverityPieChart startDate={startDate} endDate={endDate} />,
            <SeverityBarChart />,
            <SeverityLineChart />,
            <Table
              name={"alerts-card-table"}
              columns={columns}
              rows={alerts?.slice(0, ELEMENTS_PER_TABLE)}
              isLoading={isAlertsPending}
              error={alertsFetchError}
              ghostRows={ELEMENTS_PER_TABLE}
              columnNameTransform={t}
              isFullWidth
              onRowClick={handleRowClick}
              size="small"
              formatters={formatters}
              dataTestId="alerts-card-table"
            />,
          ]}
        />
      </ProductCard.Carousel>
      <ProductCard.Footer
        text={`${totalAlertsNew} ${t("new_alerts")}`}
        url={alertsInProgressUrl}
      />
    </ProductCard>
  );
}
