import { MenuItems } from "@csis.com/tip/src/components/shared/Sidebar/types";
import urls from "../routes/urls";

const sidebarConfig: MenuItems = {
  products: {
    icon: "dashboard",
    onboardingId: "sidebar-dashboard",
    label: "dashboard",
    url: urls.products,
    children: {
      threat_insights: {
        permissionProductKey: "threat_insights",
        label: "threat_insights",
        url: urls.products_threatinsights,
      },
      phishing: {
        label: "anti-phishing",
        tipProductKey: "phishing",
        permissionProductKey: "phishing",
        url: urls.products_phishing_urls,
        children: {
          search: {
            label: "search",
            url: urls.products_phishing_urls,
          },
          phishing_statistics: {
            label: "statistics",
            url: urls.products_phishing_statistics,
          },
          phishing_usage: {
            label: "service_usage",
            url: urls.products_phishing_usage,
          },
        },
      },
      payment_cards: {
        label: "compromised_cards",
        permissionProductKey: "payment_cards",
        url: urls.products_compromiseddata_cc_search,
        children: {
          search: {
            label: "search",
            url: urls.products_compromiseddata_cc_search,
          },
          statistics: {
            label: "statistics",
            url: urls.products_compromiseddata_cc_statistics,
          },
        },
      },
      credentials: {
        label: "compromised_credentials",
        permissionProductKey: "credentials",
        url: urls.products_compromiseddata_victims_search,
        children: {
          search: {
            label: "search",
            url: urls.products_compromiseddata_victims_search,
          },
          statistics: {
            label: "statistics",
            url: urls.products_compromiseddata_victims_statistics,
          },
        },
      },
      investigations: {
        label: "investigations",
        url: urls.products_investigations_search,
        tipProductKey: "crimeware",
        permissionProductKey: "investigations",
        children: {
          search: {
            label: "search",
            url: urls.products_investigations_search,
          },

          threat_cloud: {
            label: "threat_cloud",
            url: urls.products_investigations_threatcloud,
          },
        },
      },
      emergency_response: {
        tipProductKey: "retainers",
        label: "emergency_response",
        permissionProductKey: "retainers",
        url: urls.products_emergencyresponse_retainer,
        children: {
          retainer: {
            label: "retainer_information",
            url: urls.products_emergencyresponse_retainer,
          },
        },
      },
    },
  },
  tickets: {
    icon: "tickets",
    onboardingId: "sidebar-tickets",
    label: "tickets",
    url: urls.tickets_search,
    children: {
      search: {
        label: "search",
        url: urls.tickets_search,
      },
      statistics: {
        label: "statistics",
        url: urls.tickets_statistics,
      },
    },
  },
  alerts: {
    icon: "alert_circle",
    onboardingId: "sidebar-alerts",
    label: "alerts",
    url: urls.alerts_search,
    children: {
      search: {
        label: "search",
        url: urls.alerts_search,
      },
      statistics: {
        label: "statistics",
        url: urls.alerts_statistics,
      },
    },
  },
  reports: {
    onboardingId: "sidebar-reports",
    permissionProductKey: "reports",
    url: urls.reports,
    icon: "reports",
    label: "reports",
  },
  admin: {
    url: urls.admin_emergency_cases_search,
    icon: "malware",
    label: "er_admin",
    isAdminOnlyVisible: true,
    children: {
      emergency_cases: {
        url: urls.admin_emergency_cases_search,
        label: "emergency_cases",
      },
      retainers: {
        url: urls.admin_retainers_search,
        label: "retainers",
      },
    },
  },

  help_and_support: {
    url: urls.helpandsupport_apidocumentation,
    icon: "help_filled",
    label: "help_and_support",
    children: {
      //help_center: {
      //  label: "help_center",
      //  url: urls.helpandsupport_helpcenter,
      //},
      // portal_manual: {
      //   label: "portal_manual",
      //   url: urls.helpandsupport_portalmanual,
      // },
      api_documentation: {
        label: "api_documentation",
        url: urls.helpandsupport_apidocumentation,
      },
      //Mdr: {
      //  label: "mdr",
      //  url: urls.helpandsupport_mdr,
      //},
    },
  },
  settings: {
    url: urls.organization_customerinfo,
    icon: "organization",
    isAdminOnlyVisible: true,
    label: "organization",
    children: {
      customers: {
        label: "customers",
        url: urls.organization_customers_search,
      },
      customer: {
        label: "customer",
        url: urls.organization_customerinfo,
      },
    },
  },
};

export default sidebarConfig;
