import React, { ErrorInfo, ReactNode } from "react";
import ErrorInfoPage from "@csis.com/tip/src/components/shared/ErrorInfo/ErrorInfo";
import Page from "./components/shared/Page/Page";

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

// This is the only class component of the codebase
// since react still hasnt tranlated it to functional/hooks
class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Page classes="error-generic-page">
          <ErrorInfoPage
            error="Something went wrong"
            technicalDetails={
              this.state.error
                ? this.state.error.toString() +
                  " " +
                  this.state.errorInfo.componentStack
                : undefined
            }
            retryAction={() => {
              window.location.reload();
            }}
          />
        </Page>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default AppErrorBoundary;
