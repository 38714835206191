// a helper function that receives a Blob
// and opens a new tab with that blob

export function openBlobInNewTab(blob: Blob, filetype?: "pdf") {
  let file: Blob;
  if (filetype === "pdf") {
    file = new Blob([blob], { type: "application/pdf" });
  } else {
    file = new Blob([blob]);
  }

  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}
