import { axiosCsisApi } from "@csis.com/tip/src/App";
import { QueryParams } from "../../types";
import { addGroupByParamBasedOnRange } from "./utils";

export async function fetchMatcherStatisticsHistogramApi(
  queryParams: Partial<QueryParams>
) {
  const params = addGroupByParamBasedOnRange(queryParams);

  return axiosCsisApi.getVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGet(
    {
      ...params,
    }
  );
}
