import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getStatusDistribution = (state: RootState) =>
  state.alertsStatisticsStatusReducer.data;

export const getIsStatusDistributionPending = (state: RootState) =>
  state.alertsStatisticsStatusReducer.isDataPending;

export const getStatusDistributionFetchError = (state: RootState) =>
  state.alertsStatisticsStatusReducer.dataFetchError;

export const getStatusDistributionResult = createSelector(
  [
    getStatusDistribution,
    getIsStatusDistributionPending,
    getStatusDistributionFetchError,
  ],
  (
    statusDistributions,
    isStatusDistributionPending,
    statusDistributionError
  ) => {
    return {
      statusDistributions,
      isStatusDistributionPending,
      statusDistributionError,
    };
  }
);

export const getStatusDistributionOverTime = (state: RootState) =>
  state.alertsStatisticsStatusOverTimeReducer.data;

export const getIsStatusDistributionOverTimePending = (state: RootState) =>
  state.alertsStatisticsStatusOverTimeReducer.isDataPending;

export const getStatusDistributionOverTimeFetchError = (state: RootState) =>
  state.alertsStatisticsStatusOverTimeReducer.dataFetchError;

export const getStatusDistributionOverTimeResult = createSelector(
  [
    getStatusDistributionOverTime,
    getIsStatusDistributionOverTimePending,
    getStatusDistributionOverTimeFetchError,
  ],
  (
    statusDistributionsOverTime,
    isStatusDistributionOverTimePending,
    statusDistributionsOverTimeError
  ) => {
    return {
      statusDistributionsOverTime,
      isStatusDistributionOverTimePending,
      statusDistributionsOverTimeError,
    };
  }
);
