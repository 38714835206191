import { useSelector } from "react-redux";
import danish from "./da.json";
import english from "./en.json";
import { getSelectedLanguage } from "./selectors";
import { Language, TranslationKey } from "./types";

const translations = {
  en: english,
  da: danish,
} as const;

// this function is "private" and used only by useTranslations which is what should be used
// Its best practice to not access this function directly
// thats why its not exported
const getTranslatedString = (key: TranslationKey, language: Language) => {
  // get the translation by language
  const translation: Partial<Record<TranslationKey, string>> =
    translations[language];

  // return the string based on key or default to english
  return translation[key] || english[key];
};

// this accesses the redux store, use it in TIP
// it simplifies the translating of strings and leaves the
// part of language selection to be handled here
// use:
//    const {t} = useTranslations()
//    t('translation_key')
export const useTranslations = () => {
  const currentLanguage: Language = useSelector(getSelectedLanguage);

  const t = (key: TranslationKey, args?: string[]) => {
    const translatedString = getTranslatedString(key, currentLanguage);

    // If there are args, iterate throught them and replace the "placeholders" in the translated strings
    // `Hello {0}` , args=[world] -> `Hello world`
    if (args) {
      return translatedString.replace(/\{(.+?)\}/g, function (match) {
        if (args[0] && match === "{0}") {
          return args[0].toString();
        }
        if (args[1] && match === "{1}") {
          return args[1].toString();
        }
        if (args[2] && match === "{2}") {
          return args[2].toString();
        }
        return match;
      });
    }
    return translatedString;
  };
  return { t };
};
