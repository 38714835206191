const RADIUS1 = 100;
const RADIUS2 = 75;

const textSizes = {
  // big to small
  // textSize to radius breakpoint map
  shortText: {
    radius1: "25px",
    radius2: "19px",
    radius3: "16px",
  },
  normalText: {
    radius1: "22px",
    radius2: "16px",
    radius3: "13px",
  },
  longText: {
    radius1: "17px",
    radius2: "12px",
    radius3: "10px",
  },
};

// depending on the length of the "text" and the radius size of the chart
// return appropriate fontSize
export function getPieTextSize(name: string, radius: number) {
  if (name.length < 5) {
    if (radius > RADIUS1) {
      return textSizes.shortText.radius1;
    }
    if (radius > RADIUS2) {
      return textSizes.shortText.radius2;
    }

    return textSizes.shortText.radius3;
  } else if (name.length < 10) {
    if (radius > RADIUS1) {
      return textSizes.normalText.radius1;
    }
    if (radius > RADIUS2) {
      return textSizes.normalText.radius2;
    }
    return textSizes.normalText.radius3;
  } else {
    if (radius > RADIUS1) {
      return textSizes.longText.radius1;
    }
    if (radius > RADIUS2) {
      return textSizes.longText.radius2;
    }

    return textSizes.longText.radius3;
  }
}
