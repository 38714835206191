import React from "react";

const FormLabel: React.FC<{ htmlFor: string; text: string }> = ({
  htmlFor,
  text,
}) => {
  return (
    <label className="f_small f_semibold" htmlFor={htmlFor}>
      {text}
    </label>
  );
};

export default FormLabel;
