import { axiosCsisApi } from "@csis.com/tip/src/App";

export async function fetchCsrfTokenApi() {
  return axiosCsisApi.csrfTokenRouteApi10CsrfTokenGet();
}

export async function resetPasswordPostApi(
  {
    newPassword,
    resetPasswordToken = "",
  }: {
    newPassword: string;
    resetPasswordToken?: string;
  },
  csrfToken: string
) {
  return axiosCsisApi.setPasswordApi10ResetPasswordPost(
    {
      reset_token: resetPasswordToken,
      new_password: newPassword,
    },
    { headers: { "X-CSRF-Token": csrfToken } }
  );
}
