import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const retainersKeys = {
  ID: "id",
  RETAINER_ID: "retainer_id",
  NAME: "name",
  FULL_NAME: "full_name",
  RETAINER: "emergency_response_retainer",
  TYPE: "retainer_type",
  UPDATED_AT: "updated_at",
  PRIMARY_CONTACT_NAME: "primary_contact_name",
  PRIMARY_PHONE_NUMBER: "primary_phone_number",
  ATTACHMENTS: "attachments",
  BUSINESS_UNIT: "business_unit",
  BUSINESS_UNIT_ID: "business_unit_id",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [
  retainersKeys.BUSINESS_UNIT,
  retainersKeys.TYPE,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;

export enum RetainerType {
  IT = "IT",
  OT = "OT",
}
