import {
  HighlightSubString,
  Icon,
  SearchableCell,
  Stack,
  Tag,
} from "@csis.com/components";
import { checkIfTextContainsSubstring } from "@csis.com/components/src/atoms/HighlightSubString/utils/utils";
import { TableCellFormatter } from "@csis.com/components/src/atoms/Table/types";
import { isString } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { alertKeys } from "../../Alert/constants";
import { AlerterEntry } from "../../models/Alerter/Alerter";
import { getAlerterDisplayName } from "../../models/Alerter/utils";
import {
  SeverityColors,
  SeverityTranslationKeys,
  isSeverity,
} from "../../models/Severity";
import {
  StatusIcons,
  StatusTranslationKeys,
  isStatus,
} from "../../models/Status";
import { AlertsKey, FilterKey } from "../types";

export function titleCell(
  searchString?: string
): TableCellFormatter<AlertsKey> {
  return (row, column) => {
    const cell = row[column];

    if (
      isString(cell) &&
      searchString &&
      checkIfTextContainsSubstring(cell, searchString)
    ) {
      return (
        <span>
          <HighlightSubString text={cell} searchString={searchString} />
        </span>
      );
    }

    return (
      <Stack align="center">
        <div>{isString(cell) ? cell : "-"}</div>
      </Stack>
    );
  };
}

export function severityCell(
  t: (key: TranslationKey, args?: string[]) => string
): TableCellFormatter<AlertsKey> {
  return (row, column) => {
    const severityValue = row[column];
    if (isSeverity(severityValue)) {
      return (
        <Tag size="small" color={SeverityColors[severityValue]} isUppercase>
          {t(SeverityTranslationKeys[severityValue])}
        </Tag>
      );
    }
    return <>{severityValue}</>;
  };
}

export function statusCell(
  t: (key: TranslationKey, args?: string[]) => string
): TableCellFormatter<AlertsKey> {
  return (row, column) => {
    const statusValue = row[column];
    if (isStatus(statusValue)) {
      return (
        <Icon
          kind={StatusIcons[statusValue]}
          color="secondary"
          size="large"
          tooltipText={t(StatusTranslationKeys[statusValue])}
          isInline
        />
      );
    }
    return <>{statusValue}</>;
  };
}

export function alerterCell(
  alerters: AlerterEntry[]
): TableCellFormatter<AlertsKey> {
  return (row, column) => {
    const alerter = row[column];
    if (isString(alerter)) {
      return getAlerterDisplayName(alerter, alerters);
    }
    return <>{alerter}</>;
  };
}

export function alerterCellWithHandler(
  alerters: AlerterEntry[],
  handleClick: (newValues: unknown, name: FilterKey) => void
): TableCellFormatter<AlertsKey> {
  return (row, column) => {
    const alerter = row[column];

    if (isString(alerter)) {
      return (
        <SearchableCell
          onClick={() => {
            handleClick([alerter], alertKeys.ALERTER);
          }}
        >
          {getAlerterDisplayName(alerter, alerters)}
        </SearchableCell>
      );
    }
    return <>{alerter}</>;
  };
}

// if there is a search string, highlight it in the id row
export function idCell(searchString?: string): TableCellFormatter<AlertsKey> {
  return (row, column) => {
    const id = row[column];

    if (
      isString(id) &&
      searchString &&
      checkIfTextContainsSubstring(id, searchString)
    ) {
      return <HighlightSubString text={id} searchString={searchString} />;
    }

    return <>{id}</>;
  };
}
