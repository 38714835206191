export const setCookie = (name: string, value: string) =>
  (document.cookie = `${name}=${value}; Secure; SameSite=Lax; Path=/;`);

export const setCookieWithExpiration = (
  name: string,
  value: string,
  expiresAfterSeconds: number
) =>
  (document.cookie = `${name}=${value}; Secure; SameSite=Lax; Path=/; max-age=${expiresAfterSeconds}`);

// a bit unreadable but gets the job of reading a cookie by name
//https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript
export const getCookieValue = (name: string) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const deleteCookie = (name: string) =>
  (document.cookie =
    name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
