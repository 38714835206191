import classNames from "classnames";
import React from "react";
import { IconKind } from "../../icons/Icon";
import { CSIS, GREY, SECONDARY } from "../../colors";
import { BIG, HUGE, LARGE, NORMAL, SMALL } from "../../sizes";
import { DropdownOption } from "../Dropdown/Dropdown";
import IconButton from "../IconButton/IconButton";
import { renderModal } from "../ListMenu/renderModal";
import { useClickOutside } from "../helpers/useClickOutside";
import IconMenuList from "./IconMenuList";

export interface IconMenuInterface {
  options: (DropdownOption<number | string> | React.ReactElement)[];
  type?: "primary" | "text";
  icon?: IconKind;
  iconClicked?: IconKind;
  size?: BIG | LARGE | HUGE;
  spacing?: SMALL | NORMAL | BIG;
  color?: GREY | CSIS | SECONDARY;
  onSelect?: (value: number | string) => void;
  opensFromTop?: boolean;
  opensFromRight?: boolean;
  name: string;
  dataTestId?: string;
}

const IconMenu: React.FC<IconMenuInterface> = ({
  options,
  icon = "more",
  iconClicked = icon,
  onSelect,
  color = "grey",
  type = "text",
  spacing = "small",
  size,
  opensFromTop,
  opensFromRight,
  name,
  dataTestId,
}) => {
  const [isComponentVisible, setIsComponentVisible, ref, modalRef] =
    useClickOutside();

  const onOptionSelected: (value: number | string) => void = React.useCallback(
    (values) => {
      setIsComponentVisible(false);
      if (onSelect) {
        onSelect(values);
      }
    },
    [onSelect, setIsComponentVisible]
  );

  const classes = classNames("iconmenu", {});

  return (
    <div ref={ref} className={classes} data-test-id={dataTestId}>
      <IconButton
        color={color}
        type={type}
        size={size}
        spacing={spacing}
        isCircle
        icon={isComponentVisible ? iconClicked : icon}
        onButtonClick={() => setIsComponentVisible(!isComponentVisible)}
        ariaHasPopup={"menu"}
        dataTestId={name}
      />
      <div>
        {isComponentVisible &&
          renderModal(
            <IconMenuList
              options={options}
              onOptionSelected={onOptionSelected}
              opensFromRight={opensFromRight}
              opensFromTop={opensFromTop}
              parentHeight={ref.current?.clientHeight}
              parentName={name}
            />,
            ref,
            modalRef,
            opensFromRight
          )}
      </div>
    </div>
  );
};

export default IconMenu;
