import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { Status } from "../../models/Status";
import { QueryParams } from "../types";
import { mapCcStartAndEndDateToCorrectType } from "./utils";

export async function fetchCreditCardsApi(queryParams: Partial<QueryParams>) {
  const params = mapCcStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getCreditCardReportsApi20CompromisedDataCreditCardReportGet(
    {
      ...params,
    },
    STRINGIFY_QUERY_PARAMS
  );
}

export async function exportCCInfoCsvApi(queryParams: Partial<QueryParams>) {
  const params = mapCcStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGet(
    {
      ...params,
    },
    {
      format: "blob",
      ...STRINGIFY_QUERY_PARAMS,
    }
  );
}

export async function patchBulkStatusApi({
  ids,
  status,
}: {
  ids: string[];
  status: Status;
}) {
  return axiosCsisApi.bulkEditCreditCardReportsApi20CompromisedDataCreditCardReportBulkPatch(
    { external_ids: ids, status: status }
  );
}
