import Stack from "../../layout/Stack/Stack";
import { Icon } from "../../icons";
import CheckBox from "../CheckBox/CheckBox";
import Input from "../Input/Input";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";
import { QuestionData, QuestionMulti, QuestionSingle } from "./types";

export const Question = ({
  question,
  onAnswerChange,
  sectionId,
}: {
  question: QuestionData;
  onAnswerChange: (
    newAnswer: string | string[],
    qId: string,
    sectionId?: string
  ) => void;
  sectionId?: string;
}) => {
  function handleInputChange(newAnswer: string, questionId: string) {
    onAnswerChange(newAnswer, questionId, sectionId);
  }

  function handleRadioChange(newAnswer: string, questionId: string) {
    onAnswerChange([newAnswer], questionId, sectionId);
  }

  function handleCheckboxClick(question: QuestionData, choice: string) {
    if (question.answer && Array.isArray(question.answer)) {
      // either remove or add the choice
      if (question.answer.includes(choice)) {
        onAnswerChange(
          question.answer.filter((a) => a !== choice),
          question.id,
          sectionId
        );
      } else {
        onAnswerChange([...question.answer, choice], question.id, sectionId);
      }
    } else {
      onAnswerChange([choice], question.id, sectionId);
    }
  }

  function renderAnswer(question: QuestionSingle | QuestionMulti) {
    switch (question.type) {
      case "text":
        return (
          <>
            <Input
              isFullWidth
              name={question.id}
              type="text"
              value={question.answer}
              list={question.id + "_suggestions"}
              placeholder={question.placeholder}
              onChange={handleInputChange}
            />
            {question.suggestions && (
              <datalist id={question.id + "_suggestions"}>
                {question.suggestions.map((suggestion) => {
                  return <option key={suggestion} value={suggestion} />;
                })}
              </datalist>
            )}
          </>
        );
      case "textarea":
        return (
          <Input
            isFullWidth
            name={question.id}
            type="textarea"
            placeholder={question.placeholder}
            value={question.answer}
            onChange={handleInputChange}
          />
        );
      case "multi_radio":
        return (
          <RadioButtonGroup
            name={question.id}
            options={question.choices.map((c) => {
              return {
                title: c.choice,
                value: c.id || c.choice,
              };
            })}
            value={question.answer?.[0]}
            onChange={handleRadioChange}
          />
        );
      case "multi_checkbox":
        return (
          <>
            {question.choices.map((c) => (
              <CheckBox
                name={
                  c.id
                    ? sectionId + "-" + question.id + "-" + c.id
                    : sectionId + "-" + question.id + "-" + c.choice
                }
                title={c.choice}
                key={c.choice}
                isChecked={
                  question.answer &&
                  question.answer.length > 0 &&
                  question.answer.indexOf(c.choice) !== -1
                }
                onClick={() => handleCheckboxClick(question, c.choice)}
              />
            ))}
          </>
        );
      default:
        return null;
    }
  }
  return (
    <div className="questionnaire__question">
      <Stack isVertical isExpanded gutterSize="small">
        <Stack align="center">
          <div className="questionnaire__question-label">{question.label}</div>
          {question.tooltipText && (
            <Icon kind="help_filled" tooltipText={question.tooltipText} />
          )}
        </Stack>
        {renderAnswer(question)}
      </Stack>
    </div>
  );
};
