/* eslint-disable sonarjs/no-collapsible-if */
// TO FIX once this is resolved https://github.com/facebook/create-react-app/issues/11770
// eslint-disable-next-line import/no-webpack-loader-syntax
import LogoWhite from "!file-loader!../../img/logo_white.svg";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@csis.com/components";
import { CsisFooter } from "../PasswordReset/CsisFooter";
import { EmergencyCaseConfirmation } from "./EmergencyCaseInfo/CaseIdConfirmation";
import { EmergencyCaseQuestionary } from "./EmergencyCaseInfo/EmergencyCaseQuestionary";
import { EmergencyCaseTimer } from "./EmergencyCaseInfo/EmergencyCaseTimer";
import { getEmergencyCaseInfoResult, getSubmissionCode } from "./selectors";
import { getEmergencyCaseInfo, setQuestionaryData } from "./slice";
import { EmergencyCaseInfo, EmergencyCaseQuestionaryData } from "./types";

const Logo = () => (
  <div role="banner" className="emergency-case-confirmation-page__logo">
    <img src={LogoWhite} alt="CSIS Security Group Logo" />
  </div>
);

type ViewType = "confirm" | "questionary" | "timer";

const getStep = (emergencyCaseInfo: EmergencyCaseInfo | null): ViewType => {
  if (!emergencyCaseInfo) {
    return "confirm";
  }
  if (emergencyCaseInfo.submitted_at) {
    return "timer";
  }
  return "questionary";
};
const EmergencyCaseConfirmationPage = () => {
  const dispatch = useDispatch();

  // read the id from the url
  const id = window.location.pathname.split("/").pop();

  const { emergencyCaseInfo } = useSelector(getEmergencyCaseInfoResult);
  const submissionCode = useSelector(getSubmissionCode);

  const step = getStep(emergencyCaseInfo);

  const onConfirmCaseId = useCallback(
    (formDetails: { submissionCode: string }) => {
      dispatch(
        getEmergencyCaseInfo({
          caseId: id || "",
          code: formDetails.submissionCode,
        })
      );
    },
    [dispatch, id]
  );

  const handleSubmitQuestionaryData = useCallback(
    (formDetails: EmergencyCaseQuestionaryData) => {
      dispatch(
        setQuestionaryData({
          caseId: id || "",
          code: submissionCode,
          data: { questionnaire_data: formDetails },
        })
      );
    },
    [submissionCode, dispatch, id]
  );

  const currentView = useMemo(() => {
    switch (step) {
      case "confirm":
        return <EmergencyCaseConfirmation onSuccess={onConfirmCaseId} />;
      case "questionary":
        return (
          <EmergencyCaseQuestionary
            onSuccess={handleSubmitQuestionaryData}
            submissionCode={submissionCode}
          />
        );
      case "timer":
        return <EmergencyCaseTimer />;
    }
  }, [handleSubmitQuestionaryData, onConfirmCaseId, step, submissionCode]);

  return (
    <div className="emergency-case-confirmation-page">
      <Stack isVertical isExpanded gutterSize="gigantic">
        <Logo />
        <Stack align="center" isVertical isExpanded gutterSize="gigantic">
          <div className="emergency-case-confirmation-page__view">
            {currentView}
          </div>
        </Stack>
      </Stack>
      <CsisFooter />
    </div>
  );
};

export default EmergencyCaseConfirmationPage;
