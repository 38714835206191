import { axiosCsisApi } from "@csis.com/tip/src/App";
import { RetainerInfo } from "./types";

export async function fetchRetainerInfoAPI() {
  return axiosCsisApi.getRetainerApi20RetainerGet();
}

export async function updateRetainerInfoAPI(retainerInfo: RetainerInfo) {
  return axiosCsisApi.createOrUpdateRetainerApi20RetainerPost(retainerInfo);
}

export async function updateRetainerAttachmentsAPI(formData: File[]) {
  return axiosCsisApi.uploadRetainerAttachmentApi20RetainerAttachmentPost({
    files: formData,
  });
}

export async function deleteRetainerAttachmentAPI(attachmentId: string) {
  return axiosCsisApi.deleteRetainerAttachmentApi20RetainerAttachmentExternalIdDelete(
    attachmentId
  );
}

export async function fetchAttachmentApi(attachmentId: string) {
  return axiosCsisApi.downloadRetainerAttachmentApi20RetainerAttachmentExternalIdGet(
    attachmentId,
    {
      format: "blob",
    }
  );
}
