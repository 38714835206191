import { OpenAPIV3 } from "openapi-types";
import {
  DocumentationSection,
  EndpointOperation,
  HttpMethod,
} from "@csis.com/tip/src/pages/HelpAndSupport/ApiDocumentation/Section/types";

export function parseOpenApiSpec(
  openApiSpec: OpenAPIV3.Document,
  sectionName: string
) {
  const parsedSpec = parsePaths(openApiSpec);
  parsedSpec.sectionName = sectionName;
  return parsedSpec;
}

// returns all paths
function parsePaths(openApiSpec: OpenAPIV3.Document): DocumentationSection {
  const paths = Object.entries(openApiSpec.paths);

  // 0 in path will be path/url(key) and 1 will be the object(value) containing info
  const mappedPaths = paths.map((path) => {
    return {
      endpoint: path[0],
      operations: parsePathObject(path[1] as OpenAPIV3.PathsObject),
    };
  });

  // extract tags for grouping
  const groupingTags = new Set<string>();

  mappedPaths.forEach((path) => {
    path.operations.forEach((op) => {
      if (op.tags) groupingTags.add(op.tags.join("-"));
    });
  });

  return {
    tags: groupingTags,
    paths: mappedPaths,
  };
}

// returns all methods for a path
function parsePathObject(pathObj: OpenAPIV3.PathsObject): EndpointOperation[] {
  const operations = Object.entries(pathObj);

  return operations.map((operation) => {
    const op = operation[1] as OpenAPIV3.OperationObject;

    // Type refinement for requestBody
    let requestBody: OpenAPIV3.RequestBodyObject | undefined;
    if (op.requestBody && !("$ref" in op.requestBody)) {
      requestBody = op.requestBody as OpenAPIV3.RequestBodyObject;
    }

    return {
      method: operation[0].toUpperCase() as HttpMethod,
      summary: op.summary,
      description: op.description,
      tags: op.tags,
      // checks if the parameter is a reference object
      parameters: op.parameters?.filter(
        (param): param is OpenAPIV3.ParameterObject => !("$ref" in param)
      ),
      operationId: op.operationId,
      responses: op.responses,
      requestBody: requestBody,
      security: op.security,
    };
  });
}
