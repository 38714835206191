import classNames from "classnames";
import React, { useEffect } from "react";
import Icon, { IconKind } from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { BLUE, RED } from "../../colors";
import IconButton from "../IconButton/IconButton";

const AUTOHIDE_DURATION = 5000;

export interface ToastInterface {
  icon: IconKind;
  text: string | React.ReactNode;
  isShown: boolean;
  onHide: () => void;
  color?: RED | BLUE;
  dataTestId?: string;
  isDismissable?: boolean;
}

const Toast: React.FC<ToastInterface> = ({
  icon,
  text,
  isShown,
  onHide,
  color,
  dataTestId,
  isDismissable,
}) => {
  useEffect(() => {
    if (isShown && !isDismissable) {
      setTimeout(() => {
        onHide();
      }, AUTOHIDE_DURATION);
    }
  }, [isShown, isDismissable, onHide]);

  const classes = classNames("toast", {
    "toast--shown": isShown,
    "toast--blue": color === "blue",
    "toast--red": color === "red",
  });
  return (
    <div className={classes} data-test-id={dataTestId}>
      <Stack align="center" gutterSize="big">
        <Icon kind={icon} size="huge" />
        <div className="toast__text">{text}</div>
        {isDismissable ? (
          <IconButton
            icon="close"
            size="large"
            spacing="small"
            type="text"
            isCircle
            onButtonClick={onHide}
            dataTestId={dataTestId + "-close-iconbtn"}
          />
        ) : null}
      </Stack>
    </div>
  );
};

export default Toast;
