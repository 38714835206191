import classNames from "classnames";
import React, { useState } from "react";
import { Button, Stack } from "@csis.com/components";
import { useInterval } from "@csis.com/components/src/utils/utils";
import IconButton from "../IconButton/IconButton";
import { AUTOPLAY_INTERVAL_IN_MS } from "./constants";

export interface CarouselInterface {
  name?: string;
  items: React.ReactElement[];
  arrowsPosition?: "sides" | "bottom";
  isLoading?: boolean;
  infiniteMode?: boolean;
  hasAutoplay?: boolean;
  isAutoplayEnabled?: boolean;
  autoplayIntervalDurationMS?: number;
  onChange?: (newIndex: number) => void;
}

const Carousel: React.FC<CarouselInterface> = ({
  name,
  items,
  isLoading,
  infiniteMode = true,
  arrowsPosition = "sides",
  hasAutoplay,
  isAutoplayEnabled,
  autoplayIntervalDurationMS = AUTOPLAY_INTERVAL_IN_MS,
  onChange,
}) => {
  const [current, setCurrent] = useState<number>(0);
  const [autoplayToggle, setAutoplayToggle] = useState<boolean>(
    Boolean(isAutoplayEnabled)
  );

  function handleChange(newIndex: number) {
    setCurrent(newIndex);
    if (onChange) {
      onChange(newIndex);
    }
  }

  function handleSetPrevious() {
    handleChange((current - 1 + items.length) % items.length);
  }

  function handleSetNext() {
    handleChange((current + 1) % items.length);
  }

  const classes = classNames("carousel", {});

  useInterval(() => {
    if (hasAutoplay && !isLoading && autoplayToggle) {
      handleSetNext();
    }
  }, autoplayIntervalDurationMS);

  if (items.length === 0) {
    return null;
  }

  const NavigateLeftButton = ({ isDisabled }: { isDisabled: boolean }) => (
    <Button
      text="Prev"
      icon="left-arrow"
      onButtonClick={handleSetPrevious}
      name="x"
      type="text"
      size="small"
      isDisabled={isDisabled}
    />
  );

  const NavigateLeftIconButton = ({ isDisabled }: { isDisabled: boolean }) => (
    <div className="carousel__control">
      <IconButton
        isCircle
        type="text"
        icon="left-arrow"
        onButtonClick={handleSetPrevious}
        isDisabled={isDisabled}
      />
    </div>
  );

  const NavigateRightButton = ({ isDisabled }: { isDisabled: boolean }) => (
    <Button
      text="Next"
      icon="right-arrow"
      onButtonClick={handleSetNext}
      name="x"
      type="text"
      size="small"
      isIconAfterText
      isDisabled={isDisabled}
    />
  );

  const NavigateRightIconButton = ({ isDisabled }: { isDisabled: boolean }) => (
    <div className="carousel__control">
      <IconButton
        isCircle
        type="text"
        icon="right-arrow"
        onButtonClick={handleSetNext}
        isDisabled={isDisabled}
      />
    </div>
  );

  const isFirst = current === 0;
  const isLast = current === items.length - 1;

  return (
    <section className={classes} aria-label={name}>
      <Stack align="stretch" isVertical>
        <div className="carousel__top-part">
          {items.length > 1 && arrowsPosition === "sides" && (
            <NavigateLeftIconButton isDisabled={!infiniteMode && isFirst} />
          )}
          <div className="carousel__slides">
            {items.map(
              (item, index) =>
                index === current && (
                  <React.Fragment key={index}>{item}</React.Fragment>
                )
            )}
          </div>
          {items.length > 1 && arrowsPosition === "sides" && (
            <NavigateRightIconButton isDisabled={!infiniteMode && isLast} />
          )}
        </div>
        <Stack
          align="center"
          justify={arrowsPosition === "bottom" ? "space-between" : "center"}
        >
          {items.length > 1 && arrowsPosition === "bottom" && (
            <NavigateLeftButton isDisabled={!infiniteMode && isFirst} />
          )}
          {items.length > 1 && (
            <ul className="carousel__navigation">
              {items.map((_item, index) => (
                <li key={index}>
                  <button
                    name={"carousel-navigation-slide-" + index}
                    aria-label={"carousel-navigation-slide-" + index}
                    className={
                      index === current
                        ? "carousel__navigation__button carousel__navigation__button--current"
                        : "carousel__navigation__button"
                    }
                    onClick={() => handleChange(index)}
                  />
                </li>
              ))}
            </ul>
          )}

          {items.length > 1 && arrowsPosition === "bottom" && (
            <NavigateRightButton isDisabled={!infiniteMode && isLast} />
          )}
        </Stack>
      </Stack>
      {hasAutoplay ? (
        <div className="carousel__navigation__autoplay">
          <Stack>
            <IconButton
              isCircle
              icon="autoplay"
              type="text"
              size="large"
              tooltipText={
                autoplayToggle ? "Autoplay is enabled" : "Autoplay is disabled"
              }
              color={autoplayToggle ? "csis" : "grey"}
              onButtonClick={() =>
                setAutoplayToggle((autoplayToggle) => !autoplayToggle)
              }
            />
          </Stack>
        </div>
      ) : null}
    </section>
  );
};

export default Carousel;
