import { RootState } from "@csis.com/tip/src/configureStore";

export const getUserPreferences = (state: RootState) =>
  state.userPreferencesReducer.userPreferences;

export const getUserPreferencesForPhish = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.phishing;

export const getUserPreferencesForAlerts = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.alerts;

export const getUserPreferencesForTickets = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.tickets;

export const getUserPreferencesForTicket = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.ticket;

export const getUserPreferencesForReports = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.reports;

export const getUserPreferencesForGeneral = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.general;

export const getUserPreferencesForLocalization = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.localization;

export const getUserPreferencesForStrayDataCards = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.straydata_cards;

export const getUserPreferencesForStrayDataCredentials = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.straydata_credentials;

export const getUserPreferencesForThreatInsights = (state: RootState) =>
  state.userPreferencesReducer.userPreferences?.threat_insights;
