import { RootState } from "@csis.com/tip/src/configureStore";

export const getIsPasswordResetEmailSuccess = (state: RootState) =>
  state.passwordResetEmailReducer.isSuccess;

export const getIsPasswordResetEmailPending = (state: RootState) =>
  state.passwordResetEmailReducer.isPasswordResetEmailPending;

export const getPasswordResetEmailError = (state: RootState) =>
  state.passwordResetEmailReducer.passwordResetEmailError;
