import classNames from "classnames";

// getUrlForLevel constructs a URL path up to a specified "level" of depth.
// For example, given a URL "/a/b/c/d" and a level of 2, it returns "/a/b".
// - `url`: The full URL path to be processed.
// - `level`: The depth level to which the URL should be truncated.
export const getUrlForLevel = (url: string, level: number) => {
  const splittedUrl = url.split("/");
  return "/" + splittedUrl.slice(1, level + 1).join("/");
};

// isLevelActive determines if the current route matches the URL up to a specified level.
// This is used to check if a menu item should be marked as active based on the current route.
// - `route`: The current route/path being accessed.
// - `url`: The URL path of the menu item.
// - `level`: The depth level to compare the current route against the menu item's URL.
export const isLevelActive = (route: string, url: string, level: number) => {
  return route.startsWith(getUrlForLevel(url, level));
};

// getClassesForMenu generates the CSS class names for a menu item based on its active state.
// if the menu item's URL matches the current route up to a specified level.
// - `route`: The current route/path being accessed.
// - `url`: The URL path of the menu item.
// - `level`: The depth level to compare the current route against the menu item's URL.
// - `baseClassName`: The base CSS class name for the menu item.
export const getClassesForMenu = (
  route: string,
  url: string,
  level: number,
  baseClassName: string,
  isItemHighlightedForOnboarding?: boolean
) => {
  return classNames(baseClassName, {
    [`${baseClassName}--selected`]: isLevelActive(route, url, level),
    "onboarding-highlight": isItemHighlightedForOnboarding,
  });
};
