import React from "react";
import { IconButton, Stack } from "@csis.com/components";
import CsisLogo from "@csis.com/tip/src/img/Logo/CsisLogo";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { LinkWithOrgId } from "../../LinkWithOrgId/LinkWithOrgId";

export interface SidebarHeaderInterface {
  isMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
  isAdmin?: boolean;
  logo?: string;
  homeUrl: string;
}

const SidebarHeader: React.FC<SidebarHeaderInterface> = ({
  isMinimized,
  setIsMinimized,
  isAdmin,
  homeUrl,
}) => {
  const { t } = useTranslations();

  return (
    <div className="sidebar__header">
      <Stack align="center" justify="space-between">
        {!isMinimized && (
          <Stack align="center">
            <LinkWithOrgId href={homeUrl}>
              <div className="sidebar__header__logo">
                <CsisLogo />
                logo
              </div>
            </LinkWithOrgId>
            {isAdmin && (
              <div className="sidebar__header__admin-indicator">INTERNAL</div>
            )}
          </Stack>
        )}
        <IconButton
          type="text"
          size="large"
          isCircle
          spacing="normal"
          icon={isMinimized ? "menu_collapsed" : "menu_expanded"}
          onButtonClick={() => setIsMinimized(!isMinimized)}
          tooltipText={t("sliding_menu")}
        />
      </Stack>
    </div>
  );
};

export default SidebarHeader;
