import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const alertsKeys = {
  ALERT_ID: "id",
  DISPLAY_ID: "display_id",
  STATUS: "status",
  SEVERITY: "severity",
  TITLE: "title",
  RELEASED: "released",
  ALERTER: "alerter",
  ATTACHED_TICKET: "ticket",
  RELEASED_AFTER: "released_after",
  RELEASED_BEFORE: "released_before",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [
  alertsKeys.SEVERITY,
  alertsKeys.STATUS,
  alertsKeys.ALERTER,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;
