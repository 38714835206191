import { useMemo } from "react";
import { Stack, Table } from "@csis.com/components";
import { OnRowClick } from "@csis.com/components/src/atoms/Table/types";
import { StatisticsCount } from "@csis.com/tip/src/api/openapi/data-contracts";
import { LinkWithOrgId } from "@csis.com/tip/src/components/LinkWithOrgId/LinkWithOrgId";
import { SeveritiesTotalEntry } from "@csis.com/tip/src/pages/Alerts/Statistics/types";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import {
  alertsCountCell,
  alertsSeverityCell,
} from "../Overview/tableFormatters";

interface AlertsOverviewProps {
  totalAlerts: number;
  alertsTotalUrl: string;
  severitiesDistributions: StatisticsCount[];
  isSeveritiesDistributionPending: boolean;
  handleRowClick: OnRowClick<SeveritiesTotalEntry>;
}

const AlertsOverview: React.FC<AlertsOverviewProps> = ({
  totalAlerts,
  alertsTotalUrl,
  severitiesDistributions,
  isSeveritiesDistributionPending,
  handleRowClick,
}) => {
  const { t } = useTranslations();

  const alertsFormatters = useMemo(
    () => ({
      count: alertsCountCell,
      name: alertsSeverityCell(t),
    }),
    [t]
  );

  return (
    <div className="landing-page__alerts-overview">
      <Stack gutterSize="huge" align="center">
        <div className="landing-page__alerts-overview__total">
          <LinkWithOrgId href={alertsTotalUrl} isFullWidth>
            <Stack isVertical align="center">
              <div className="f_gigantic f_semibold">{totalAlerts}</div>
              <div className="f_semibold f_uppercase">{t("alerts")}</div>
            </Stack>
          </LinkWithOrgId>
        </div>
        {(severitiesDistributions.length > 0 ||
          isSeveritiesDistributionPending) && (
          <Table
            name="product-overview-table-1"
            hasHeader={false}
            size="small"
            isBorderless
            onRowClick={handleRowClick}
            isLoading={isSeveritiesDistributionPending}
            ghostRows={5}
            rows={severitiesDistributions}
            columns={[
              { key: "count", name: "count", isRightAligned: true },
              { key: "name", name: "name" },
            ]}
            formatters={alertsFormatters}
            dataTestId={"alerts-overview-table"}
          />
        )}
      </Stack>
    </div>
  );
};

export default AlertsOverview;
