import classNames from "classnames";
import React from "react";
import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { Color } from "../../colors";

export interface ReminderInterface {
  text: string | React.ReactNode;
  withDot?: boolean;
  underlineOnHover?: boolean;
  hasRoundedCorners?: boolean;
  color?: Color;
}

const Reminder: React.FC<ReminderInterface> = ({
  text,
  color = "csis",
  underlineOnHover = true,
  hasRoundedCorners,
  withDot = true,
}) => {
  const classes = classNames("reminder", {
    "reminder--csis": color === "csis",
    "reminder--red": color === "red",
    "reminder--orange": color === "orange",
    "reminder--blue": color === "blue",
    "reminder--grey": color === "grey",
    "reminder--rounded-corners": hasRoundedCorners,
    "reminder--underline-on-hover": underlineOnHover,
  });

  return (
    <div className={classes}>
      <Stack align="center">
        {withDot && <Icon kind="dot" size="tiny" />}
        <div>{text}</div>
      </Stack>
    </div>
  );
};

export default Reminder;
