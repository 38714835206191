import classNames from "classnames";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import Icon, { IconKind } from "../../icons/Icon";
import { BIG, LARGE, SMALL } from "../../sizes";

function getText(text?: string, icon?: IconKind, isIconAfterText?: boolean) {
  return (
    <>
      {icon && !isIconAfterText ? <Icon kind={icon} /> : null}
      {text && <span>{text}</span>}
      {icon && isIconAfterText ? <Icon kind={icon} /> : null}
    </>
  );
}
export interface LinkInterface {
  href: string;
  text?: string;
  size?: SMALL | BIG | LARGE;
  ariaCurrent?: "location" | "page";
  ariaLabel?: string;
  icon?: IconKind;
  tooltipText?: string;
  isDisabled?: boolean;
  isUppercase?: boolean;
  isIconAfterText?: boolean;
  shouldOpenInNewWindow?: boolean;
  withStyling?: boolean;
  children?: React.ReactNode;
  isFullWidth?: boolean;
  dataTestId?: string;
  isExternal?: boolean;
}

const Link: React.FC<LinkInterface> = ({
  href,
  text,
  isDisabled,
  icon,
  size,
  isIconAfterText,
  isUppercase,
  ariaCurrent,
  ariaLabel,
  withStyling = true,
  shouldOpenInNewWindow,
  isFullWidth,
  tooltipText,
  children,
  dataTestId,
  isExternal,
}) => {
  const classes = classNames("link", {
    "link--disabled": isDisabled,
    "link--small": size === "small",
    "link--big": size === "big",
    "link--large": size === "large",
    "link--uppercase": isUppercase,
    "link--with-styling": withStyling,
    "link--fullwidth": isFullWidth,
  });

  return isExternal ? (
    <a
      tabIndex={isDisabled ? -1 : 0}
      aria-disabled={isDisabled}
      className={classes}
      href={href}
      target={shouldOpenInNewWindow ? "_blank" : undefined}
      rel="noreferrer"
    >
      {children ? children : getText(text, icon, isIconAfterText)}
    </a>
  ) : (
    // @ts-ignore
    <ReactRouterLink
      aria-current={ariaCurrent}
      aria-label={ariaLabel || text || tooltipText}
      className={classes}
      aria-disabled={isDisabled}
      to={href}
      tabIndex={isDisabled ? -1 : 0}
      target={shouldOpenInNewWindow ? "_blank" : undefined}
      data-test-id={dataTestId}
      title={tooltipText}
    >
      {children ? children : getText(text, icon, isIconAfterText)}
    </ReactRouterLink>
  );
};

export default Link;
