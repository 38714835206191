import { axiosCsisApi } from "@csis.com/tip/src/App";
import { AlertsSettings } from "../../types";

export async function fetchAlertsSettingsApi() {
  return axiosCsisApi.getAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertGet();
}

export async function patchAlertsSettingsApi(settings: AlertsSettings) {
  return axiosCsisApi.patchAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertPatch(
    // @ts-ignore can be null - api type is wrong
    settings
  );
}
