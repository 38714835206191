import classNames from "classnames";
import React from "react";
import Stack from "../../layout/Stack/Stack";
import { TabsBodyInterface } from "./TabsBody";
import { TabsHeaderInterface } from "./TabsHeader";

// 0 index based counting! first tab = 0
export interface TabsInterface {
  selectedTab?: number;
  onSelectTab?: (tab: number) => void;
  children: React.ReactNode;
}

const Tabs: React.FC<TabsInterface> = ({
  selectedTab = 0,
  children,
  onSelectTab,
}) => {
  const classes = classNames("tabs", {});
  const handleTabClick = (index: number) => {
    if (onSelectTab) {
      onSelectTab(index);
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(
      child as React.ReactElement<TabsHeaderInterface | TabsBodyInterface>,
      {
        selectedTab: selectedTab,
        onTabClick: handleTabClick,
      }
    );
  });

  return (
    <div className={classes} role="tablist">
      <Stack isVertical align="stretch" gutterSize="large">
        {childrenWithProps}
      </Stack>
    </div>
  );
};

export default Tabs;
