import { axiosCsisApi } from "@csis.com/tip/src/App";
import { Status } from "../../models/Status";

export async function fetchCreditCardReportApi(id: string) {
  return axiosCsisApi.getCreditCardReportApi20CompromisedDataCreditCardReportExternalIdGet(
    id
  );
}

export async function patchCreditCardReportStatusApi({
  id,
  status,
}: {
  id: string;
  status: Status;
}) {
  return axiosCsisApi.editCreditCardReportApi20CompromisedDataCreditCardReportExternalIdPatch(
    id,
    { status: status }
  );
}

export async function patchCreditCardReportCommentApi({
  id,
  customer_comment,
}: {
  id: string;
  customer_comment: string;
}) {
  return axiosCsisApi.editCreditCardReportApi20CompromisedDataCreditCardReportExternalIdPatch(
    id,
    { customer_comment: customer_comment }
  );
}
