import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { BarChart } from "@csis.com/components";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getIsoDateString } from "@csis.com/tip/src/utils/dateCalculations";
import {
  getFirstDayOfAWeek,
  getStartDateAndEndDateForADay,
  getStartDateAndEndDateForAWeek,
  isValidDay,
  isValidWeekString,
} from "@csis.com/tip/src/utils/dateChartsCalculations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { ticketsKeys } from "../../TicketsSearch/constants";
import {
  SeverityChartColors,
  SeverityTranslationKeys,
  SeverityValues,
} from "../../models/Severity";
import { transformHistogramDataToBarchartData } from "../transformers";
import { getSeveritiesDistributionOverTimeResult } from "./selectors";

export const SeverityBarChart = () => {
  const { t } = useTranslations();
  const history = useHistory();

  const {
    severitiesDistributionsOverTime,
    isSeveritiesDistributionOverTimePending,
    severitiesDistributionsOverTimeError,
  } = useSelector(getSeveritiesDistributionOverTimeResult);

  const severitiesDistributionsOverTimeAsBarChart =
    transformHistogramDataToBarchartData(severitiesDistributionsOverTime);

  function handleBarChartClick(chartData: unknown) {
    // @ts-ignore
    const date = chartData?.name;

    if (isValidDay(date)) {
      const [startDate, endDate] = getStartDateAndEndDateForADay(date);

      const ticketsDateSelectedUrl = getNewLocationUrl(urls.tickets_search, {
        [ticketsKeys.START_DATE]: getIsoDateString(startDate),
        [ticketsKeys.END_DATE]: getIsoDateString(endDate),
      });

      history.push(ticketsDateSelectedUrl);
    }

    if (isValidWeekString(date)) {
      const firstDayOfAWeek = getFirstDayOfAWeek(date);

      const [startDate, endDate] =
        getStartDateAndEndDateForAWeek(firstDayOfAWeek);

      const ticketsDateSelectedUrl = getNewLocationUrl(urls.tickets_search, {
        [ticketsKeys.START_DATE]: getIsoDateString(startDate),
        [ticketsKeys.END_DATE]: getIsoDateString(endDate),
      });

      history.push(ticketsDateSelectedUrl);
    }
  }

  return (
    <BarChart
      xAxisKey="name"
      isStacked
      yAxisKeys={Object.values(SeverityValues).map((severity) => ({
        key: severity,
        name: t(SeverityTranslationKeys[severity]),
        color: SeverityChartColors[severity],
      }))}
      data={severitiesDistributionsOverTimeAsBarChart}
      isLoading={isSeveritiesDistributionOverTimePending}
      error={severitiesDistributionsOverTimeError}
      onClick={handleBarChartClick}
    />
  );
};
