import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
  CodeBlock,
  Stack,
} from "@csis.com/components";
import {
  EndpointSection,
  EndpointSectionContent,
} from "../EndpointSection/EndpointSection";
import { Subject } from "../Subject/Subject";

export const GainingAccessSection: React.FC = () => {
  const oAuth2DocUrl: string = "https://oauth.net/2/";
  const authUrl: string = "https://login.csis.com";
  const apiURL: string = "https://api.csis.com";
  const ticketReadScope: string = `${apiURL}/ticket:read`;
  const ticketWriteScope: string = `${apiURL}/ticket:write`;

  const curlExample: string = `export CLIENT_ID=<insert client ID>
export CLIENT_SECRET=<insert client secret>`;

  const oAuth2Example: string = `curl ${authUrl}/oauth2/v2/token -d
"grant_type=client_credentials&client_id=$CLIENT_ID&client_secret=$CLIENT_SECRET&scope=scope1 scope2"`;

  const ticketEndpointAccessTokenExample: string = `curl ${authUrl}/oauth2/v2/token -d
"grant_type=client_credentials&client_id=$CLIENT_ID&client_secret=$CLIENT_SECRET&scope=${ticketReadScope} ${ticketWriteScope}"`;

  const pythonExample = `import requests
from urllib.parse import urljoin

login_url = "${authUrl}"

def get_token(client_id: str, client_secret: str):
    url = urljoin(login_url, "/oauth2/v2/token")
    payload = {
        "grant_type": "client_credentials",
        "client_id": client_id,
        "client_secret": client_secret,
        "scope": "${ticketReadScope} ${ticketWriteScope}"
    }
    response = requests.post(url, data=payload)
    if response.status_code == 200:
        token = response.json().get("access_token")
        return token
    else:
        print(f"Error {response.status_code}: {response.text}")
        return None
`;

  return (
    <Subject title="Gaining Access" id="portals-gaining-access">
      <EndpointSection>
        <h4 id="authentication-portals" className="f_semibold f_large">
          Authentication
        </h4>
        <EndpointSectionContent>
          <p>
            All requests to the API is authenticated via Bearer access token
            using the{" "}
            <a
              className="f_csis"
              target="_blank"
              rel="noreferrer"
              href={oAuth2DocUrl}
            >
              OAuth 2.0
            </a>{" "}
            protocol. A token is obtained by requesting{" "}
            <a
              className="f_csis"
              target="_blank"
              rel="noreferrer"
              href={`${authUrl}/oauth2/v2/token`}
            >
              {`${authUrl}/oauth2/v2/token`}
            </a>{" "}
            with your client ID and client secret as well as the desired scopes.
          </p>
          <Accordion headerGutterSize="space-between" headerColor="grey">
            <AccordionItem>
              <AccordionItemHeader>
                <span className="f_normal">Examples</span>
              </AccordionItemHeader>
              <AccordionItemBody>
                <h4 className="f_normal f_semibold">
                  Here is an example of how to do it via curl:
                </h4>
                <Stack>
                  <CodeBlock
                    color="grey"
                    text={curlExample}
                    isCopyable
                    isFullWidth
                  />
                </Stack>
                <p>
                  Then request the OAuth 2.0 endpoint with the desired scopes:
                </p>
                <Stack>
                  <CodeBlock
                    color="grey"
                    text={oAuth2Example}
                    isCopyable
                    isFullWidth
                  />
                </Stack>
                <p>
                  This returns an access token as well as when it expires. If a
                  token has expired a new one needs to be requested.
                </p>
                <p>
                  To figure out which scopes are needed for a specific endpoint,
                  check the endpoint documentation below, As an example the{" "}
                  <span className="f_normal f_semibold">Get Ticket</span>{" "}
                  endpoint requires the following scope:
                </p>
                <CodeBlock text={ticketReadScope} color="red" />
                <p>
                  And the{" "}
                  <span className="f_normal f_semibold">Update Ticket</span>{" "}
                  endpoint requires the following scope:
                </p>
                <CodeBlock text={ticketWriteScope} color="red" />
                <p>
                  One token can be used for multiple endpoints, so it is easiest
                  to request all the scopes that is needed for the token at
                  once. We recommend only requesting the scopes that the token
                  needs in order to minimize damage in case of stolen
                  credentials. Here is an example of how to request a token for
                  to be used for both the{" "}
                  <span className="f_normal f_semibold">Get Ticket</span> and{" "}
                  <span className="f_normal f_semibold">Update Ticket</span>{" "}
                  endpoints:
                </p>
                <Stack>
                  <CodeBlock
                    color="grey"
                    text={ticketEndpointAccessTokenExample}
                    isCopyable
                    isFullWidth
                  />
                </Stack>
                <h4 className="f_normal f_semibold">In Python:</h4>
                <Stack>
                  <CodeBlock
                    color="grey"
                    text={pythonExample}
                    isCopyable
                    isFullWidth
                  />
                </Stack>
              </AccordionItemBody>
            </AccordionItem>
          </Accordion>
        </EndpointSectionContent>
      </EndpointSection>
    </Subject>
  );
};
