import classNames from "classnames";
import React from "react";

export interface ListMenuHeaderInterface {
  children: React.ReactNode;
}

const ListMenuHeader: React.FC<ListMenuHeaderInterface> = ({ children }) => {
  const classes = classNames("listmenu__header", {});
  return (
    <div onClick={(e) => e.stopPropagation()} className={classes}>
      {children}
    </div>
  );
};

export default ListMenuHeader;
