import axios, { AxiosError } from "axios";
import * as qs from "query-string";
import { isString } from "@csis.com/tip/src/models/helpers";
import { removeDuplicateErrors } from "./helpers";
import { isCsisErrorResponse } from "./models/ErrorResponse";

// passed as arg to axios get requests, to stringify the params (for example if they are arrays)
// so we want to send to the api ?status=new&status=closed NOT ?status[]=new&status[]=closed
export const STRINGIFY_QUERY_PARAMS = {
  paramsSerializer: {
    serialize: (params: any) => qs.stringify(params),
  },
};

export function handleRequestError(e: AxiosError | unknown): string {
  if (axios.isAxiosError(e)) {
    // check if it is a CSIS error
    if (e?.response?.data && isCsisErrorResponse(e?.response?.data)) {
      const response = e.response.data;

      // the api returns duplicate field errors sometimes. We filter them here
      const uniqueErrors = removeDuplicateErrors(response.errors);

      const csisErrorText = uniqueErrors
        .map((error) => {
          if (isString(error)) {
            return "-" + error + "\n";
          } else {
            if (error.field === "__root__") {
              // special case where the field doesnt "exist"
              return "-" + error.error + "\n";
            }
            return "-" + error.field + ": " + error.error + "\n";
          }
        })
        .join("");

      return e.message + "\n" + csisErrorText;
    }

    // the default axios error message
    return e.message;
  } else {
    // could be anything!
    return "Something went wrong";
  }
}
