import classNames from "classnames";
import React from "react";
import { CSIS, GREY } from "../../colors";
import { BIG, NORMAL } from "../../sizes";
import { AccordionItemInterface } from "./AccordionItem";

export interface AccordionInterface {
  isFullWidth?: boolean;
  size?: NORMAL | BIG;
  isTransparent?: boolean;
  headerColor?: CSIS | GREY;
  headerGutterSize: "big" | "space-between";
  dataTestId?: string;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionInterface> = ({
  isFullWidth,
  headerGutterSize = "big",
  size,
  isTransparent,
  headerColor,
  dataTestId,
  children,
}) => {
  const classes = classNames("accordion", {
    "accordion--full-width": isFullWidth,
    "accordion--transparent": isTransparent,
    "accordion--big": size === "big",
    "accordion--grey": headerColor === "grey",
  });

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(
      child as React.ReactElement<AccordionItemInterface>,
      {
        headerGutterSize: headerGutterSize,
        isTransparent: isTransparent,
        headerColor: headerColor,
      }
    );
  });

  return (
    <div className={classes} data-test-id={dataTestId}>
      {childrenWithProps}
    </div>
  );
};

export default Accordion;
