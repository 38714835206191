import classNames from "classnames";
import React from "react";
import Stack from "../../layout/Stack/Stack";
import { TabInterface } from "./Tab";

export interface TabsHeaderInterface {
  selectedTab?: number;
  onTabClick?: (tab: number) => void;
  children: React.ReactNode;
}

const TabsHeader: React.FC<TabsHeaderInterface> = ({
  selectedTab,
  onTabClick,
  children,
}) => {
  const classes = classNames("tabs__header", {});

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child as React.ReactElement<TabInterface>, {
      selectedTab: selectedTab,
      index: index,
      onTabClick: onTabClick,
    });
  });
  return (
    <div className={classes}>
      <Stack align="center" gutterSize="large">
        {childrenWithProps}
      </Stack>
    </div>
  );
};

export default TabsHeader;
