import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getSeveritiesDistribution = (state: RootState) =>
  state.ticketsStatisticsSeverityReducer.data;

export const getIsSeveritiesDistributionPending = (state: RootState) =>
  state.ticketsStatisticsSeverityReducer.isDataPending;

export const getSeveritiesDistributionFetchError = (state: RootState) =>
  state.ticketsStatisticsSeverityReducer.dataFetchError;

export const getSeveritiesDistributionResult = createSelector(
  [
    getSeveritiesDistribution,
    getIsSeveritiesDistributionPending,
    getSeveritiesDistributionFetchError,
  ],
  (
    severitiesDistributions,
    isSeveritiesDistributionPending,
    severitiesDistributionError
  ) => {
    return {
      severitiesDistributions,
      isSeveritiesDistributionPending,
      severitiesDistributionError,
    };
  }
);

export const getSeveritiesDistributionOverTime = (state: RootState) =>
  state.ticketsStatisticsSeverityOverTimeReducer.data;

export const getIsSeveritiesDistributionOverTimePending = (state: RootState) =>
  state.ticketsStatisticsSeverityOverTimeReducer.isDataPending;

export const getSeveritiesDistributionOverTimeFetchError = (state: RootState) =>
  state.ticketsStatisticsSeverityOverTimeReducer.dataFetchError;

export const getSeveritiesDistributionOverTimeResult = createSelector(
  [
    getSeveritiesDistributionOverTime,
    getIsSeveritiesDistributionOverTimePending,
    getSeveritiesDistributionOverTimeFetchError,
  ],
  (
    severitiesDistributionsOverTime,
    isSeveritiesDistributionOverTimePending,
    severitiesDistributionsOverTimeError
  ) => {
    return {
      severitiesDistributionsOverTime,
      isSeveritiesDistributionOverTimePending,
      severitiesDistributionsOverTimeError,
    };
  }
);
