import { Category, CategoryEntry } from "./Category";

export function getCategoryDisplayName(
  category: Category,
  categories: CategoryEntry[]
) {
  const displayName = categories.find(
    (cat) => cat.name === category
  )?.display_name;

  return displayName ? displayName : category;
}
