import React, { ReactElement } from "react";
import { SMALL } from "../../sizes";
import ListMenu from "../ListMenu/ListMenu";
import { DropdownOption } from "./Dropdown";
import MultiSelectList from "./MultiSelectList/MultiSelectList";
import SingleSelectList from "./SingleSelectList/SingleSelectList";

interface CommonDropdownListTypes<T> {
  name: string;
  parentId: string;
  size?: SMALL;
  options: DropdownOption<T>[];
  isSearchable?: boolean;
  opensFromRight?: boolean;
  opensFromTop?: boolean;
  headerTitle: string;
  parentHeight?: number;
  dataTestId?: string;
  internalDropdown?: ReactElement;
  isLoading?: boolean;
  error?: string | null;
  onRetry?: () => void;
}

export interface SingleValueDropdownListInterface<T>
  extends CommonDropdownListTypes<T> {
  value?: T;
  isMultiSelect?: false;
  onOptionSelected: (value: T, e?: React.MouseEvent) => void;
}

export interface MultiValueDropdownListInterface<T>
  extends CommonDropdownListTypes<T> {
  values?: T[];
  isMultiSelect: true;
  onOptionsSelected: (values: T[], e?: React.MouseEvent) => void;
}

export type DropdownListInterface<T> =
  | SingleValueDropdownListInterface<T>
  | MultiValueDropdownListInterface<T>;

const DropdownList = <T,>(props: DropdownListInterface<T>) => {
  const {
    options,
    parentId,
    name,
    headerTitle,
    isSearchable,
    size,
    opensFromRight,
    opensFromTop,
    parentHeight,
    dataTestId,
    internalDropdown,
    isLoading,
    error,
    onRetry,
  } = props;

  return (
    <ListMenu
      parentName={parentId}
      size={size}
      opensFromTop={opensFromTop}
      opensFromRight={opensFromRight}
      parentHeight={parentHeight}
      isMultiSelectable={props.isMultiSelect}
      hasMinWidth={Boolean(internalDropdown)}
      hasMinHeight={Boolean(internalDropdown)}
    >
      {props.isMultiSelect ? (
        <MultiSelectList
          name={name}
          values={props.values}
          dataTestId={dataTestId}
          options={options}
          isSearchable={isSearchable}
          onOptionsSelected={props.onOptionsSelected}
          internalDropdown={internalDropdown}
          isLoading={isLoading}
          error={error}
          onRetry={onRetry}
        />
      ) : (
        <SingleSelectList
          value={props.value}
          options={options}
          dataTestId={dataTestId}
          headerTitle={headerTitle}
          isSearchable={isSearchable}
          onOptionSelected={props.onOptionSelected}
          internalDropdown={internalDropdown}
          isLoading={isLoading}
          error={error}
          onRetry={onRetry}
        />
      )}
    </ListMenu>
  );
};

export default DropdownList;
