import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SelectedOnboardingStep } from "./types";

interface StateSlice {
  selectedOnboardingStep: SelectedOnboardingStep;
}
const initialState: StateSlice = {
  selectedOnboardingStep: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {
    setOnboardingStep(state, action: PayloadAction<SelectedOnboardingStep>) {
      state.selectedOnboardingStep = action.payload;
    },
  },
});

export default onboardingSlice.reducer;

export const { setOnboardingStep } = onboardingSlice.actions;
