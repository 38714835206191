/* eslint-disable sonarjs/no-duplicate-string */
import classNames from "classnames";
import { Icon, Stack } from "@csis.com/components";
import {
  GOOD_PASSWORD_STRENGTH,
  GREAT_PASSWORD_STRENGTH,
  VERY_WEAK_PASSWORD_STRENGTH,
  WEAK_PASSWORD_STRENGTH,
} from "../constants";

const PasswordStrengthIndicator = ({ strength = 0 }: { strength: number }) => {
  const barClasses = classNames("password-strength-indicator__bar", {
    "password-strength-indicator__bar--red":
      strength <= VERY_WEAK_PASSWORD_STRENGTH,
    "password-strength-indicator__bar--orange":
      strength > VERY_WEAK_PASSWORD_STRENGTH &&
      strength <= WEAK_PASSWORD_STRENGTH,
    "password-strength-indicator__bar--green":
      strength > WEAK_PASSWORD_STRENGTH,
  });

  const textClasses = classNames("password-strength-indicator__text", {
    "password-strength-indicator__text--red":
      strength <= VERY_WEAK_PASSWORD_STRENGTH,
    "password-strength-indicator__text--orange":
      strength > VERY_WEAK_PASSWORD_STRENGTH &&
      strength <= WEAK_PASSWORD_STRENGTH,
    "password-strength-indicator__text--green":
      strength > WEAK_PASSWORD_STRENGTH,
  });

  let strengthText: string | undefined;
  if (strength === 0) {
    strengthText = undefined;
  } else if (strength <= VERY_WEAK_PASSWORD_STRENGTH) {
    strengthText = "Very weak";
  } else if (strength <= WEAK_PASSWORD_STRENGTH) {
    strengthText = "Weak";
  } else if (strength <= GOOD_PASSWORD_STRENGTH) {
    strengthText = "Good";
  } else if (strength === GREAT_PASSWORD_STRENGTH) {
    strengthText = "Great";
  }

  return (
    <div className="password-strength-indicator">
      <Stack isExpanded isVertical>
        <div className={barClasses}>
          <div
            className={
              strength >= VERY_WEAK_PASSWORD_STRENGTH
                ? "password-strength-indicator__quarter--solid"
                : "password-strength-indicator__quarter"
            }
          />
          <div
            className={
              strength >= WEAK_PASSWORD_STRENGTH
                ? "password-strength-indicator__quarter--solid"
                : "password-strength-indicator__quarter"
            }
          />
          <div
            className={
              strength >= GOOD_PASSWORD_STRENGTH
                ? "password-strength-indicator__quarter--solid"
                : "password-strength-indicator__quarter"
            }
          />
          <div
            className={
              strength >= GREAT_PASSWORD_STRENGTH
                ? "password-strength-indicator__quarter--solid"
                : "password-strength-indicator__quarter"
            }
          />
        </div>
        {strengthText && (
          <div className={textClasses}>
            <Stack align="center" gutterSize="small">
              <div className="f_small">{strengthText}</div>
              <Icon
                size="big"
                color="secondary"
                kind="alert_circle"
                tooltipText="The password must be at least 8 characters long and must contain characters from at least three of the following sets: upper case letters (A-Z), lower case letters (a-z), numbers (0-9) and special characters (everything else)."
              />
            </Stack>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default PasswordStrengthIndicator;
