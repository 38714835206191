import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  PortalsFrontendApiStraydataDepredatedAppDetailResponseBulkReportUpdateResponse as DetailResponseBulkReportUpdateResponse,
  PortalsFrontendApiStraydataDepredatedAppVictimPageResponse as VictimPageResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { LatestSearches } from "@csis.com/tip/src/components/shared/SearchComboInput/AdvancedSearchCard/types";
import { PredefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";
import {
  getUserPreferences,
  getUserPreferencesForStrayDataCredentials,
} from "@csis.com/tip/src/userPreferences/selectors";
import {
  updateUserPreferences,
  fetchSuccess as userPreferencesFetchSuccess,
} from "@csis.com/tip/src/userPreferences/slice";
import {
  SearchPagePreferences,
  UserPreferences,
} from "@csis.com/tip/src/userPreferences/types";
import {
  generateColumnsBasedOnSettings,
  generateColumnsOrderSetting,
  generateColumnsVisibilitySetting,
} from "@csis.com/tip/src/userPreferences/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { Status } from "../models/Status";
import { VictimsColumns, columns } from "./Table/columns";
import {
  exportVictimsInfoCsvApi,
  fetchVictimsInfoApi,
  patchBulkStatusApi,
} from "./api/api";
import { getCompromisedDataColumns } from "./selectors";
import { QueryParams, VictimsInfoRow } from "./types";

interface StateSlice {
  compromisedData: VictimsInfoRow[];
  hasNextPage: boolean;
  isCompromisedDataPending: boolean;
  compromisedDataFetchError: string | null;
  columns: VictimsColumns;

  isCsvExportPending: boolean;

  isBulkUpdatePending: boolean;
  bulkUpdateSuccess: string | null;
  bulkUpdateError: string | null;
}
const initialState: StateSlice = {
  compromisedData: [],
  hasNextPage: false,
  isCompromisedDataPending: false,
  compromisedDataFetchError: null,
  columns: columns,

  isCsvExportPending: false,

  isBulkUpdatePending: false,
  bulkUpdateSuccess: null,
  bulkUpdateError: null,
};

const victimsInfoSlice = createSlice({
  name: "compromisedDataVictimsInfo",
  initialState: initialState,
  reducers: {
    fetchCompromisedData(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setCompromisedDataPending(state) {
      state.isCompromisedDataPending = true;
      state.compromisedDataFetchError = null;
      state.compromisedData = [];
    },
    setFetchCompromisedDataError(state, action: PayloadAction<string>) {
      state.isCompromisedDataPending = false;
      state.compromisedDataFetchError = action.payload;
    },
    fetchCompromisedDataSuccess(
      state,
      action: PayloadAction<VictimsInfoRow[]>
    ) {
      state.isCompromisedDataPending = false;
      state.compromisedDataFetchError = null;
      state.compromisedData = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reorderColumns(state, action: PayloadAction<VictimsColumns>) {
      state.columns = action.payload;
    },
    setResultsPerPageUserPreference(
      _state,
      _action: PayloadAction<PredefinedResultsPerPage>
    ) {
      //empty handled by saga
    },

    exportCompromisedDataVictimsInfoCsv(
      _state,
      _action: PayloadAction<Partial<QueryParams>>
    ) {
      //empty handled by saga
    },
    setCsvExportPending(state) {
      state.isCsvExportPending = true;
    },
    setCsvExportComplete(state) {
      state.isCsvExportPending = false;
    },

    bulkUpdateStatus(
      _state,
      _action: PayloadAction<{ ids: string[]; status: Status }>
    ) {
      //empty handled by saga
    },
    setBulkUpdatePending(state) {
      state.bulkUpdateSuccess = null;
      state.bulkUpdateError = null;
      state.isBulkUpdatePending = true;
    },
    setBulkUpdateError(state, action: PayloadAction<string>) {
      state.bulkUpdateSuccess = null;
      state.bulkUpdateError = action.payload;
      state.isBulkUpdatePending = false;
    },
    setBulkUpdateSuccess(state, action: PayloadAction<string>) {
      state.bulkUpdateSuccess = action.payload;
      state.bulkUpdateError = null;
      state.isBulkUpdatePending = false;
    },
    resetBulkUpdateState(state) {
      state.bulkUpdateSuccess = null;
      state.bulkUpdateError = null;
      state.isBulkUpdatePending = false;
    },
    setLatestSearchesUserPreference(
      _state,
      _action: PayloadAction<LatestSearches>
    ) {
      //empty handled by saga
    },
  },
});

export default victimsInfoSlice.reducer;

export const {
  fetchCompromisedData,
  setCompromisedDataPending,
  setFetchCompromisedDataError,
  fetchCompromisedDataSuccess,
  setHasNextPage,
  reorderColumns,
  setResultsPerPageUserPreference,
  setLatestSearchesUserPreference,

  exportCompromisedDataVictimsInfoCsv,
  setCsvExportPending,
  setCsvExportComplete,

  bulkUpdateStatus,
  setBulkUpdatePending,
  setBulkUpdateError,
  setBulkUpdateSuccess,
  resetBulkUpdateState,
} = victimsInfoSlice.actions;

// Async stuff - sagas

function* fetchCompromisedDataSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setCompromisedDataPending());

  try {
    const response: AxiosResponse<VictimPageResponse> = yield call(
      fetchVictimsInfoApi,
      action.payload
    );
    yield put(setHasNextPage(response.data.payload.has_next));
    yield put(fetchCompromisedDataSuccess(response.data.payload.page));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchCompromisedDataError(errorMessage));
  }
}
function* exportCompromisedDataVictimsInfoCsvSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setCsvExportPending());

  try {
    const response: AxiosResponse<Blob> = yield call(
      exportVictimsInfoCsvApi,
      action.payload
    );

    const blob = response.data;
    downloadBlobForUser(blob, "report_csv.csv");

    yield put(setCsvExportComplete());
  } catch (e) {
    yield put(setCsvExportComplete());
  }
}

function* bulkUpdateStatusSaga(
  action: PayloadAction<{ ids: string[]; status: Status }>
) {
  yield put(setBulkUpdatePending());
  try {
    const response: AxiosResponse<DetailResponseBulkReportUpdateResponse> =
      yield call(patchBulkStatusApi, action.payload);
    const successMessage = response.data.payload.updated_rows + " rows updated";

    yield put(setBulkUpdateSuccess(successMessage));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setBulkUpdateError(errorMessage));
  }
}

function* reactToColumnsUpdateAndUpdateUserPreferencesSaga(
  action: PayloadAction<VictimsColumns>
) {
  // the user updated the columns so, we "update"
  // the prefs server side on the background
  const newColumns = action.payload;
  const newColumnsOrderSettings = generateColumnsOrderSetting(newColumns);
  const newColumnsVisibilitySettings =
    generateColumnsVisibilitySetting(newColumns);
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = {
      ...userPrefs,
      straydata_credentials: {
        ...userPrefs?.straydata_credentials,
        columnsVisibility: newColumnsVisibilitySettings,
        columnsOrder: newColumnsOrderSettings,
      },
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* reactToUserPreferencesResponse() {
  const userPrefs: SearchPagePreferences | undefined = yield select(
    getUserPreferencesForStrayDataCredentials
  );
  const columns: VictimsColumns = yield select(getCompromisedDataColumns);

  if (userPrefs && userPrefs.columnsOrder && userPrefs.columnsVisibility) {
    // generate columns based on the saved user preferences
    const columnsFromSettings = generateColumnsBasedOnSettings(
      userPrefs.columnsOrder,
      userPrefs.columnsVisibility,
      columns
    );
    yield put(reorderColumns(columnsFromSettings));
  }
}

// eslint-disable-next-line require-yield
function* setResultsPerPageUserPreferenceSaga(
  action: PayloadAction<PredefinedResultsPerPage>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.straydata_credentials = {
      ...newPrefs.straydata_credentials,
      resultsPerPage: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* setLatestSearchesUserPreferenceSaga(
  action: PayloadAction<LatestSearches>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.straydata_credentials = {
      ...newPrefs.straydata_credentials,
      latestSearches: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchCompromisedData.toString(), fetchCompromisedDataSaga);
  yield takeLatest(
    exportCompromisedDataVictimsInfoCsv.toString(),
    exportCompromisedDataVictimsInfoCsvSaga
  );
  yield takeLatest(bulkUpdateStatus.toString(), bulkUpdateStatusSaga);
  yield takeLatest(
    userPreferencesFetchSuccess.toString(),
    reactToUserPreferencesResponse
  );
  yield takeLatest(
    reorderColumns.toString(),
    reactToColumnsUpdateAndUpdateUserPreferencesSaga
  );
  yield takeLatest(
    setResultsPerPageUserPreference.toString(),
    setResultsPerPageUserPreferenceSaga
  );
  yield takeLatest(
    setLatestSearchesUserPreference.toString(),
    setLatestSearchesUserPreferenceSaga
  );
}

export function* compromisedDataVictimsInfoSagas() {
  yield all([actionWatcher()]);
}
