import classNames from "classnames";
import React from "react";

export interface GridItemInterface {
  children: React.ReactNode;
  span?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  dataTestId?: string;
  responsiveSize?: "mobile" | "small" | "large" | "huge";
}

const GridItem: React.FC<GridItemInterface> = ({
  children,
  span,
  responsiveSize,
  dataTestId,
}) => {
  const currentClass = "griditem";
  const spanClass = span ? `${currentClass}--span-${span}` : "";
  const responsiveClass = responsiveSize
    ? `${currentClass}--size-${responsiveSize}`
    : "";

  const classes = classNames(currentClass, spanClass, responsiveClass);

  return (
    <div className={classes} data-test-id={dataTestId}>
      {children}
    </div>
  );
};

export default GridItem;
