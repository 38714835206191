import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const auditLogKeys = {
  ACTIVITY: "activity",
  ACTIVITY_DESCRIPTION: "activity_description",
  ACTOR: "actor_name",
  ACTOR_IP: "actor_ip",
  EVENT: "event",
  TIMESTAMP: "event_timestamp",
  PLATFORM: "platform",
  EVENT_AFTER: "event_timestamp_after",
  EVENT_BEFORE: "event_timestamp_before",
  TARGET_NAME: "target_name",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [auditLogKeys.ACTOR, auditLogKeys.ACTIVITY] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.FIFTY;
