import React from "react";

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="csis-bar-chart__tooltip">
        <p className="csis-bar-chart__tooltip__title">{label}</p>
        {payload.reverse().map((element: any) => (
          <p
            key={element.name + "_" + element.value}
            className={
              "csis-bar-chart__tooltip__label " +
              (element.className
                ? "csis-bar-chart__tooltip__label--" + element.className
                : "")
            }
          >
            {element.name + ": " + element.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};
