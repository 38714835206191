import * as tableTypes from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { urlsKeys } from "../constants";
import { UrlsKey } from "../types";

export type UrlsColumns = tableTypes.ColumnInterface<UrlsKey, TranslationKey>[];

export const columns: UrlsColumns = [
  { key: urlsKeys.URL, name: "url", isSortable: true },
  { key: urlsKeys.CREATED, name: "created", isSortable: true },
  {
    key: urlsKeys.STATUS,
    name: "status",
    isCenterAligned: true,
    isSortable: true,
  },
  {
    key: urlsKeys.TARGETED_BRAND,
    name: "targeted_brand",
    isSortable: true,
  },

  // these 3 should go together:
  { key: urlsKeys.ASN, name: "hosting_provider" },
  { key: urlsKeys.IP, name: "ip_address" },
  { key: urlsKeys.COUNTRY, name: "country" },

  // extra date fields, default hidden
  { key: urlsKeys.FIRST_SEEN, name: "first_seen", isHidden: true },
  { key: urlsKeys.LAST_SEEN, name: "last_seen", isHidden: true },
  { key: urlsKeys.HASH, name: "hash", isHidden: true, isSortable: true },
  { key: urlsKeys.ORIGIN, name: "submitted_by", isHidden: true },
];
