import classNames from "classnames";
import React, { ReactNode } from "react";
import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { CSIS, GREY } from "../../colors";

export interface AccordionItemHeaderInterface {
  isItemOpen?: boolean;
  headerGutterSize?: "big" | "space-between";
  isTransparent?: boolean; // passed by the parent! do not pass manually
  headerColor?: CSIS | GREY; // passed by the parent! do not pass manually
  setIsItemOpen?: (isItemOpen: boolean) => void;
  sideContent?: ReactNode;
  dataTestId?: string;
  children: React.ReactNode;
}

const AccordionItemHeader: React.FC<AccordionItemHeaderInterface> = ({
  children,
  isItemOpen,
  headerGutterSize = "big",
  headerColor,
  setIsItemOpen,
  isTransparent,
  sideContent,
  dataTestId,
}) => {
  const classes = classNames("accordion__item__header", {
    "accordion__item__header--transparent": isTransparent,
  });
  const buttonClasses = classNames("accordion__item__header__button", {
    "accordion__item__header__button--has-sidecontent": Boolean(sideContent),
    "accordion__item__header__button--transparent": isTransparent,
    "accordion__item__header__button--grey": headerColor === "grey",
  });
  return (
    <div className={classes} data-test-id={dataTestId}>
      {headerGutterSize === "big" ? (
        <Stack justify="space-between" align="center">
          <button
            onClick={
              setIsItemOpen ? () => setIsItemOpen(!isItemOpen) : () => null
            }
            aria-expanded={isItemOpen ? true : false}
            className={buttonClasses}
            data-test-id={dataTestId + "-btn"}
          >
            <Stack gutterSize={"big"} align="center">
              <Stack>
                <div>{children}</div>
              </Stack>
              <Icon
                color={headerColor === "grey" ? "secondary" : "csis"}
                kind={isItemOpen ? "up-arrow" : "down-arrow"}
              />
            </Stack>
          </button>
          {sideContent && <>{sideContent}</>}
        </Stack>
      ) : (
        <button
          onClick={
            setIsItemOpen ? () => setIsItemOpen(!isItemOpen) : () => null
          }
          aria-expanded={isItemOpen ? true : false}
          className={buttonClasses}
        >
          <Stack justify={"space-between"} align="center">
            <Stack>
              <div>{children}</div>
            </Stack>
            <Stack gutterSize={"big"} align="center">
              <Icon kind={isItemOpen ? "up-arrow" : "down-arrow"} />
            </Stack>
          </Stack>
        </button>
      )}
    </div>
  );
};

export default AccordionItemHeader;
