import { FC } from "react";
import { CheckBox, Icon, Stack, Switch } from "@csis.com/components";
import { ThemePreference } from "@csis.com/tip/src/userPreferences/types";

function CompactSwitch({
  switchName,
  showIcons = true,
  onToggleTheme,
  prefersDarkTheme,
}: {
  switchName: string;
  themePreference: ThemePreference | undefined;
  displaySystemToggle?: boolean;
  showIcons?: boolean;
  onToggleTheme: () => void;
  prefersDarkTheme: boolean;
}) {
  return (
    <div className="dark__theme__switch__container">
      {showIcons && !prefersDarkTheme && (
        <div
          className="dark__theme__switch dark__theme__switch__item--sun"
          onClick={onToggleTheme}
        >
          <Icon size="normal" kind="light_mode" />
        </div>
      )}
      <Switch
        name={`${switchName}-theme-switch`}
        isOn={prefersDarkTheme}
        onToggle={onToggleTheme}
        dataTestId={`${switchName}-dark-theme-switch`}
        tooltipText={
          prefersDarkTheme ? "Switch to light theme" : "Switch to dark theme"
        }
      />
      {showIcons && prefersDarkTheme && (
        <div
          className="dark__theme__switch dark__theme__switch__item--moon"
          onClick={onToggleTheme}
        >
          <Icon size="normal" kind="dark_mode" />
        </div>
      )}
    </div>
  );
}

interface DarkThemeSwitchInterface {
  switchName: string;
  themePreference: ThemePreference | undefined;
  displaySystemToggle?: boolean;
  showIcons?: boolean;
  onToggleTheme: () => void;
  isInline?: boolean;
  onToggleSystemTheme?: () => void;
}
export const DarkThemeSwitch: FC<DarkThemeSwitchInterface> = ({
  switchName,
  themePreference,
  displaySystemToggle = false,
  showIcons = true,
  onToggleTheme,
  isInline,
  onToggleSystemTheme,
}) => {
  const prefersDarkTheme = themePreference === "dark";

  return isInline ? (
    <Stack gutterSize="big">
      <Stack align="end">
        <CompactSwitch
          switchName={switchName}
          onToggleTheme={onToggleTheme}
          showIcons={showIcons}
          prefersDarkTheme={prefersDarkTheme}
          themePreference={themePreference}
        />
      </Stack>
      {displaySystemToggle && (
        <Stack align="center" gutterSize="small">
          <CheckBox
            name={`${switchName}-system-theme-checkbox`}
            title="System"
            isChecked={themePreference === "system"}
            onClick={onToggleSystemTheme}
          />
          <Icon
            color="secondary"
            kind="help_filled"
            tooltipText="Theme will match your system active settings"
          />
        </Stack>
      )}
    </Stack>
  ) : (
    <>
      <Stack align="end">
        <CompactSwitch
          switchName={switchName}
          onToggleTheme={onToggleTheme}
          showIcons={showIcons}
          prefersDarkTheme={prefersDarkTheme}
          themePreference={themePreference}
        />
      </Stack>
      {displaySystemToggle && (
        <Stack align="center" gutterSize="small">
          <CheckBox
            name={`${switchName}-system-theme-checkbox`}
            title="System"
            isChecked={themePreference === "system"}
            onClick={onToggleSystemTheme}
          />
          <Icon
            color="secondary"
            kind="help_filled"
            tooltipText="Theme will match your system active settings"
          />
        </Stack>
      )}
    </>
  );
};
