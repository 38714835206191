import classNames from "classnames";
import React from "react";
import { Color, YELLOW } from "../../colors";
import { NORMAL, SMALL } from "../../sizes";

export interface TagInterface {
  isUppercase?: boolean;
  isCircle?: boolean;
  size?: SMALL | NORMAL;
  tooltipText?: string;
  color: Color | YELLOW;
  onClick?: () => void;
  dataTestId?: string;
  children: React.ReactNode;
}

const Tag: React.FC<TagInterface> = ({
  isUppercase,
  color,
  size = "normal",
  tooltipText,
  isCircle,
  onClick,
  children,
  dataTestId,
}) => {
  const classes = classNames("tag", {
    "tag--red": color === "red",
    "tag--csis": color === "csis",
    "tag--blue": color === "blue",
    "tag--green": color === "green",
    "tag--yellow": color === "yellow",
    "tag--orange": color === "orange",
    "tag--grey": color === "grey",
    "tag--uppercase": isUppercase,
    "tag--circle": isCircle,
    "tag--clickable": Boolean(onClick),
    "tag--small": size === "small",
  });

  return (
    <div
      onClick={onClick}
      className={classes}
      data-test-id={dataTestId}
      title={tooltipText}
    >
      <span>{children}</span>
    </div>
  );
};

export default Tag;
