import { DropdownOption } from "../Dropdown/Dropdown";

export const timePeriods = {
  TODAY: 1,
  LAST_7_DAYS: 2,
  PREVIOUS_WEEK: 10, // Last completed calendar week starting monday
  LAST_14_DAYS: 8,
  LAST_30_DAYS: 3,
  PREVIOUS_MONTH: 11, // Last completed calendar month
  LAST_90_DAYS: 9,
  PREVIOUS_QUARTER: 12, // Last completed calendar quarter
  LAST_365_DAYS: 4,
  YTD: 7,
  ALL_TIME: 6,
  CUSTOM: 5,
} as const;

export type TimePeriod = typeof timePeriods[keyof typeof timePeriods];

export const isTimePeriod = (x: any): x is TimePeriod =>
  Object.values(timePeriods).includes(x);

export type DateRange = [Date, Date];

export type DatePickerOption = DropdownOption<TimePeriod>;
