import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { all, put, select, takeLatest } from "redux-saga/effects";
import { isNullOrUndefined } from "@csis.com/components/src/utils/utils";
import {
  getCookieValue,
  setCookieWithExpiration,
} from "@csis.com/tip/src/pages/Login/utils";
import { defaultPreferences } from "./defaultPreferences";
import { getUserPreferences } from "./selectors";
import { Localization, ThemePreference, UserPreferences } from "./types";

interface StateSlice {
  userPreferences: UserPreferences | null;
  isPending: boolean;
  fetchError: string | null;
}
const initialState: StateSlice = {
  userPreferences: null,
  isPending: false,
  fetchError: null,
};

const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState: initialState,
  reducers: {
    fetchUserPreferences() {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<UserPreferences>) {
      state.isPending = false;
      state.userPreferences = action.payload;
      state.fetchError = null;
    },
    setUserPreferences(state, action: PayloadAction<UserPreferences>) {
      state.isPending = false;
      state.userPreferences = action.payload;
    },

    updateUserPreferences(_state, _action: PayloadAction<UserPreferences>) {
      //empty handled by saga
    },

    changeTheme(_state, _action: PayloadAction<ThemePreference>) {},
    changeHighContrast(_state, _action: PayloadAction<boolean>) {},
    changeLocalization(_state, _action: PayloadAction<Localization>) {},
  },
});

export default userPreferencesSlice.reducer;

export const {
  fetchUserPreferences,
  setPending,
  setFetchError,
  fetchSuccess,
  setUserPreferences,

  updateUserPreferences,

  changeTheme,
  changeHighContrast,
  changeLocalization,
} = userPreferencesSlice.actions;

function* fetchUserPreferencesSaga() {
  yield put(setPending());

  try {
    // this will be received from a server request
    const responseFromCookie = getCookieValue("userPreferences");

    if (isNullOrUndefined(responseFromCookie) || responseFromCookie === "") {
      yield put(fetchSuccess(defaultPreferences));
      yield put(updateUserPreferences(defaultPreferences));
    } else {
      const userPrefs = JSON.parse(responseFromCookie) as unknown;
      yield put(fetchSuccess(userPrefs as UserPreferences));
    }
  } catch (e) {}
}

function* updateUserPreferencesSaga(action: PayloadAction<UserPreferences>) {
  try {
    const HALF_YEAR_IN_SECONDS = 15_768_000;

    setCookieWithExpiration(
      "userPreferences",
      JSON.stringify(action.payload),
      HALF_YEAR_IN_SECONDS
    );
    // also update the "local" object so its up to date
    yield put(setUserPreferences(action.payload as UserPreferences));
  } catch (e) {}
}

function* changeThemeSaga(action: PayloadAction<ThemePreference>) {
  const userPreferences: UserPreferences = yield select(getUserPreferences);

  if (userPreferences) {
    const newPrefs = { ...userPreferences };
    newPrefs.general = { ...newPrefs.general, themePreference: action.payload };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* changeHighContrastSaga(action: PayloadAction<boolean>) {
  const userPreferences: UserPreferences = yield select(getUserPreferences);

  if (userPreferences) {
    const newPrefs = { ...userPreferences };
    newPrefs.general = {
      ...newPrefs.general,
      prefersHighContrast: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* changeLocalizationSaga(action: PayloadAction<Localization>) {
  const localization = action.payload;
  const userPreferences: UserPreferences = yield select(getUserPreferences);

  if (userPreferences) {
    const newPrefs = { ...userPreferences };
    newPrefs.localization = localization;

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchUserPreferences.toString(), fetchUserPreferencesSaga);
  yield takeLatest(updateUserPreferences.toString(), updateUserPreferencesSaga);
  yield takeLatest(changeTheme.toString(), changeThemeSaga);
  yield takeLatest(changeHighContrast.toString(), changeHighContrastSaga);
  yield takeLatest(changeLocalization.toString(), changeLocalizationSaga);
}

export function* userPreferencesSagas() {
  yield all([actionWatcher()]);
}
