import { ColumnInterface } from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { LatestSearches } from "../components/shared/SearchComboInput/AdvancedSearchCard/types";
import { MAX_SAVED_LATEST_SEARCHES } from "./constants";
import { ColumnsOrderPref, ColumnsVisibilityPref } from "./types";

// given columns, we generate user settings entry for columns order
export function generateColumnsOrderSetting<T extends string>(
  columns: ColumnInterface<T, TranslationKey>[]
): ColumnsOrderPref {
  return columns.map((col) => col.key);
}

// given columns, we generate user preferences entry for columns visibility
export function generateColumnsVisibilitySetting<T extends string>(
  columns: ColumnInterface<T, TranslationKey>[]
): ColumnsVisibilityPref {
  return columns.reduce(
    (obj, col) => ({ ...obj, [col.key]: Boolean(col.isHidden) }),
    {}
  );
}

// given columns preferences
// and columns, we generate an updated columns array
// with new order and visibility based on the preferences
export function generateColumnsBasedOnSettings<T extends string>(
  columnsOrderPref: ColumnsOrderPref,
  columnsVisibilityPref: ColumnsVisibilityPref,
  columns: ColumnInterface<T, TranslationKey>[]
): ColumnInterface<T, TranslationKey>[] {
  const updatedColumns = columns.map((col) => {
    return {
      ...col,
      isHidden: columnsVisibilityPref.hasOwnProperty(col.key)
        ? Boolean(columnsVisibilityPref[col.key])
        : Boolean(col.isHidden),
    };
  });

  updatedColumns.sort(function (a, b) {
    return columnsOrderPref.indexOf(a.key) - columnsOrderPref.indexOf(b.key);
  });

  return updatedColumns;
}

// add an item to the latest searches array
// if its not larger than the limit
// then remove the oldest item
export const pushLatestSearch = (
  latestSearches: LatestSearches,
  searchString: string
) => {
  if (latestSearches.length + 1 > MAX_SAVED_LATEST_SEARCHES) {
    return [...latestSearches.slice(1), searchString];
  }
  return [...latestSearches, searchString];
};

export const removeLatestSearchItem = (
  latestSearches: LatestSearches,
  value: string
) => {
  return latestSearches.filter((v) => v !== value);
};
