import { Dropdown } from "@csis.com/components";
import { Organization } from "./types";

const OrgsDropdown = ({
  organizations,
  selectedOrgId,
  onSelectedOrgChange,
}: {
  organizations?: Organization[];
  selectedOrgId?: string;
  onSelectedOrgChange?: (orgId: string) => void;
}) => {
  return organizations && organizations.length > 1 ? (
    <div className="sidebar__org-dropdown">
      <Dropdown
        headerTitle="Select Organization"
        name="org-select-dropdown"
        size="small"
        color="grey"
        type="primary"
        isFullWidth
        value={selectedOrgId}
        options={organizations.map((org) => ({
          label: org.display_name,
          value: org.external_id,
        }))}
        onSelect={onSelectedOrgChange}
        dataTestId="sidebar__org-dropdown"
      />
    </div>
  ) : null;
};

export default OrgsDropdown;
