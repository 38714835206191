import { QuestionData, QuestionnaireData } from "./types";

export function getTotalAnsweredQuestions(questions: QuestionData[]) {
  return questions.filter((q) => q.answer && q.answer.length > 0).length;
}

export function calculatePercentageOfAnsweredQuestions(
  questionnaire: QuestionnaireData
) {
  const questions = questionnaire.questions;
  const sections = questionnaire.sections;

  const totalQuestions =
    (questions && questions?.length ? questions.length : 0) +
    (sections && Object.values(sections)?.flatMap.length
      ? Object.values(sections)?.flatMap((s) => s.questions).length
      : 0);

  const totalAnsweredQuestions =
    (questionnaire.questions && questionnaire.questions?.length
      ? getTotalAnsweredQuestions(questionnaire.questions)
      : 0) +
    (sections && Object.values(sections)?.length > 0
      ? Object.values(sections).reduce(
          (accumulator, s) =>
            accumulator + getTotalAnsweredQuestions(s.questions),
          0
        )
      : 0);

  if (totalQuestions > 0) {
    return Math.ceil((totalAnsweredQuestions / totalQuestions) * 100);
  }
  return 0;
}
