import { OpenAPIV3 } from "openapi-types";
import { FC, Fragment } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
  CodeBlock,
  Divider,
  Stack,
} from "@csis.com/components";
import {
  EndpointSection,
  EndpointSectionContent,
  EndpointSectionHeader,
} from "../EndpointSection/EndpointSection";
import {
  ParamList,
  ParamListTerm,
  ParamListTermDetails,
} from "../ParamList/ParamList";
import { Subject } from "../Subject/Subject";
import { GainingAccessSection } from "./GainingAccessSection";
import { RequestBodyExample } from "./RequestSection";
import { ResponseCodeObject } from "./ResponseSection";
import {
  DocumentationSection,
  isReferenceObject,
  isSchemaObject,
} from "./types";

interface SectionInterface {
  title: string;
  titleId: string;
  sectionData?: DocumentationSection;
  children?: React.ReactNode;
}

export const Section: FC<SectionInterface> = ({
  title,
  titleId,
  children,
  sectionData,
}) => {
  return (
    <Stack isVertical gutterSize="big" align="stretch">
      <h2 id={titleId} className="f_huge f_semibold f_csis">
        {title}
      </h2>
      {sectionData ? (
        <GeneratedSubjects titleId={titleId} sectionData={sectionData} />
      ) : (
        children
      )}
    </Stack>
  );
};

const GeneratedSubjects: FC<{
  titleId: string;
  sectionData: DocumentationSection;
}> = ({ titleId, sectionData }) => {
  if (!sectionData) return <p>no data</p>;

  const baseUrl = process.env.REACT_APP_CSIS_PORTALS_API;

  const uppercaseTitle = (tag: string) => {
    return tag
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(", ");
  };

  return (
    <>
      <GainingAccessSection />
      {sectionData &&
        sectionData.paths &&
        Array.from(sectionData.tags).map((tag) => (
          <Subject
            id={`${titleId}-${tag}`}
            title={uppercaseTitle(tag)}
            key={tag}
          >
            <EndpointSection>
              {sectionData.paths.map((path) => (
                <Fragment key={path.endpoint}>
                  {path.operations.map((operation) => {
                    if (operation.tags?.join("-") !== tag) return null;
                    const permissions =
                      operation.security?.[0]?.["HTTPOAuth2Bearer"];

                    return (
                      <Fragment key={operation.operationId}>
                        <EndpointSectionHeader
                          endpoint={path.endpoint.replace(/^\//, "")}
                          method={operation.method}
                          title={operation.summary}
                          titleId={operation.operationId}
                          baseUrl={baseUrl}
                        />
                        <EndpointSectionContent>
                          <p>
                            {operation.description ||
                              "No description for this endpoint"}
                          </p>

                          {operation.security && (
                            <Stack>
                              <div className="f_semibold">
                                OAuth 2.0 scope(s):
                              </div>
                              <ul>
                                {permissions?.map((entry, index) => (
                                  <li key={index}>
                                    <CodeBlock text={entry} />
                                  </li>
                                ))}
                              </ul>
                            </Stack>
                          )}

                          {operation.parameters && (
                            <GeneratedParamList
                              operationParameters={operation.parameters}
                            />
                          )}

                          {operation.requestBody && (
                            <RequestBodyExample
                              requestBody={operation.requestBody}
                            />
                          )}

                          {operation.responses && (
                            <PortalsAPICodeExample
                              responses={operation.responses}
                            />
                          )}
                        </EndpointSectionContent>
                        <Divider />
                      </Fragment>
                    );
                  })}
                </Fragment>
              ))}
            </EndpointSection>
          </Subject>
        ))}
    </>
  );
};

const GeneratedParamList: FC<{
  operationParameters?: (
    | OpenAPIV3.ReferenceObject
    | OpenAPIV3.ParameterObject
  )[];
}> = ({ operationParameters }) => {
  if (!operationParameters) return <p>No parameters for this operation</p>;

  const paramListItem = (parameterObject: OpenAPIV3.ParameterObject) => {
    const schemaObject =
      parameterObject.schema as OpenAPIV3.SchemaObject | null;

    if (!schemaObject) return null;

    const parameterSchemaType = isSchemaObject(schemaObject)
      ? schemaObject.type
      : undefined;

    return (
      <>
        <ParamListTerm
          term={parameterObject.name}
          isOptional={!parameterObject.required}
          types={parameterSchemaType ? [parameterSchemaType] : undefined}
          paramLocation={parameterObject.in}
        />
        <ParamListTermDetails>
          <p>
            {parameterObject.description ? parameterObject.description : null}
          </p>
        </ParamListTermDetails>
      </>
    );
  };

  return (
    <ParamList>
      {operationParameters?.map((parameter, index) => {
        return (
          <Fragment key={index}>
            {paramListItem(parameter as OpenAPIV3.ParameterObject)}
          </Fragment>
        );
      })}
    </ParamList>
  );
};

// The following components are for the response code examples
const PortalsAPICodeExample: FC<{
  responses: OpenAPIV3.ResponsesObject | undefined;
}> = ({ responses }) => {
  return (
    <Accordion headerGutterSize="space-between" headerColor="grey">
      <AccordionItem>
        <AccordionItemHeader>
          <span className="f_normal">Responses</span>
        </AccordionItemHeader>
        <AccordionItemBody>
          <Stack isVertical align="stretch">
            {responses &&
              Object.entries(responses).map(([key, response]) => {
                if (isReferenceObject(response)) {
                  return null;
                }
                return (
                  <ResponseCodeObject
                    key={key}
                    response={response}
                    code={key}
                  />
                );
              })}
          </Stack>
        </AccordionItemBody>
      </AccordionItem>
    </Accordion>
  );
};
