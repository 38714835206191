import classNames from "classnames";
import React from "react";
import { BLUE, GREY, RED } from "../../../colors";
import { SMALL } from "../../../sizes";
import IconButton from "../../IconButton/IconButton";
import ListMenu from "../../ListMenu/ListMenu";
import ListMenuItem from "../../ListMenu/ListMenuItem";
import { renderModal } from "../../ListMenu/renderModal";
import { useClickOutside } from "../../helpers/useClickOutside";
import { getSplitIconButtonSpacing, getSplitIconSize } from "./utils";

export type SecondaryAction = {
  text: string;
  onClick: () => void;
  dataTestId?: string;
};

const SplitButton: React.FC<{
  button: React.ReactElement;
  size?: SMALL;
  color?: BLUE | RED | GREY;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  secondaryActions: SecondaryAction[];
  opensFromTop?: boolean;
  opensFromRight?: boolean;
  name: string;
  dataTestId?: string;
}> = ({
  size,
  color,
  button,
  secondaryActions,
  isFullWidth,
  isDisabled,
  isLoading,
  opensFromTop,
  opensFromRight,
  name,
  dataTestId,
}) => {
  const [isComponentVisible, setIsComponentVisible, ref, modalRef] =
    useClickOutside();

  const classes = classNames("splitbutton", {
    "splitbutton--full-width": isFullWidth,
    "splitbutton--loading": isLoading,
  });

  return (
    <div className={classes} ref={ref}>
      <div className="splitbutton__header">
        {button}
        <IconButton
          ariaHasPopup={"menu"}
          icon={isComponentVisible ? "up-arrow" : "down-arrow"}
          aria-expanded={isComponentVisible ? true : false}
          color={color}
          tooltipText={"Click for more actions"}
          size={getSplitIconSize(size)}
          spacing={getSplitIconButtonSpacing(size)}
          type="primary"
          isDisabled={isDisabled}
          onButtonClick={() => setIsComponentVisible(!isComponentVisible)}
          dataTestId={dataTestId + "-show-secondary-actions-btn"}
        />
      </div>
      <div>
        {isComponentVisible &&
          renderModal(
            <ListMenu
              parentName={name}
              opensFromRight={opensFromRight}
              opensFromTop={opensFromTop}
              size={size}
              parentHeight={ref.current?.clientHeight}
              dataTestId={dataTestId + "-listmenu"}
            >
              {secondaryActions.map((secondaryAction) => {
                return (
                  <ListMenuItem
                    onClick={secondaryAction.onClick}
                    key={secondaryAction.text}
                    dataTestId={secondaryAction.dataTestId}
                    isSemibold
                  >
                    {secondaryAction.text.toUpperCase()}
                  </ListMenuItem>
                );
              })}
            </ListMenu>,
            ref,
            modalRef,
            opensFromRight
          )}
      </div>
    </div>
  );
};

export default SplitButton;
