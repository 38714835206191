import {
  CartesianGrid,
  Line,
  LineChart as LineChartRechart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import ChartError from "../ChartError/ChartError";
import NoData from "../NoData/NoData";
import * as constants from "../constants";
import { ChartColor } from "../types";
import { getChartColor } from "../utils";

export type LineChartData = {
  [key: string]: number | string;
}[];

export interface LineChartInterface {
  data: LineChartData;
  xAxisKey: string;
  yAxisKeys: {
    key: string;
    name?: string;
    color?: ChartColor;
  }[];
  isAnimationActive?: boolean;
  isLoading?: boolean;
  error?: string | null;
}

const LineChart: React.FC<LineChartInterface> = ({
  data,
  xAxisKey,
  yAxisKeys,
  isAnimationActive = true,
  isLoading,
  error,
}) => {
  if (isLoading) {
    return (
      <ResponsiveContainer>
        <Skeleton type="chart" />
      </ResponsiveContainer>
    );
  }

  if (error) {
    return <ChartError error={error} />;
  }

  return data && data.length ? (
    <ResponsiveContainer>
      <LineChartRechart className="csis-line-chart" data={data}>
        <XAxis
          dy={constants.axisMargin}
          tick={{
            fontSize: constants.fontSize,
            fontWeight: constants.fontWeight,
          }}
          dataKey={xAxisKey}
        />
        <YAxis
          dx={-constants.axisMargin}
          tick={{
            fontSize: constants.fontSize,
            fontWeight: constants.fontWeight,
          }}
          axisLine={false}
        />
        <CartesianGrid
          strokeWidth={constants.cartesianStrokeWidth}
          vertical={false}
        />
        {yAxisKeys.map((yAxisKey, index) => (
          <Line
            key={yAxisKey.key}
            isAnimationActive={isAnimationActive}
            type={constants.lineStyle}
            strokeWidth={constants.lineWidth}
            dataKey={yAxisKey.key}
            name={yAxisKey.name ? yAxisKey.name : yAxisKey.key}
            className={getChartColor(index, yAxisKey.color)}
            connectNulls={true}
          />
        ))}

        <Tooltip isAnimationActive={false} content={CustomTooltip} />
      </LineChartRechart>
    </ResponsiveContainer>
  ) : (
    <NoData />
  );
};

export default LineChart;
