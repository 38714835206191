import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { RetainerTypePreview } from "@csis.com/tip/src/api/openapi/data-contracts";
import { fetchRetainerTypesApi } from "./api/api";

interface StateSlice {
  retainerTypes: RetainerTypePreview[];
}
const initialState: StateSlice = {
  retainerTypes: [],
};

const retainerTypesSlice = createSlice({
  name: "retainerTypes",
  initialState: initialState,
  reducers: {
    fetchRetainerTypes() {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<RetainerTypePreview[]>) {
      state.retainerTypes = action.payload;
    },
  },
});

export default retainerTypesSlice.reducer;

export const { fetchRetainerTypes, fetchSuccess } = retainerTypesSlice.actions;

// Async stuff - sagas

function* fetchRetainerTypesSaga() {
  try {
    const response: AxiosResponse<any> = yield call(fetchRetainerTypesApi);
    const retainerTypes = response.data.payload;
    yield put(fetchSuccess(retainerTypes));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchRetainerTypes.toString(), fetchRetainerTypesSaga);
}

export function* retainersTypesSagas() {
  yield all([actionWatcher()]);
}
