import classNames from "classnames";
import React from "react";

const Divider: React.FC<{ extraClasses?: string }> = ({ extraClasses }) => {
  let classes = classNames("divider");
  classes = extraClasses ? classes + " " + extraClasses : classes;

  return <div className={classes} />;
};

export default Divider;
