import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StateSlice {
  isUpdatePending: boolean;
  isUpdateError: string | null;
  isUpdateSuccess: boolean;
}

export function createAsyncDataUpdateSlice<T>(name: string) {
  const initialState: StateSlice = {
    isUpdatePending: false,
    isUpdateError: null,
    isUpdateSuccess: false,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      updateData(_state, _action: PayloadAction<T>) {
        //empty handled by saga
      },
      setIsUpdatePending(state) {
        state.isUpdatePending = true;
        state.isUpdateError = null;
        state.isUpdateSuccess = false;
      },
      setIsUpdateError(state, action: PayloadAction<string | undefined>) {
        state.isUpdatePending = false;
        state.isUpdateError = action.payload || "Something went wrong";
        state.isUpdateSuccess = false;
      },
      setIsUpdateSuccess(state) {
        state.isUpdatePending = false;
        state.isUpdateSuccess = true;
        state.isUpdateError = null;
      },
      resetState(state) {
        state.isUpdatePending = false;
        state.isUpdateSuccess = false;
        state.isUpdateError = null;
      },
    },
  });
}
