import classNames from "classnames";
import React from "react";
import { RadiusSize } from "../../radius";

export interface CardInterface {
  color?: "alt" | "alt2";
  hasShadow?: boolean;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  radius?: RadiusSize;
  spacing?: "none" | "normal" | "big" | "large" | "huge";
  align?: "space-between";
  isHoverable?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  children: React.ReactNode;
}

const Card: React.FC<CardInterface> = ({
  children,
  hasShadow = true,
  align,
  isFullWidth,
  isFullHeight,
  spacing = "huge",
  radius = "big",
  isHoverable,
  dataTestId,
  color,
  onClick,
}) => {
  const classes = classNames("card", {
    "card--no-shadow": !hasShadow,
    // variations to fit the dark/light theme - especially inside dialogs when the colors are inversed
    // for light mode default color and alt2 are the same
    // for dark mode default color and alt are the same
    "card--alt": color === "alt",
    "card--alt-2": color === "alt2",
    "card--spacing-none": spacing === "none",
    "card--spacing-normal": spacing === "normal",
    "card--spacing-big": spacing === "big",
    "card--spacing-large": spacing === "large",
    "card--spacing-huge": spacing === "huge",
    "card--align-space-between": align === "space-between",
    "card--full-width": isFullWidth,
    "card--full-height": isFullHeight,
    "card--hoverable": isHoverable,
    "card--radius-big": radius === "big",
    "card--radius-normal": radius === "normal",
    "card--radius-small": radius === "small",
  });

  return (
    <div className={classes} onClick={onClick} data-test-id={dataTestId}>
      {children}
    </div>
  );
};
export default Card;
