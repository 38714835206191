import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { Flag, FlagTranslationKeys, isFlagName } from "../models/Flag/Flag";

// returns the time in seconds remaining in the 72hour deadline
// since the time the GDPR flag was created
export function getTimeUntilGdprExpires(createdTime: string) {
  const SEVENTY_TWO_HOURS_IN_SECONDS = 259200;

  const nowInMilliseconds = new Date().valueOf();
  const createdTimeInMilliseconds = new Date(createdTime).valueOf();

  const timeElapsedInSeconds = Math.floor(
    Math.abs(nowInMilliseconds - createdTimeInMilliseconds) / 1000
  );

  // never return negative numbers
  return SEVENTY_TWO_HOURS_IN_SECONDS - timeElapsedInSeconds > 0
    ? SEVENTY_TWO_HOURS_IN_SECONDS - timeElapsedInSeconds
    : 0;
}

export function getFlagName(
  flagName: string,
  t: (key: TranslationKey) => string,
  userSubscribedFlags: Flag[]
) {
  const name = isFlagName(flagName)
    ? t(FlagTranslationKeys[flagName])
    : userSubscribedFlags.find((fl) => fl.name === flagName)?.display_name;

  return name ? name : flagName;
}
