import { PeriodGrouping } from "@csis.com/tip/src/api/openapi/data-contracts";
import { calculateDiffBetweenTwoDatesInDays } from "@csis.com/tip/src/utils/dateCalculations";

// returns the grop_by param for statistics histogram endpoints
// less than 100 days group by -> day
// more than 100 days group by -> week
// undefined (all_time) group by -> month
export function calculateGroupByParamBasedOnDateRange(
  startDate?: string,
  endDate?: string
): PeriodGrouping {
  if (startDate && endDate) {
    return calculateDiffBetweenTwoDatesInDays(startDate, endDate) < 100
      ? "day"
      : "week";
  }
  return "month";
}
