import classNames from "classnames";
import React from "react";
import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { BIG, NORMAL, SMALL } from "../../sizes";
import { BreadcrumbInterface } from "./Breadcrumb";

export interface BreadcrumbsInterface {
  size?: SMALL | NORMAL | BIG;
  children: React.ReactNode;
}

const Breadcrumbs: React.FC<BreadcrumbsInterface> = ({ size, children }) => {
  const classes = classNames("breadcrumbs", {
    "breadcrumbs--small": size === "small",
    "breadcrumbs--normal": size === "normal",
    "breadcrumbs--big": size === "big",
  });

  return (
    <nav className={classes} aria-label="breadcrumb">
      <Stack align="center">
        {React.Children.map(children, (child, index) => {
          return (
            <Stack align="center">
              {index !== 0 && (
                <Icon
                  size={size === "small" ? "small" : "normal"}
                  kind="right-arrow"
                />
              )}
              {React.cloneElement(
                child as React.ReactElement<BreadcrumbInterface>,
                {
                  size: size,
                }
              )}
            </Stack>
          );
        })}
      </Stack>
    </nav>
  );
};

export default Breadcrumbs;
