import classNames from "classnames";
import React from "react";

export interface RadioButtonInterface {
  title: string;
  isSelected?: boolean;
  value?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonInterface> = ({
  title,
  isSelected = false,
  value,
  name,
  onChange,
}) => {
  const classes = classNames("radiobutton");

  return (
    <label tabIndex={0} className={classes}>
      <input
        onChange={onChange}
        name={name}
        checked={isSelected}
        type="radio"
        value={value}
        aria-checked={isSelected}
      />
      <div className="radiobutton__checkmark" />
      {title}
    </label>
  );
};

export default RadioButton;
