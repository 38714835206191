import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  GenericFormError,
  Grid,
  GridItem,
  InputPin,
  Stack,
} from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import {
  getEmergencyCaseInfoError,
  getIsEmergencyCaseInfoPending,
} from "../selectors";

interface EmergencyCaseConfirmationProps {
  onSuccess: (formDetails: { submissionCode: string }) => void;
}
export const EmergencyCaseConfirmation = ({
  onSuccess,
}: EmergencyCaseConfirmationProps) => {
  const { t } = useTranslations();

  const [formDetails, setFormDetails] = useState({
    submissionCode: "",
  });

  const [formError, setFormError] = useState<string | null>(null);

  const isEmergencyCaseInfoPending = useSelector(getIsEmergencyCaseInfoPending);
  const caseIdConfirmationError = useSelector(getEmergencyCaseInfoError);

  const checkForFormClientSideErrors = () => {
    let hasFormErrors = false;
    if (!formDetails.submissionCode) {
      hasFormErrors = true;
      setFormError("Please enter a submission code");
    }
    return hasFormErrors;
  };
  const handleInputChange = useCallback((value: string, name: string) => {
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleConfirmCaseId = () => {
    if (!checkForFormClientSideErrors()) {
      onSuccess(formDetails);
    }
  };

  return (
    <Card isFullHeight hasShadow={false} spacing="huge" color="alt">
      <div className="emergency-case-confirmation-page__confirmation">
        <Stack isExpanded isVertical gutterSize="gigantic" align="center">
          <div className="emergency-case-confirmation-page__confirmation__content">
            <Stack isVertical gutterSize="large">
              <Stack isVertical>
                <span className="emergency-case-confirmation-page__confirmation__title">
                  By proceeding further, you are acknowledging and accepting the
                  fees detailed below.
                </span>
                <span className="emergency-case-confirmation-page__confirmation__text">
                  For organizations with an active CSIS Emergency Response
                  Retainer, we encourage you to review your specific contractual
                  agreement. This will provide clarity on the fees applicable to
                  your organization, including any potential discounts, and any
                  exemptions on startup costs, and hourly rates.
                </span>
              </Stack>
              <Stack isVertical isExpanded>
                <span className="emergency-case-confirmation-page__confirmation__title">
                  All quoted fees are in DKK and exclude VAT, any fiscal
                  retentions and/or bank transfer fees.*
                </span>

                <Grid justify="space-between">
                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      Startup fee:
                    </span>
                  </GridItem>
                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      10.000
                    </span>
                  </GridItem>
                  <GridItem span={12}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      Hourly price per person, for hours logged between
                    </span>
                  </GridItem>

                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      08.30 - 16.30, Mon-Fri:
                    </span>
                  </GridItem>
                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      2.500
                    </span>
                  </GridItem>
                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      16.30 – 21.30, Mon-Fri:
                    </span>
                  </GridItem>
                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      3.750
                    </span>
                  </GridItem>
                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      21.30 – 08.30, weekends and/or public holidays:
                    </span>
                  </GridItem>
                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      5.000
                    </span>
                  </GridItem>
                </Grid>
              </Stack>

              <Stack isVertical isExpanded>
                <span className="emergency-case-confirmation-page__confirmation__title">
                  Forensic collections with Chronos. The unit price of Chronos
                  decreases with scale.*
                </span>
                <Grid justify="space-between">
                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      1-200:
                    </span>
                  </GridItem>

                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      880 per collection
                    </span>
                  </GridItem>

                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      201-1000:
                    </span>
                  </GridItem>
                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      660 per collection
                    </span>
                  </GridItem>

                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      1001-5000:
                    </span>
                  </GridItem>
                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      440 per collection
                    </span>
                  </GridItem>
                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      5001-10.000:
                    </span>
                  </GridItem>
                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      220 per collection
                    </span>
                  </GridItem>

                  <GridItem span={9}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      Any additional collections:
                    </span>
                  </GridItem>

                  <GridItem span={3}>
                    <span className="emergency-case-confirmation-page__confirmation__text">
                      Custom
                    </span>
                  </GridItem>
                </Grid>
              </Stack>
              <span className="emergency-case-confirmation-page__confirmation__text">
                *Please note that the quoted fees are intended for companies
                that do not currently have an Emergency Incident Response
                Retainer with CSIS Security Group A/S
              </span>
            </Stack>
          </div>

          <Form id={"confirm-case-id-form"} onFormSubmit={handleConfirmCaseId}>
            <Stack gutterSize="gigantic" align="center" isVertical>
              <Stack isVertical justify="center" align="center">
                <Stack gutterSize="gigantic" align="center">
                  <span className="emergency-case-confirmation-page__confirmation__label">
                    {t("case_id")}:
                  </span>
                  <InputPin
                    name="submissionCode"
                    value={formDetails.submissionCode}
                    onChange={handleInputChange}
                    pinLength={4}
                    iconColor="grey"
                  />
                </Stack>
                {formError && <GenericFormError errorText={formError} />}
                {caseIdConfirmationError && (
                  <GenericFormError errorText={caseIdConfirmationError} />
                )}
              </Stack>
              <Grid justify="center">
                <GridItem span={9}>
                  <Button
                    formId="confirm-case-id-form"
                    text={"CONFIRM CASE START"}
                    dataTestId="submit-button"
                    isLoading={isEmergencyCaseInfoPending}
                    name="confirm-btn"
                    onButtonClick={() => {}}
                    isFullWidth
                    isDisabled={!formDetails.submissionCode}
                  />
                </GridItem>
              </Grid>
            </Stack>
          </Form>
        </Stack>
      </div>
    </Card>
  );
};
