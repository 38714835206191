import React from "react";
import Icon, { IconKind } from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { DropdownOption } from "./Dropdown";
import * as utils from "./utils";

interface CommonDropdownHeaderTypes<T> {
  setIsComponentVisible: (isVisible: boolean) => void;
  isComponentVisible: boolean;
  options: DropdownOption<T>[];
  headerTitle: string;
  headerIcon?: IconKind; // a default option, if the selected value has an icon, we show that instead
  isFullWidth?: boolean;
  tabIndex?: number;
  isDisabled?: boolean;
}

export interface SingleValueDropdownHeaderInterface<T>
  extends CommonDropdownHeaderTypes<T> {
  value?: T;
  isMultiSelect?: false;
}

export interface MultiValueDropdownHeaderInterface<T>
  extends CommonDropdownHeaderTypes<T> {
  values?: T[];
  isMultiSelect: true;
}

export type DropdownHeaderInterface<T> =
  | SingleValueDropdownHeaderInterface<T>
  | MultiValueDropdownHeaderInterface<T>;

const DropdownHeader = <T,>(props: DropdownHeaderInterface<T>) => {
  const {
    setIsComponentVisible,
    isComponentVisible,
    options,
    headerTitle,
    headerIcon,
    isFullWidth,
    tabIndex,
    isDisabled,
  } = props;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsComponentVisible(!isComponentVisible);
  };
  const headerText = props.isMultiSelect
    ? headerTitle
    : utils.getHeaderTitle(options, headerTitle, props.value);

  const icon = props.isMultiSelect
    ? utils.getHeaderIconForMultiSelect(headerIcon)
    : utils.getHeaderIcon(options, props.value, headerIcon);

  return (
    <div
      onClick={handleClick}
      className="dropdown__header"
      tabIndex={isDisabled ? -1 : tabIndex}
    >
      <Stack
        gutterSize={isFullWidth ? "none" : "normal"}
        justify={isFullWidth ? "space-between" : "left"}
        align="center"
        isExpanded
      >
        <div className="dropdown__header__text__container">
          <Stack align="center" isExpanded>
            {icon && <Icon kind={icon} />}
            <span title={String(headerText)} className="dropdown__header__text">
              {headerText}
            </span>
          </Stack>
        </div>
        {isComponentVisible ? (
          <Icon kind={"up-arrow"} size="small" />
        ) : (
          <Icon kind={"down-arrow"} size="small" />
        )}
      </Stack>
    </div>
  );
};

export default DropdownHeader;
