import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { NotificationDigestPreferenceSetListResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { AlertsSettings } from "../types";
import { fetchAlertsSettingsApi, patchAlertsSettingsApi } from "./api/api";

interface StateSlice {
  alertsSettings: AlertsSettings | null;
  isPending: boolean;
  fetchError: string | null;

  isPatchPending: boolean;
}
const initialState: StateSlice = {
  alertsSettings: null,
  isPending: false,
  fetchError: null,

  isPatchPending: false,
};

const alertsSettingsSlice = createSlice({
  name: "alertsSettings",
  initialState: initialState,
  reducers: {
    fetchAlertsSettings() {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<AlertsSettings>) {
      state.isPending = false;
      state.alertsSettings = action.payload;
      state.fetchError = null;
    },

    patchAlertsSettings(_state, _action: PayloadAction<AlertsSettings>) {
      //empty handled by saga
    },
    setIsPatchPending(state, action: PayloadAction<boolean>) {
      state.isPatchPending = action.payload;
    },
  },
});

export default alertsSettingsSlice.reducer;

export const {
  fetchAlertsSettings,
  setPending,
  setFetchError,
  fetchSuccess,

  patchAlertsSettings,
  setIsPatchPending,
} = alertsSettingsSlice.actions;

// Async stuff - sagas

function* fetchAlertsSettingsSaga() {
  yield put(setPending());
  try {
    const response: AxiosResponse<NotificationDigestPreferenceSetListResponse> =
      yield call(fetchAlertsSettingsApi);

    yield put(fetchSuccess(response.data.payload as AlertsSettings));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* patchAlertsSettingsSaga(action: PayloadAction<AlertsSettings>) {
  try {
    yield put(setIsPatchPending(true));
    yield call(patchAlertsSettingsApi, action.payload);

    // refetch the profile after a successful update
    yield put(fetchAlertsSettings());
    yield put(setIsPatchPending(false));
  } catch (e) {
    yield put(setIsPatchPending(false));
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchAlertsSettings.toString(), fetchAlertsSettingsSaga);
  yield takeLatest(patchAlertsSettings.toString(), patchAlertsSettingsSaga);
}

export function* alertsSettingsSagas() {
  yield all([actionWatcher()]);
}
