export const PASSWORD_CRITERIA = [
  "[A-Z]",
  "[a-z]",
  "[0-9]",
  "[^A-Za-z0-9]",
] as const;
export const PASSWORD_MIN_LENGTH = 8;

export const VERY_WEAK_PASSWORD_STRENGTH = 25;
export const WEAK_PASSWORD_STRENGTH = 50;
export const GOOD_PASSWORD_STRENGTH = 75;
export const GREAT_PASSWORD_STRENGTH = 100;
