import { axiosCsisApi } from "@csis.com/tip/src/App";
import { RetainerCreateBody } from "@csis.com/tip/src/api/openapi/data-contracts";
import { RetainerUpdateBody } from "./types";

export async function fetchRetainerByIdAPI(id: string) {
  return axiosCsisApi.getRetainerByExternalIdByAdminApi10AdminRetainerRetainerExternalIdGet(
    id
  );
}

export async function updateRetainerAPI(
  id: string,
  retainer: RetainerUpdateBody
) {
  return axiosCsisApi.updateRetainerByAdminApi10AdminRetainerRetainerExternalIdPut(
    id,
    retainer
  );
}

export async function deleteRetainerAttachmentAPI(
  retainerid: string,
  attachmentId: string
) {
  return axiosCsisApi.deleteRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdDelete(
    retainerid,
    attachmentId
  );
}

export async function updateRetainerAttachmentsAPI(
  retainerId: string,
  formData: File[]
) {
  return axiosCsisApi.uploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost(
    retainerId,
    {
      files: formData,
    }
  );
}

export async function fetchAttachmentApi(
  retainerId: string,
  attachmentId: string
) {
  return axiosCsisApi.downloadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdGet(
    retainerId,
    attachmentId,
    {
      format: "blob",
    }
  );
}

export async function createRetainerAPI(retainer: RetainerCreateBody) {
  return axiosCsisApi.createRetainerByAdminApi10AdminRetainerPost(retainer);
}
