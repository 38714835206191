import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "@csis.com/components";
import { emergencyCaseKeys } from "../EmergencyCaseSearch/constants";
import { getAdminUsersResult } from "../EmergencyCaseSearch/selectors";
import { fetchAdminUsers } from "../EmergencyCaseSearch/slice";

export interface ResponsibleUserSelectProps {
  responsibleUserId?: string;
  onChange: (value: string) => void;
}

const ResponsibleUserSelect = ({
  responsibleUserId,
  onChange,
}: ResponsibleUserSelectProps) => {
  const dispatch = useDispatch();

  const { responsibleUsers } = useSelector(getAdminUsersResult);

  const handleResponsibleUserChange = (value: string) => {
    onChange(value);
  };

  useEffect(() => {
    dispatch(fetchAdminUsers());
  }, [dispatch]);

  return (
    <Dropdown
      type={"shadow"}
      name={emergencyCaseKeys.RESPONSIBLE_EXTERNAL_ID}
      headerTitle="Responsible User"
      value={responsibleUserId}
      options={
        responsibleUsers
          ? Object.values(responsibleUsers).map((user) => ({
              value: user.id,
              label: user.name || user.login,
            }))
          : []
      }
      onSelect={(value: string) => handleResponsibleUserChange(value)}
      isFullWidth
      isLoading={!responsibleUsers}
      isUppercase={false}
    />
  );
};

export default ResponsibleUserSelect;
