import { ColumnInterface } from "../types";

// given a column array, and an index and another index,
// we move the element from the first index to the second index
export function arrayMoveItem(
  columns: Array<any>,
  fromIndex: number,
  toIndex: number
) {
  const cloneArray = columns.slice();
  const element = cloneArray[fromIndex];
  if (element) {
    cloneArray.splice(fromIndex, 1);
    cloneArray.splice(toIndex, 0, element);
    return cloneArray;
  }
  return cloneArray;
}

// given a column array, and a column from that array
// we return a new array with the column visible/hidden
export function toggleColumnVisibility<K extends string, N extends string>(
  columns: ColumnInterface<K, N>[],
  column: ColumnInterface<K, N>
) {
  return columns.map((col) => {
    if (col.key === column.key) {
      return { ...col, isHidden: !col.isHidden };
    }
    return col;
  });
}

// given a column array, it return how many columns are hidden
export function getHiddenColumnsCount<K extends string, N extends string>(
  columns: ColumnInterface<K, N>[]
) {
  return columns.filter((col) => {
    return col.isHidden === true;
  }).length;
}
