import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";

const GenericFormError = ({ errorText }: { errorText: string }) => {
  return (
    <Stack align="center">
      <Icon kind="alert_circle" size="big" color="red" />
      <div
        className="f_red f_semibold form__generic-error"
        style={{ whiteSpace: "pre-line" }}
        data-test-id="generic-form-error"
      >
        {errorText}
      </div>
    </Stack>
  );
};

export default GenericFormError;
