import { Stack } from "@csis.com/components";
import { useTranslations } from "../../translations/useTranslations";

export const CsisFooter = () => {
  const { t } = useTranslations();
  return (
    <div className="passwordreset-page__footer" role="contentinfo">
      <Stack gutterSize="gigantic">
        <div>CSIS &#169; {new Date().getFullYear()}</div>
        <Stack gutterSize="normal" align="center">
          <a href={"https://www.csis.com/terms-and-conditions/"}>
            <span className="passwordreset-page__footer__link">
              {t("terms_of_use")}
            </span>
          </a>
          <div className="passwordreset-page__footer__dot" />
          <a href={"https://www.csis.com/csis-privacy-policy/"}>
            <span className="passwordreset-page__footer__link">
              {t("privacy_policy")}
            </span>
          </a>
        </Stack>
      </Stack>
    </div>
  );
};
