import React from "react";
import DatePicker, {
  DatePickerInterface,
} from "@csis.com/components/src/atoms/DatePicker/DatePicker";
import { isDate } from "../../../models/helpers";

// todo find a way to nicely read from user settings
function formatDate(date?: Date | null) {
  if (date && isDate(date)) {
    return date.toLocaleDateString("en-GB");
  }
  return "";
}

// This component is a simple "wrapper" of the atom Datepicker
// to make it fit in tip by for example giving it some translations etc
export interface DatePickerTIPInterface extends DatePickerInterface {}

const DatePickerTIP: React.FC<DatePickerTIPInterface> = (props) => {
  return <DatePicker {...props} formatDate={formatDate} />;
};

export default React.memo(DatePickerTIP);
