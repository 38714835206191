import React from "react";
import Icon from "../../icons/Icon";

export interface PillInterface {
  onClick?: () => void;
  dataTestId?: string;
  text: string;
}

const Pill = ({ text, onClick, dataTestId }: PillInterface) => {
  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && onClick) {
        event.stopPropagation();
        event.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      tabIndex={0}
      className="pill"
      onClick={onClick}
      onKeyPress={handleKeyPress}
      data-test-id={dataTestId}
      title={text}
    >
      {text}
      <Icon kind="close" size="big" />
    </div>
  );
};

export default React.memo(Pill);
