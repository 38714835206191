import { ChartColor } from "@csis.com/components/src/charts/types";
import { Color } from "@csis.com/components/src/colors";
import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const SeverityValues = {
  NA: "na",
  FALSE_POSITIVE: "false-positive",
  INFO: "info",
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
  CRITICAL: "critical",
} as const;

export type Severity = typeof SeverityValues[keyof typeof SeverityValues];

export const isSeverity = (x: any): x is Severity => {
  return Object.values(SeverityValues).includes(x);
};

export const isArrayOfSeverities: Guard<Severity[]> = isArrayOf(isSeverity);

export const SeverityColors: Record<Severity, Color> = {
  [SeverityValues.NA]: "blue",
  [SeverityValues.FALSE_POSITIVE]: "grey",
  [SeverityValues.INFO]: "blue",
  [SeverityValues.LOW]: "orange",
  [SeverityValues.MEDIUM]: "orange",
  [SeverityValues.HIGH]: "red",
  [SeverityValues.CRITICAL]: "red",
} as const;

export const SeverityChartColors: Record<Severity, ChartColor> = {
  [SeverityValues.NA]: "data1",
  [SeverityValues.FALSE_POSITIVE]: "data2",
  [SeverityValues.INFO]: "data4",
  [SeverityValues.LOW]: "data5",
  [SeverityValues.MEDIUM]: "data8",
  [SeverityValues.HIGH]: "data9",
  [SeverityValues.CRITICAL]: "data10",
} as const;

export const SeverityClassColors = {
  [SeverityValues.NA]: "f_blue",
  [SeverityValues.FALSE_POSITIVE]: "f_secondary",
  [SeverityValues.INFO]: "f_blue",
  [SeverityValues.LOW]: "f_orange",
  [SeverityValues.MEDIUM]: "f_orange",
  [SeverityValues.HIGH]: "f_red",
  [SeverityValues.CRITICAL]: "f_red",
} as const;

// this is the part we localize
export const SeverityTranslationKeys: Record<Severity, TranslationKey> = {
  [SeverityValues.NA]: "na",
  [SeverityValues.FALSE_POSITIVE]: "false_positive",
  [SeverityValues.INFO]: "info",
  [SeverityValues.LOW]: "low",
  [SeverityValues.MEDIUM]: "medium",
  [SeverityValues.HIGH]: "high",
  [SeverityValues.CRITICAL]: "critical",
} as const;
