import classNames from "classnames";
import React, { useCallback } from "react";
import { Stack } from "@csis.com/components";
import Icon, { IconKind } from "../../icons/Icon";
import { BLUE, GREY, RED } from "../../colors";
import { SMALL } from "../../sizes";
import Spinner from "./Spinner/Spinner";
import SplitButton, { SecondaryAction } from "./SplitButton/SplitButton";
import { getIconSize } from "./utils";

export interface ButtonInterface {
  type?: "primary" | "text";
  text: string;
  color?: BLUE | RED | GREY;
  icon?: IconKind;
  isLoading?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  size?: SMALL;
  tooltipText?: string;
  isIconAfterText?: boolean;
  formId?: string;
  onButtonClick: () => void;
  dataTestId?: string;
  name: string;
  // SplitButton specific:
  secondaryActions?: SecondaryAction[];
  opensFromTop?: boolean;
  opensFromRight?: boolean;
}

const Button: React.FC<ButtonInterface> = ({
  type = "primary",
  size,
  text,
  icon,
  isFullWidth,
  isLoading,
  isDisabled,
  onButtonClick,
  formId,
  isIconAfterText,
  secondaryActions,
  color,
  tooltipText,
  name,
  dataTestId,
  opensFromTop,
  opensFromRight,
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onButtonClick();
    },
    [onButtonClick]
  );

  const classes = classNames("button", {
    "button--primary": type === "primary",
    "button--text": type === "text",
    "button--disabled": isDisabled,
    "button--loading": isLoading,
    "button--blue": color === "blue",
    "button--red": color === "red",
    "button--grey": color === "grey",
    "button--small": size === "small",
    "button--full-width": isFullWidth,
  });

  const button = (
    <button
      onClick={handleClick}
      className={classes}
      aria-label={text || icon}
      title={tooltipText}
      data-test-id={dataTestId}
      disabled={isDisabled || isLoading}
      type={formId ? "submit" : "button"}
      form={formId}
      name={name}
    >
      <Stack align="center" gutterSize="normal">
        {icon && !isIconAfterText && !isLoading ? (
          <Icon kind={icon} size={getIconSize(size)} />
        ) : null}
        <span title={text} className={isLoading ? "f_transparent" : undefined}>
          {text}
        </span>
        {icon && isIconAfterText && !isLoading ? (
          <Icon kind={icon} size={getIconSize(size)} />
        ) : null}
      </Stack>
      {isLoading && (
        <div className="button__spinner-container">
          <Spinner size={getIconSize(size)} />
        </div>
      )}
    </button>
  );

  return secondaryActions && secondaryActions?.length > 0 ? (
    <SplitButton
      button={button}
      color={color}
      size={size === "small" ? "small" : undefined}
      secondaryActions={secondaryActions}
      isFullWidth={isFullWidth}
      isDisabled={isDisabled}
      isLoading={isLoading}
      opensFromTop={opensFromTop}
      opensFromRight={opensFromRight}
      name={name}
      dataTestId={dataTestId}
    />
  ) : (
    button
  );
};

export default React.memo(Button);
