import { Alerter, AlerterEntry } from "./Alerter";

export function getAlerterDisplayName(
  alerter: Alerter,
  alerters: AlerterEntry[]
) {
  const displayName = alerters.find((al) => al.name === alerter)?.display_name;

  return displayName ? displayName : alerter;
}
