import { DateSpanMode } from "../components/shared/DateSpan/DateSpan";
import { isString } from "../models/helpers";
import { HOUR_PREF_24 } from "../userPreferences/constants";
import { Localization } from "../userPreferences/types";
import { getRelativeTimeDifference } from "./dateCalculations";

type DateFormatter = Intl.DateTimeFormat | "ISO";

function dateTimeFormatterFactory(userLocalization: Localization) {
  return new Intl.DateTimeFormat(userLocalization.locale, {
    dateStyle: userLocalization?.dateTime.dateTimeStyle.dateStyle
      ? userLocalization?.dateTime.dateTimeStyle.dateStyle
      : "short",
    timeStyle: userLocalization?.dateTime.dateTimeStyle.timeStyle
      ? userLocalization?.dateTime.dateTimeStyle.timeStyle
      : undefined,
    hourCycle: userLocalization?.dateTime.hourCycle
      ? userLocalization?.dateTime.hourCycle
      : HOUR_PREF_24,
  });
}

export function getFormattedDateBasedOnPrefs(
  inputDate: Date | string,
  userLocalization: Localization,
  mode?: DateSpanMode
) {
  const date = isString(inputDate) ? new Date(inputDate) : inputDate;

  let formatter: DateFormatter;

  if (userLocalization?.dateTime?.dateFormat === "ISO") {
    formatter = "ISO";
  } else {
    formatter = dateTimeFormatterFactory(userLocalization);
  }

  if (mode === "timestamp") {
    return getCsisUtcDate(date);
  }

  if (mode === "short") {
    formatter = new Intl.DateTimeFormat("en-GB");
  }

  if (mode === "extended") {
    formatter = "ISO";
  }

  if (mode === "short-no-relative") {
    formatter = new Intl.DateTimeFormat("en-GB");
    return formatLocalizedDate(date, formatter);
  }

  // Checks for "show relative time"
  if (userLocalization.dateTime.dateTimeStyle.showRelativeTime === "never") {
    if (mode === "extended") {
      // only in extended mode we dont care about showing the relative time
      return getTimestampAndRelativeTime(
        date.toISOString(),
        formatter,
        userLocalization
      );
    }
    // the "simplest" case, we return timestamp or whatever the user has defined they prefer
    return formatLocalizedDate(date, formatter);
  }

  if (
    userLocalization.dateTime.dateTimeStyle.showRelativeTime === "sometimes"
  ) {
    if (mode === "extended") {
      return getTimestampAndRelativeTime(
        date.toISOString(),
        formatter,
        userLocalization
      );
    }
    if (mode === "table") {
      return getTimestampAndRelativeTime(
        date.toISOString(),
        formatter,
        userLocalization
      );
    }

    return getTimestampOrRelativeTime(
      date.toISOString(),
      formatter,
      userLocalization
    );
  }
  return getTimestampOrRelativeTime(
    date.toISOString(),
    formatter,
    userLocalization
  );
}

// CSIS-y UTC date format because js doesnt support YYYY-MM-DD HH:MM:SS out of the box
export function getCsisUtcDate(inputDate?: Date | string) {
  if (!inputDate) return "-";
  const date = isString(inputDate) ? new Date(inputDate) : inputDate;
  return date.toISOString().replace("T", " ").substring(0, 19) + " UTC";
}

// Timestamp or formatted Date
function formatLocalizedDate(date: Date, formatter: DateFormatter) {
  if (formatter === "ISO") return getCsisUtcDate(date);
  else return formatter.format(new Date(date));
}

function getLocalizedRelativeTime(
  ISODate: string,
  userLocalization: Localization
) {
  const timeDifferenceInfo = getRelativeTimeDifference(ISODate);
  if (timeDifferenceInfo === null) return null;

  const locale = userLocalization?.locale || "en-GB";

  const relativeTimeFormatter = new Intl.RelativeTimeFormat(locale, {
    numeric: "always",
    style: "long",
  });

  return relativeTimeFormatter.format(
    timeDifferenceInfo.amount,
    timeDifferenceInfo.unit
  );
}

function getTimestampOrRelativeTime(
  ISODate: string,
  formatter: DateFormatter,
  userLocalization: Localization
) {
  const relativeTimeSince = getLocalizedRelativeTime(ISODate, userLocalization);

  if (relativeTimeSince === null) {
    return formatLocalizedDate(new Date(ISODate), formatter);
  }
  return relativeTimeSince;
}

// if relative time exists it will return both
// or else just timestamp
function getTimestampAndRelativeTime(
  dateString: string,
  formatter: DateFormatter,
  userLocalization: Localization
) {
  const formattedDate = formatLocalizedDate(new Date(dateString), formatter);
  const timeSince = getLocalizedRelativeTime(dateString, userLocalization);
  const timeSinceString = timeSince ? ` (${timeSince})` : "";

  return `${formattedDate}${timeSinceString}`;
}
