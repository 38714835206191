import { ColumnInterface } from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { retainersKeys } from "../constants";
import { RetainersKey } from "../types";

export type RetainersColumns = ColumnInterface<RetainersKey, TranslationKey>[];

export const columns: RetainersColumns = [
  {
    key: retainersKeys.NAME,
    name: "business_unit_name",
    isSortable: true,
  },
  {
    key: retainersKeys.TYPE,
    name: "retainer_type",
    isSortable: false,
  },
  {
    key: retainersKeys.UPDATED_AT,
    name: "last_updated",
    isSortable: false,
  },
  {
    key: retainersKeys.PRIMARY_CONTACT_NAME,
    name: "primary_contact_name",
    isSortable: false,
  },
  {
    key: retainersKeys.PRIMARY_PHONE_NUMBER,
    name: "primary_contact_phone_number",
    isSortable: false,
  },
  {
    key: retainersKeys.ATTACHMENTS,
    name: "attachments",
    isSortable: false,
    isHidden: true,
  },
];
