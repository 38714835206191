import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const emergencyCaseKeys = {
  EXTERNAL_ID: "external_id",
  BUSINESS_UNIT_ID: "business_unit_external_id",
  CREATED_AT: "created_at",
  SUBMITTED_AT: "submitted_at",
  BUSINESS_UNIT: "business_unit",
  CONTACT_NAME: "contact_name",
  CONTACT_PHONE_NUMBER: "contact_phone_number",
  CONTACT_EMAIL: "contact_email",
  EMERGENCY_RESPONSE_CASE_TYPE: "emergency_response_case_type",
  REACTION_TIME: "reaction_time",
  RESPONSIBLE: "responsible",
  EMERGENCY_RESPONSE_CASE_STATUS: "emergency_response_case_status",
  UPDATED_AT: "updated_at",
  TYPE: "type",
  STATUS: "status",
  RESPONSIBLE_EXTERNAL_ID: "responsible_external_id",
  NEW_CUSTOMER_NAME: "new_customer_name",
  DECISION_AT: "decision_at",
  IS_RETAINER_CASE: "is_retainer_case",

  // keys used in all search pages:
  ...searchKeys,
} as const;

export const emergencyCaseFilterKeys = [
  emergencyCaseKeys.TYPE,
  emergencyCaseKeys.BUSINESS_UNIT,
  emergencyCaseKeys.STATUS,
  emergencyCaseKeys.RESPONSIBLE,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;

export const emergencyCaseTypes = {
  IT: "it",
  OT: "ot",
} as const;

export const emergencyCaseTypeLabels = {
  [emergencyCaseTypes.IT]: "IT",
  [emergencyCaseTypes.OT]: "OT",
} as const;
