import classNames from "classnames";
import React, { useRef } from "react";
import Stack from "../../layout/Stack/Stack";
import { BLUE, GRADIENT, RED } from "../../colors";
import { BIG, LARGE, NORMAL, SMALL } from "../../sizes";
import { DropdownOption } from "../Dropdown/Dropdown";
import IconButton from "../IconButton/IconButton";
import IconMenu from "../IconMenu/IconMenu";

interface DialogCardInterface extends DialogInterface {}

const DialogCard: React.FC<DialogCardInterface> = ({
  isModal,
  onClose,
  color,
  subHeader,
  children,
  horizontalAlign,
  verticalAlign,
  header,
  options,
  name,
  handleOptionSelect,
}) => {
  const classes = classNames("dialog__card", {
    "dialog__card--top": verticalAlign === "top",
    "dialog__card--bottom": verticalAlign === "bottom",
    "dialog__card--right": horizontalAlign === "right",
    "dialog__card--left": horizontalAlign === "left",
    "dialog__card--center": horizontalAlign === "center",
    "dialog__card--with-header": header,
  });

  const ref = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <div
      onClick={(e) => {
        // this is needed, or else the clicks inside the dialog card
        // are handled by the parent -> mask
        e.stopPropagation();
      }}
      className={classes}
    >
      <div className={"dialog__header"}>
        <div title={header || ""} className={"dialog__header__title"}>
          {header || ""}
        </div>
        <Stack gutterSize="normal" align="center">
          {options && (
            <IconMenu
              name={name + "-icon-menu"}
              color="csis"
              type={header ? "primary" : "text"}
              size="large"
              spacing="small"
              opensFromRight
              options={options}
              onSelect={handleOptionSelect}
            />
          )}
          {!isModal && (
            <IconButton
              color={color === "gradient" ? undefined : color}
              type={header ? "primary" : "text"}
              size="large"
              spacing="small"
              isCircle
              icon="close"
              onButtonClick={onClose || (() => {})}
              dataTestId="close-dialog-button"
            />
          )}
        </Stack>
      </div>
      {subHeader && <div className={"dialog__subheader"}>{subHeader}</div>}
      <div ref={ref} className={"dialog__body"}>
        {children}
      </div>
    </div>
  );
};

type dialogVerticalAlign = "top" | "center" | "bottom";
type dialogHorizontalAlign = "left" | "center" | "right";

export interface DialogInterface {
  isModal?: boolean; // this indicates if the dialog will be blocking until user action, or dialog can be dismissed
  hasMask?: boolean; // this indicates if the rest of the page can be accessed while the dialog is open
  children: React.ReactNode;
  subHeader?: React.ReactNode;
  header?: string;
  color?: RED | BLUE | GRADIENT;
  verticalAlign?: dialogVerticalAlign;
  horizontalAlign?: dialogHorizontalAlign;
  onClose?: () => void;
  size?: SMALL | NORMAL | BIG | LARGE | "auto";
  spacing?: "none";
  isHeaderAbsolute?: boolean; // When we want to display a dialog with the x on top right corner and not interfering with the childrens styles
  options?: DropdownOption<number | string>[];
  handleOptionSelect?: (x: number | string) => void;
  dataTestId?: string;
  name: string;
}

const Dialog: React.FC<DialogInterface> = ({
  isModal,
  color,
  hasMask = true,
  header,
  children,
  subHeader,
  verticalAlign = "center",
  horizontalAlign = "center",
  isHeaderAbsolute,
  onClose,
  options,
  size,
  spacing,
  handleOptionSelect,
  dataTestId,
  name,
}) => {
  const classes = classNames("dialog", {
    "dialog--modal": isModal,
    "dialog--red": color === "red",
    "dialog--blue": color === "blue",
    "dialog--gradient": color === "gradient",
    "dialog--small": size === "small",
    "dialog--big": size === "big",
    "dialog--large": size === "large",
    "dialog--auto": size === "auto",
    "dialog--spacing-none": spacing === "none",
    "dialog--header-absolute": isHeaderAbsolute,
  });
  const modalClasses = classNames("dialog__mask", {
    "dialog__mask--top": verticalAlign === "top",
    "dialog__mask--bottom": verticalAlign === "bottom",
    "dialog__mask--right": horizontalAlign === "right",
    "dialog__mask--left": horizontalAlign === "left",
  });

  const handleMaskClick = () => {
    if (onClose && !isModal) {
      onClose();
    }
  };

  return (
    <div className={classes} data-test-id={dataTestId}>
      {hasMask ? (
        <div onClick={handleMaskClick} className={modalClasses}>
          <DialogCard
            isModal={isModal}
            color={color}
            onClose={onClose}
            children={children}
            header={header}
            subHeader={subHeader}
            options={options}
            handleOptionSelect={handleOptionSelect}
            name={name}
          />
        </div>
      ) : (
        <DialogCard
          isModal={isModal}
          color={color}
          onClose={onClose}
          children={children}
          subHeader={subHeader}
          horizontalAlign={horizontalAlign}
          verticalAlign={verticalAlign}
          header={header}
          options={options}
          handleOptionSelect={handleOptionSelect}
          name={name}
        />
      )}
    </div>
  );
};
export default Dialog;
