import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ArticlePreviewPageDetailResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  STRINGIFY_QUERY_PARAMS,
  handleRequestError,
} from "@csis.com/tip/src/api/utils";
import { defaultPreferences } from "@csis.com/tip/src/userPreferences/defaultPreferences";
import { getUserPreferences } from "@csis.com/tip/src/userPreferences/selectors";
import { updateUserPreferences } from "@csis.com/tip/src/userPreferences/slice";
import {
  ThreatInsightsPreferences,
  UserPreferences,
} from "@csis.com/tip/src/userPreferences/types";
import { axiosCsisApi } from "../../../App";
import { mapInsightsStartAndEndDateToCorrectType } from "./api/utils";
import { InsightsRow, QueryParams } from "./types";

interface StateSlice {
  insights: InsightsRow[];
  hasNextPage: boolean;
  isPending: boolean;
  fetchError: string | null;

  latestFeatured: InsightsRow[];
  isLatestFeaturedFetchPending: boolean;
  latestFeaturedFetchError: string | null;
}
const initialState: StateSlice = {
  insights: [],
  hasNextPage: false,
  isPending: false,
  fetchError: null,

  latestFeatured: [],
  isLatestFeaturedFetchPending: false,
  latestFeaturedFetchError: null,
};

const insightsSlice = createSlice({
  name: "insights",
  initialState: initialState,
  reducers: {
    fetchInsights(_state, _action: PayloadAction<{}>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
      state.insights = [];
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
      state.insights = [];
    },
    fetchSuccess(state, action: PayloadAction<InsightsRow[]>) {
      state.isPending = false;
      state.fetchError = null;
      state.insights = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },

    fetchLatestFeaturedInsights(_state, _action: PayloadAction<{}>) {
      //empty handled by saga
    },
    setIsLatestFeaturedInsightsFetchPending(state) {
      state.isLatestFeaturedFetchPending = true;
      state.latestFeaturedFetchError = null;
      state.latestFeatured = [];
    },
    setLatestFeaturedInsightsFetchError(state, action: PayloadAction<string>) {
      state.isLatestFeaturedFetchPending = false;
      state.latestFeaturedFetchError = action.payload;
      state.latestFeatured = [];
    },
    setLatestFeaturedInsightsFetchSuccess(
      state,
      action: PayloadAction<InsightsRow[]>
    ) {
      state.isLatestFeaturedFetchPending = false;
      state.latestFeaturedFetchError = null;
      state.latestFeatured = action.payload;
    },

    setThreatInsightsUserPreference(
      _state,
      _action: PayloadAction<Partial<ThreatInsightsPreferences>>
    ) {
      //empty handled by saga
    },
  },
});

export default insightsSlice.reducer;

export const {
  fetchInsights,
  setPending,
  setFetchError,
  setHasNextPage,
  fetchSuccess,

  fetchLatestFeaturedInsights,
  setIsLatestFeaturedInsightsFetchPending,
  setLatestFeaturedInsightsFetchError,
  setLatestFeaturedInsightsFetchSuccess,

  setThreatInsightsUserPreference,
} = insightsSlice.actions;

// Async stuff - sagas

function* fetchInsightsSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setPending());
  try {
    const response: AxiosResponse<ArticlePreviewPageDetailResponse> =
      yield call(
        axiosCsisApi.getArticlesApi20ArticleGet,
        {
          ...mapInsightsStartAndEndDateToCorrectType(action.payload),
        },
        STRINGIFY_QUERY_PARAMS
      );

    yield put(fetchSuccess(response.data.payload.page as InsightsRow[]));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* fetchLatestFeaturedInsightsSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsLatestFeaturedInsightsFetchPending());
  try {
    const response: AxiosResponse<ArticlePreviewPageDetailResponse> =
      yield call(
        axiosCsisApi.getArticlesApi20ArticleGet,
        {
          ...mapInsightsStartAndEndDateToCorrectType(action.payload),
        },
        STRINGIFY_QUERY_PARAMS
      );

    yield put(
      setLatestFeaturedInsightsFetchSuccess(
        response.data.payload.page as InsightsRow[]
      )
    );
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setLatestFeaturedInsightsFetchError(errorMessage));
  }
}

function* setThreatInsightsUserPreferenceSaga(
  action: PayloadAction<Partial<ThreatInsightsPreferences>>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    if (newPrefs.threat_insights) {
      newPrefs.threat_insights = {
        ...newPrefs.threat_insights,
        ...action.payload,
      };
    } else {
      newPrefs.threat_insights = {
        ...defaultPreferences.threat_insights,
        ...action.payload,
      };
    }

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchInsights.toString(), fetchInsightsSaga);
  yield takeLatest(
    fetchLatestFeaturedInsights.toString(),
    fetchLatestFeaturedInsightsSaga
  );
  yield takeLatest(
    setThreatInsightsUserPreference.toString(),
    setThreatInsightsUserPreferenceSaga
  );
}

export function* insightsSagas() {
  yield all([actionWatcher()]);
}
