import React, { useState } from "react";
import { Button, Dialog } from "../../atoms";
import { Stack } from "../../layout";

type Item = { title: string; content: React.ReactElement };

export interface StepperDialogInterface {
  title: string;
  items: Item[];
  onChange?: (newIndex: number) => void;
  hasAddNewItem?: boolean;
  isNavigateButtonsVisible?: boolean;
}

const StepperDialog: React.FC<StepperDialogInterface> = ({
  title,
  items,
  onChange,
  hasAddNewItem = false,
  isNavigateButtonsVisible,
}) => {
  const [current, setCurrent] = useState<number>(0);

  function handleChange(newIndex: number) {
    setCurrent(newIndex);
    if (onChange) {
      onChange(newIndex);
    }
  }

  function handleSetPrevious() {
    handleChange((current - 1 + items.length) % items.length);
  }

  function handleSetNext() {
    handleChange((current + 1) % items.length);
  }

  return (
    <Dialog name="stepper-dialog" size="auto" spacing="none" isHeaderAbsolute>
      <div className={"stepper-dialog"}>
        <div className={"stepper-dialog__sidebar"}>
          <Stack isVertical gutterSize="large" isExpanded>
            <div className="f_csis f_large f_semibold">{title}</div>
            <Stack isVertical isExpanded>
              {items.map((item, index) => (
                <div
                  onClick={() => handleChange(index)}
                  className={
                    index === current
                      ? "stepper-dialog__sidebar__item stepper-dialog__sidebar__item--selected"
                      : "stepper-dialog__sidebar__item"
                  }
                >
                  {item.title}
                </div>
              ))}
              {hasAddNewItem && (
                <Button
                  onButtonClick={() => {}}
                  name="stepperdialog-add-new-item-btn"
                  text="Add new"
                  type="text"
                  icon="add"
                />
              )}
            </Stack>
          </Stack>
        </div>
        <div className={"stepper-dialog__content-container"}>
          <div className="stepper-dialog__content">
            {items[current]?.content}
          </div>
          {isNavigateButtonsVisible && (
            <div className="stepper-dialog__actions">
              <Stack justify="space-between" isExpanded>
                <Button
                  name="stepperdialog-prev-btn"
                  text="Previous"
                  type="text"
                  isDisabled={current === 0}
                  icon="left-arrow"
                  onButtonClick={handleSetPrevious}
                />
                <Button
                  name="stepperdialog-prev-btn"
                  text="Next"
                  type="text"
                  isDisabled={current === items.length - 1}
                  isIconAfterText
                  icon="right-arrow"
                  onButtonClick={handleSetNext}
                />
              </Stack>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default StepperDialog;
