import React from "react";
import Icon from "../../icons/Icon";

const ChartError: React.FC<{
  error?: string;
}> = ({ error }) => {
  return (
    <div className="chart-error">
      <div className="chart-error__graphic">
        <Icon kind="block" color="grey-alt" />
      </div>
      <div className="chart-error__title-container">
        <div className="chart-error__title f_secondary f_semibold">
          {error ? error : "Something went wrong"}
        </div>
      </div>
    </div>
  );
};
export default ChartError;
