import React from "react";
import { Button, Stack } from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";

const errorFaces = ["(._.)", "(0.o)", "(>.<)"];

const ErrorInfo: React.FC<{
  error: string;
  technicalDetails?: string;
  retryAction?: () => void;
  clearAction?: () => void;
  backAction?: () => void;
}> = ({ error, technicalDetails, retryAction, clearAction, backAction }) => {
  const { t } = useTranslations();
  return (
    <div className="errorinfo" data-test-id="error-info">
      <Stack gutterSize="large" isVertical align="center">
        <div className="f_gigantic_2 f_semibold f_grey-alt">
          {errorFaces[Math.floor(Math.random() * errorFaces.length)]}
        </div>
        <div className="f_semibold f_secondary f_big whitespace-break-spaces">
          {error}
        </div>
        {technicalDetails && <details>{technicalDetails}</details>}

        <Stack align="center">
          {retryAction && (
            <Button
              name="error-retry-action"
              size="small"
              type="text"
              icon="refresh"
              text={t("retry")}
              onButtonClick={retryAction}
              dataTestId="error-retry-action-btn"
            />
          )}
          {backAction && (
            <Button
              name="error-back-action"
              size="small"
              type="text"
              icon="left-arrow"
              text={t("go_back")}
              onButtonClick={backAction}
              dataTestId="error-back-action-btn"
            />
          )}
          {clearAction && (
            <Button
              name="error-clear-action"
              size="small"
              type="primary"
              icon="close"
              text={t("clear_filters_retry")}
              onButtonClick={clearAction}
              dataTestId="error-clear-action-btn"
            />
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default ErrorInfo;
