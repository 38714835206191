import React from "react";
import Stack from "../../layout/Stack/Stack";

export interface ListItemInterface {
  level?: number;
  verticalAlign?: "center";
  children: React.ReactNode;
}

const ListItem: React.FC<ListItemInterface> = ({
  level = 0,
  verticalAlign,
  children,
}) => {
  return (
    <li className={"list__item list__row  list__row--level-" + level}>
      <Stack
        align={verticalAlign === "center" ? "center" : undefined}
        gutterSize="small"
        isExpanded
      >
        {children}
      </Stack>
    </li>
  );
};

export default ListItem;
