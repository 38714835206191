import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, Grid, GridItem } from "@csis.com/components";
import { StatusValues } from "../../models/Status";
import { getEmergencyCaseUpdateResult } from "../selectors";
import { resetEmergencyCaseUpdateState, updateEmergencyCase } from "../slice";

const CloseEmergencyCaseDialog = ({
  onClose,
  onSuccess,
  caseId,
}: {
  onClose: () => void;
  onSuccess: () => void;
  caseId: string;
}) => {
  const dispatch = useDispatch();
  const { emergencyCaseUpdateSuccess, emergencyCaseUpdatePending } =
    useSelector(getEmergencyCaseUpdateResult);

  const handleSubmit = () => {
    dispatch(
      updateEmergencyCase({
        caseId,
        case: {
          emergency_response_case_status: StatusValues.CLOSED,
        },
      })
    );
  };

  useEffect(() => {
    if (emergencyCaseUpdateSuccess && onSuccess) {
      onSuccess();
    }
    return () => {
      dispatch(resetEmergencyCaseUpdateState());
    };
  }, [onSuccess, emergencyCaseUpdateSuccess, dispatch]);

  return (
    <Dialog
      name="new-report-dialog"
      size="big"
      isModal
      hasMask={true}
      header={"Close this case?"}
      onClose={onClose}
      dataTestId="cofirm-dialog"
    >
      <Grid gutterSize="big">
        <GridItem span={6}>
          <Button
            text={"Cancel"}
            name="cancel-edit-emergency-case-btn"
            color="grey"
            onButtonClick={onClose}
            isFullWidth
          />
        </GridItem>
        <GridItem span={6}>
          <Button
            text={"Confirm"}
            name="save-changes-btn"
            onButtonClick={handleSubmit}
            isLoading={emergencyCaseUpdatePending}
            isFullWidth
          />
        </GridItem>
      </Grid>
    </Dialog>
  );
};
export default CloseEmergencyCaseDialog;
