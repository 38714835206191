import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const reportsKeys = {
  ID: "external_id",
  NAME: "name",
  DISPLAY_NAME: "display_name",
  CREATOR: "creator",
  CREATED_AT: "created_at",
  CREATED_AFTER: "created_after",
  CREATED_BEFORE: "created_before",
  TYPE: "report_type",
  UPDATED_AD: "updated_at",
  TIMEFRAME_START: "timeframe_start",
  TIMEFRAME_END: "timeframe_end",
  REPORT_STATUS: "report_status",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [reportsKeys.CREATOR, reportsKeys.TYPE] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;
