import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { NotificationDigestPreferenceSetListResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { TicketsSettings } from "../types";
import { fetchTicketsSettingsApi, patchTicketsSettingsApi } from "./api/api";

interface StateSlice {
  ticketsSettings: TicketsSettings | null;
  isPending: boolean;
  fetchError: string | null;

  isPatchPending: boolean;
}
const initialState: StateSlice = {
  ticketsSettings: null,
  isPending: false,
  fetchError: null,

  isPatchPending: false,
};

const ticketsSettingsSlice = createSlice({
  name: "ticketsSettings",
  initialState: initialState,
  reducers: {
    fetchTicketsSettings() {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<TicketsSettings>) {
      state.isPending = false;
      state.ticketsSettings = action.payload;
      state.fetchError = null;
    },

    patchTicketsSettings(_state, _action: PayloadAction<TicketsSettings>) {
      //empty handled by saga
    },
    setIsPatchPending(state, action: PayloadAction<boolean>) {
      state.isPatchPending = action.payload;
    },
  },
});

export default ticketsSettingsSlice.reducer;

export const {
  fetchTicketsSettings,
  setPending,
  setFetchError,
  fetchSuccess,

  patchTicketsSettings,
  setIsPatchPending,
} = ticketsSettingsSlice.actions;

// Async stuff - sagas

function* fetchTicketsSettingsSaga() {
  yield put(setPending());
  try {
    const response: AxiosResponse<NotificationDigestPreferenceSetListResponse> =
      yield call(fetchTicketsSettingsApi);

    yield put(fetchSuccess(response.data.payload as TicketsSettings));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* patchTicketsSettingsSaga(action: PayloadAction<TicketsSettings>) {
  try {
    yield put(setIsPatchPending(true));
    yield call(patchTicketsSettingsApi, action.payload);

    // refetch the profile after a successful update
    yield put(fetchTicketsSettings());
    yield put(setIsPatchPending(false));
  } catch (e) {
    yield put(setIsPatchPending(false));
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchTicketsSettings.toString(), fetchTicketsSettingsSaga);
  yield takeLatest(patchTicketsSettings.toString(), patchTicketsSettingsSaga);
}

export function* ticketsSettingsSagas() {
  yield all([actionWatcher()]);
}
