import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  PortalsFrontendApiRetainersDeprecatedAppRetainerPreview as RetainerPreview,
  RetainerPreviewDetailResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import {
  deleteRetainerAttachmentAPI,
  fetchAttachmentApi,
  fetchRetainerInfoAPI,
  updateRetainerAttachmentsAPI,
  updateRetainerInfoAPI,
} from "./api/api";
import { RetainerInfo, RetainerInfoAttachment } from "./api/types";

interface StateSlice {
  retainerInfo: Partial<RetainerPreview>;
  isRetainerInfoPending: boolean;
  retainerInfoFetchError: string | null;

  isRetainerInfoUpdating: boolean;
  retainerInfoUpdateError: string | null;
  retainerInfoUpdateSuccess: boolean;

  isRetainerAttachmentUpdating: boolean;
  retainerAttachmentUpdateError: string | null;
  retainerAttachmentUpdateSuccess: boolean;

  isRetainerAttachmentDeleting: boolean;
  retainerAttachmentDeleteError: string | null;
  retainerAttachmentDeleteSuccess: boolean;
}

const initialState: StateSlice = {
  retainerInfo: {},
  isRetainerInfoPending: false,
  retainerInfoFetchError: null,

  isRetainerInfoUpdating: false,
  retainerInfoUpdateError: null,
  retainerInfoUpdateSuccess: false,

  isRetainerAttachmentUpdating: false,
  retainerAttachmentUpdateError: null,
  retainerAttachmentUpdateSuccess: false,

  isRetainerAttachmentDeleting: false,
  retainerAttachmentDeleteError: null,
  retainerAttachmentDeleteSuccess: false,
};

const emergencyResponseSlice = createSlice({
  name: "emergencyResponseRetainer",
  initialState: initialState,
  reducers: {
    fetchRetainerInfo(_state) {
      //empty handled by saga
    },
    setRetainerInfoPending(state) {
      state.isRetainerInfoPending = true;
      state.retainerInfoFetchError = null;
    },
    setFetchRetainerInfoError(state, action: PayloadAction<string>) {
      state.isRetainerInfoPending = false;
      state.retainerInfoFetchError = action.payload;
      state.retainerInfo = {};
    },
    fetchRetainerInfoSuccess(state, action: PayloadAction<RetainerPreview>) {
      state.isRetainerInfoPending = false;
      state.retainerInfoFetchError = null;
      state.retainerInfo = action.payload;
    },
    updateRetainerInfo(_state, _action: PayloadAction<RetainerInfo>) {
      //empty handled by saga
    },
    setUpdateRetainerInfoPending(state) {
      state.isRetainerInfoUpdating = true;
      state.retainerInfoUpdateError = null;
      state.retainerInfoUpdateSuccess = false;
    },
    setUpdateRetainerInfoError(state, action: PayloadAction<string>) {
      state.isRetainerInfoUpdating = false;
      state.retainerInfoUpdateError = action.payload;
      state.retainerInfoUpdateSuccess = false;
    },
    setUpdateRetainerInfoSuccess(state, action: PayloadAction<RetainerInfo>) {
      state.isRetainerInfoUpdating = false;
      state.retainerInfoUpdateError = null;
      state.retainerInfoUpdateSuccess = true;
      state.retainerInfo = { ...state.retainerInfo, ...action.payload };
    },
    resetUpdateRetainerInfoState(state) {
      state.isRetainerInfoUpdating = false;
      state.retainerInfoUpdateError = null;
      state.retainerInfoUpdateSuccess = false;
    },

    updateRetainerAttachments(_state, _action: PayloadAction<File[]>) {
      //empty handled by saga
    },
    setUpdateRetainerAttachmentsPending(state) {
      state.isRetainerAttachmentUpdating = true;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = false;
    },
    setUpdateRetainerAttachmentsError(state, action: PayloadAction<string>) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = action.payload;
      state.retainerAttachmentUpdateSuccess = false;
    },
    setUpdateRetainerAttachmentsSuccess(
      state,
      action: PayloadAction<RetainerInfoAttachment[]>
    ) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = true;
      state.retainerInfo = {
        ...state.retainerInfo,
        attachments: [
          ...(state.retainerInfo.attachments || []),
          ...action.payload,
        ],
      };
    },
    resetUpdateRetainerAttachmentsState(state) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = false;
    },

    deleteRetainerAttachmentById(_state, _action: PayloadAction<string>) {
      //empty handled by saga
    },
    setDeleteRetainerAttachmentPending(state) {
      state.isRetainerAttachmentUpdating = true;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = false;
    },
    setDeleteRetainerAttachmentError(state, action: PayloadAction<string>) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = action.payload;
      state.retainerAttachmentUpdateSuccess = false;
    },
    setDeleteRetainerAttachmentSuccess(state, action: PayloadAction<string>) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = true;
      state.retainerInfo = {
        ...state.retainerInfo,
        attachments: state.retainerInfo.attachments?.filter(
          (item) => item.external_id !== action.payload
        ),
      };
    },
    resetDeleteRetainerAttachmentState(state) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = false;
    },
    downloadAttachment(
      _state,
      _action: PayloadAction<{ attachmentId: string; filename: string }>
    ) {
      //empty handled by saga
    },
  },
});

export default emergencyResponseSlice.reducer;

export const {
  fetchRetainerInfo,
  setRetainerInfoPending,
  setFetchRetainerInfoError,
  fetchRetainerInfoSuccess,

  updateRetainerInfo,
  setUpdateRetainerInfoError,
  setUpdateRetainerInfoPending,
  setUpdateRetainerInfoSuccess,
  resetUpdateRetainerInfoState,

  updateRetainerAttachments,
  setUpdateRetainerAttachmentsError,
  setUpdateRetainerAttachmentsPending,
  setUpdateRetainerAttachmentsSuccess,
  resetUpdateRetainerAttachmentsState,

  deleteRetainerAttachmentById,
  setDeleteRetainerAttachmentError,
  setDeleteRetainerAttachmentPending,
  setDeleteRetainerAttachmentSuccess,
  resetDeleteRetainerAttachmentState,

  downloadAttachment,
} = emergencyResponseSlice.actions;

function* fetchRetainerInfoSaga() {
  yield put(setRetainerInfoPending());
  try {
    const response: AxiosResponse<RetainerPreviewDetailResponse> = yield call(
      fetchRetainerInfoAPI
    );
    yield put(fetchRetainerInfoSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchRetainerInfoError(errorMessage));
  }
}

function* updateRetainerInfoSaga(action: PayloadAction<RetainerInfo>) {
  yield put(setUpdateRetainerInfoPending());
  try {
    yield call(updateRetainerInfoAPI, action.payload);
    yield put(setUpdateRetainerInfoSuccess(action.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUpdateRetainerInfoError(errorMessage));
  }
}

function* updateRetainerAttachmentSaga(action: PayloadAction<File[]>) {
  yield put(setUpdateRetainerAttachmentsPending());
  try {
    const response: AxiosResponse<any> = yield call(
      updateRetainerAttachmentsAPI,
      action.payload
    );
    yield put(setUpdateRetainerAttachmentsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUpdateRetainerAttachmentsError(errorMessage));
  }
}

function* deleteRetainerAttachmentSaga(action: PayloadAction<string>) {
  yield put(setDeleteRetainerAttachmentPending());
  try {
    yield call(deleteRetainerAttachmentAPI, action.payload);
    yield put(setDeleteRetainerAttachmentSuccess(action.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setDeleteRetainerAttachmentError(errorMessage));
  }
}

function* downloadAttachmentSaga(
  action: PayloadAction<{ attachmentId: string; filename: string }>
) {
  try {
    const response: AxiosResponse<Blob> = yield call(
      fetchAttachmentApi,
      action.payload.attachmentId
    );

    const blob = response.data;
    downloadBlobForUser(blob, action.payload.filename);
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchRetainerInfo.toString(), fetchRetainerInfoSaga);
  yield takeLatest(updateRetainerInfo.toString(), updateRetainerInfoSaga);
  yield takeLatest(
    updateRetainerAttachments.toString(),
    updateRetainerAttachmentSaga
  );
  yield takeLatest(
    deleteRetainerAttachmentById.toString(),
    deleteRetainerAttachmentSaga
  );
  yield takeLatest(downloadAttachment.toString(), downloadAttachmentSaga);
}

export function* emergencyResponseRetainerSaga() {
  yield all([actionWatcher()]);
}
