import classNames from "classnames";
import React from "react";

export interface SkeletonInterface {
  type?:
    | "line"
    | "square"
    | "circle"
    | "image"
    | "chart"
    | "multiline"
    | "tile";
}

const Skeleton: React.FC<SkeletonInterface> = ({ type = "line" }) => {
  const classes = classNames("skeleton", {
    "skeleton--line": type === "line",
    "skeleton--square": type === "square",
    "skeleton--circle": type === "circle",
    "skeleton--image": type === "image",
    "skeleton--tile": type === "tile",
  });

  return (
    <>
      {(type === "line" ||
        type === "square" ||
        type === "circle" ||
        type === "tile" ||
        type === "image") && <div className={classes} />}
      {type === "multiline" && (
        <div className="skeleton--multiline-container">
          <div className={"skeleton"} />
          <div className={"skeleton"} />
          <div className={"skeleton"} />
        </div>
      )}
      {type === "chart" && (
        <div className="skeleton--chart-container">
          <div className="skeleton__chart-shape">
            <div className={"skeleton"} />
            <div className={"skeleton"} />
            <div className={"skeleton"} />
            <div className={"skeleton"} />
          </div>
        </div>
      )}
    </>
  );
};

export default Skeleton;
