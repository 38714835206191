import { ThemePreference } from "../userPreferences/types";

export function setTheme(theme: ThemePreference) {
  if (theme === "dark") {
    document.documentElement.setAttribute("data-theme", "dark");
  } else {
    document.documentElement.setAttribute("data-theme", "");
  }
}

export function setHighContrast(isHighContrast: boolean) {
  if (isHighContrast) {
    document.documentElement.setAttribute("data-highcontrast", "true");
  } else {
    document.documentElement.setAttribute("data-highcontrast", "");
  }
}
