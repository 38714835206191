import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getIsEmergencyCaseInfoPending = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.isEmergencyCaseInfoPending;

export const getIsEmergencyCaseInfoSuccess = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.isEmergencyCaseInfoSuccess;

export const getEmergencyCaseInfoError = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.emergencyCaseInfoError;

export const getEmergencyCaseInfo = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.emergencyCaseInfo;

export const getSubmissionCode = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.submissionCode;

export const getEmergencyCaseInfoResult = createSelector(
  [
    getIsEmergencyCaseInfoPending,
    getIsEmergencyCaseInfoSuccess,
    getEmergencyCaseInfoError,
    getEmergencyCaseInfo,
  ],
  (
    isEmergencyCaseInfoPending,
    isEmergencyCaseInfoSuccess,
    emergencyCaseInfoError,
    emergencyCaseInfo
  ) => {
    return {
      isEmergencyCaseInfoPending,
      isEmergencyCaseInfoSuccess,
      emergencyCaseInfoError,
      emergencyCaseInfo,
    };
  }
);

export const getIsEmergencyCaseQuestionaryDataPending = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.isEmergencyCaseQuestionaryDataPending;

export const getIsEmergencyCaseQuestionaryDataSuccess = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.isEmergencyCaseQuestionaryDataSuccess;

export const getEmergencyCaseQuestionaryDataError = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.emergencyCaseQuestionaryDataError;

export const getEmergencyCaseQuestionaryResult = createSelector(
  [
    getIsEmergencyCaseQuestionaryDataPending,
    getIsEmergencyCaseQuestionaryDataSuccess,
    getEmergencyCaseQuestionaryDataError,
  ],
  (
    isEmergencyCaseQuestionaryDataPending,
    isEmergencyCaseQuestionaryDataSuccess,
    emergencyCaseQuestionaryDataError
  ) => {
    return {
      isEmergencyCaseQuestionaryDataPending,
      isEmergencyCaseQuestionaryDataSuccess,
      emergencyCaseQuestionaryDataError,
    };
  }
);

export const getEmergencyCaseAttachments = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.uploadedAttachments;

export const getIsEmergencyCaseAttachmentsPending = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.isEmergencyCaseAttachmentUpdating;

export const getIsEmergencyCaseAttachmentsSuccess = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.emergencyCaseAttachmentUpdateSuccess;

export const getEmergencyCaseAttachmentsError = (state: RootState) =>
  state.emergencyCaseConfirmationReducer.emergencyCaseAttachmentUpdateError;

export const updateEmergencyCaseAttachmentsResult = createSelector(
  [
    getEmergencyCaseAttachments,
    getIsEmergencyCaseAttachmentsPending,
    getIsEmergencyCaseAttachmentsSuccess,
    getEmergencyCaseAttachmentsError,
  ],
  (
    uploadedAttachments,
    isEmergencyCaseAttachmentsPending,
    isEmergencyCaseAttachmentsSuccess,
    emergencyCaseAttachmentsError
  ) => {
    return {
      uploadedAttachments,
      isEmergencyCaseAttachmentsPending,
      isEmergencyCaseAttachmentsSuccess,
      emergencyCaseAttachmentsError,
    };
  }
);
