import classNames from "classnames";
import React from "react";
import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { Color } from "../../colors";

export interface IndicatorInterface {
  dotColor: Color;
  children: string | number;
  title?: string;
}

const Indicator: React.FC<IndicatorInterface> = ({
  dotColor = "csis",
  children,
  title,
}) => {
  const classes = classNames("indicator", "dot", {
    "dot--csis": dotColor === "csis",
    "dot--red": dotColor === "red",
    "dot--orange": dotColor === "orange",
    "dot--blue": dotColor === "blue",
    "dot--grey": dotColor === "grey",
  });

  return (
    <div className={classes} title={title}>
      <Stack align="center">
        <Icon kind="dot" size="tiny" />
        <div className="f_semibold">{children}</div>
      </Stack>
    </div>
  );
};

export default Indicator;
