import { axiosCsisApi } from "@csis.com/tip/src/App";
import { MdfeForm } from "../types";

export async function fetchIsValidFormApi(id: string) {
  return axiosCsisApi.getMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdGet(
    id
  );
}

export async function postFormByIdApi(form: MdfeForm, id: string) {
  return axiosCsisApi.createMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdPost(
    id,
    form
  );
}
