export const alertKeys = {
  ID: "id",
  DISPLAY_ID: "display_id",
  STATUS: "status",
  SEVERITY: "severity",
  TITLE: "title",
  RELEASED: "released",
  ALERTER: "alerter",
  UPDATED: "updated",
  TEXT: "text",
  ATTACHED_TICKET: "ticket",
  ATTACHMENTS: "attachments",
  TACTICS: "mitre_tactics",
  TECHNIQUES: "mitre_techniques",
} as const;
