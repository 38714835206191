import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Carousel, Table } from "@csis.com/components";
import { OnRowClick } from "@csis.com/components/src/atoms/Table/types";
import urlTemplates from "@csis.com/tip/src/routes/urlTemplates";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import { VictimsColumns } from "../../CompromisedData/VictimsInfo/Search/Table/columns";
import {
  emailCell,
  sourceCell,
} from "../../CompromisedData/VictimsInfo/Search/Table/tableCellFormatters";
import { victimsInfoKeys } from "../../CompromisedData/VictimsInfo/Search/constants";
import { getCompromisedDataResult } from "../../CompromisedData/VictimsInfo/Search/selectors";
import { fetchCompromisedData } from "../../CompromisedData/VictimsInfo/Search/slice";
import { VictimsInfoRow } from "../../CompromisedData/VictimsInfo/Search/types";
import { StatusPieChart } from "../../CompromisedData/VictimsInfo/Statistics/Status/StatusPieChart";
import { getStatusDistributionResult } from "../../CompromisedData/VictimsInfo/Statistics/Status/selectors";
import { fetchStatusDistribution } from "../../CompromisedData/VictimsInfo/Statistics/Status/slice";
import { StatusValues } from "../../CompromisedData/VictimsInfo/models/Status";
import ProductCard from "./ProductCard/ProductCard";
import { ELEMENTS_PER_TABLE, REQUEST_DELAY_MS } from "./constants";

const columns: VictimsColumns = [
  { key: victimsInfoKeys.EMAIL, name: "email" },
  { key: victimsInfoKeys.USERNAME, name: "username" },
  { key: victimsInfoKeys.SOURCE, name: "source" },
];

const slidesInfo = [
  {
    subtitle: "Status Distributions",
    viewAllUrl: urls.products_compromiseddata_victims_statistics,
  },
  {
    subtitle: "Latest Credentials",
    viewAllUrl: urls.products_compromiseddata_victims_search,
  },
];

export default function CredentialsProductCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedOrgId = useSelector(getSelectedOrgId);

  const [requestDelay, setRequestDelay] = useState<number>(REQUEST_DELAY_MS);
  const { t } = useTranslations();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  function handleCarouselChange(newSlideIndex: number) {
    setCurrentSlideIndex(newSlideIndex);
  }

  useEffect(() => {
    dispatch(
      fetchStatusDistribution({
        [victimsInfoKeys.SUBMITTED_AFTER]: startDate,
        [victimsInfoKeys.SUBMITTED_BEFORE]: endDate,
      })
    );

    const timer = setTimeout(() => {
      dispatch(
        fetchCompromisedData({
          [victimsInfoKeys.START_DATE]: startDate,
          [victimsInfoKeys.END_DATE]: endDate,
        })
      );
      setRequestDelay(0);
    }, requestDelay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate, selectedOrgId]);

  const {
    compromisedData,
    isCompromisedDataPending,
    compromisedDataFetchError,
  } = useSelector(getCompromisedDataResult);

  const handleRowClick: OnRowClick<VictimsInfoRow> = (row) => {
    history.push(
      urlTemplates._products_compromiseddata_victims_report_id(row.id)
    );
  };

  const formatters = useMemo(
    () => ({
      [victimsInfoKeys.EMAIL]: emailCell(),
      [victimsInfoKeys.SOURCE]: sourceCell(),
    }),
    []
  );

  const { statusDistributions, isStatusDistributionPending } = useSelector(
    getStatusDistributionResult
  );

  const totalNewReports =
    statusDistributions?.find((element) => element.name === StatusValues.NEW)
      ?.count || 0;

  const newReportsUrl = getNewLocationUrl(
    urls.products_compromiseddata_victims_search,
    {
      [victimsInfoKeys.STATUS]: [StatusValues.NEW],
      [victimsInfoKeys.START_DATE]: startDate,
      [victimsInfoKeys.END_DATE]: endDate,
    }
  );

  return (
    <ProductCard dataTestId="credentials-product-card">
      <ProductCard.Header
        title={t("compromised_credentials")}
        subTitle={slidesInfo[currentSlideIndex]?.subtitle}
        viewAllUrl={slidesInfo[currentSlideIndex]?.viewAllUrl}
      />
      <ProductCard.Carousel>
        <Carousel
          onChange={handleCarouselChange}
          name="credentials-carousel"
          hasAutoplay
          isLoading={isStatusDistributionPending}
          items={[
            <StatusPieChart startDate={startDate} endDate={endDate} />,
            <Table
              name={"compdata-productcard-table"}
              columns={columns}
              rows={compromisedData?.slice(0, ELEMENTS_PER_TABLE)}
              isLoading={isCompromisedDataPending}
              error={compromisedDataFetchError}
              ghostRows={ELEMENTS_PER_TABLE}
              isFullWidth
              hasUnlimitedCellWidth
              size="small"
              columnNameTransform={t}
              formatters={formatters}
              onRowClick={handleRowClick}
            />,
          ]}
        />
      </ProductCard.Carousel>
      <ProductCard.Footer
        text={`${totalNewReports} ${t("new_reports")}`}
        url={newReportsUrl}
      />
    </ProductCard>
  );
}
