import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  Form,
  FormLabel,
  GenericFormError,
  Grid,
  GridItem,
  Input,
  Stack,
} from "@csis.com/components";
import { RetainerUpdateBody } from "../api/types";
import { retainerKeys } from "../constants";
import { getRetainer, getUpdateRetainerResult } from "../selectors";
import { resetUpdateRetainerState, updateRetainer } from "../slice";

const EditRetainerDialog = ({
  onDialogClose,
  onSuccess,
}: {
  onDialogClose: () => void;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch();
  const retainer = useSelector(getRetainer);

  const { retainerUpdateSuccess, isRetainerUpdating, retainerUpdateError } =
    useSelector(getUpdateRetainerResult);

  const [retainerDraft, setRetainerDraft] = useState<RetainerUpdateBody>({
    [retainerKeys.NAME]: retainer?.[retainerKeys.NAME],
    [retainerKeys.PHONE]: retainer?.[retainerKeys.PHONE],
  });

  const handleChangeField = (newValue: string, fieldKey: string) => {
    setRetainerDraft((prevState) => ({
      ...prevState,
      [fieldKey]: newValue,
    }));
  };

  const handleFormSubmit = () => {
    dispatch(
      updateRetainer({
        retainerId: retainer?.external_id || "",
        retainer: retainerDraft,
      })
    );
  };

  useEffect(() => {
    if (retainerUpdateSuccess && onSuccess) {
      onSuccess();
    }
  }, [onSuccess, retainerUpdateSuccess]);

  useEffect(() => {
    // runs when component unmounts
    return () => {
      dispatch(resetUpdateRetainerState());
    };
  }, [dispatch]);

  return (
    <Dialog
      name="retainer-information-dialog"
      onClose={onDialogClose}
      header="Edit Retainer Information"
    >
      <Form id="retainer-information-form" onFormSubmit={handleFormSubmit}>
        <Stack isVertical gutterSize="large" align="stretch">
          <Stack isVertical>
            <FormLabel
              text="Primary Retainer contact name"
              htmlFor="primary_contact_name"
            />
            <Input
              name={"primary_contact_name"}
              type="text"
              isOptional
              isFullWidth
              placeholder="Jane Doe"
              value={retainerDraft[retainerKeys.NAME]}
              onChange={handleChangeField}
            />
          </Stack>
          <Stack isVertical>
            <FormLabel
              text="Primary Retainer phone number"
              htmlFor="primary_phone_number"
            />
            <Input
              name={"primary_phone_number"}
              type="tel"
              isOptional
              isFullWidth
              placeholder="+XX 0123456789"
              value={retainerDraft[retainerKeys.PHONE]}
              onChange={handleChangeField}
            />
          </Stack>
          {retainerUpdateError && (
            <GenericFormError errorText={retainerUpdateError} />
          )}

          <Grid gutterSize="big">
            <GridItem span={6}>
              <Button
                text={"Cancel"}
                name="cancel-edit-retainer-btn"
                color="grey"
                onButtonClick={onDialogClose}
                isFullWidth
              />
            </GridItem>
            <GridItem span={6}>
              <Button
                text={"Save Changes"}
                name="save-changes-btn"
                onButtonClick={() => {}}
                formId="retainer-information-form"
                isLoading={isRetainerUpdating}
                isFullWidth
              />
            </GridItem>
          </Grid>
        </Stack>
      </Form>
    </Dialog>
  );
};

export default EditRetainerDialog;
