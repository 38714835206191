import classNames from "classnames";
import React from "react";
import Icon, { IconKind } from "../../icons/Icon";
import { BIG, NORMAL, SMALL } from "../../sizes";
import Link from "../Link/Link";

export interface BreadcrumbInterface {
  label?: string;
  size?: SMALL | NORMAL | BIG;
  isActive?: boolean;
  icon?: IconKind;
  href: string;
}

const Breadcrumb: React.FC<BreadcrumbInterface> = ({
  icon,
  label,
  size,
  href,
  isActive,
}) => {
  const classes = classNames("breadcrumbs__breadcrumb", {
    "breadcrumbs__breadcrumb--active": isActive,
  });
  return (
    <Link
      href={href}
      ariaLabel={label || icon}
      ariaCurrent={isActive ? "location" : undefined}
      withStyling={false}
    >
      <div className={classes}>
        {icon && (
          <Icon
            kind={icon}
            size={size === "small" ? "big" : "large"}
            color={isActive ? undefined : "grey"}
          />
        )}
        {label}
      </div>
    </Link>
  );
};

export default Breadcrumb;
