import * as tableTypes from "@csis.com/components/src/atoms/Table/types";
import {
  SeverityClassColors as AlertsSeverityClassColors,
  SeverityTranslationKeys as AlertsSeverityTranslationKeys,
  isSeverity as isAlertsSeverity,
} from "@csis.com/tip/src/pages/Alerts/models/Severity";
import {
  SeverityClassColors as TicketsSeverityClassColors,
  SeverityTranslationKeys as TicketsSeverityTranslationKeys,
  isSeverity as isTicketsSeverity,
} from "@csis.com/tip/src/pages/Tickets/models/Severity";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const alertsCountCell: tableTypes.TableCellFormatter<string> = (
  row,
  column
) => {
  const cell = row[column];
  const severity = row.name;

  let colorClass = "";
  if (isAlertsSeverity(severity)) {
    colorClass = AlertsSeverityClassColors[severity];
  }

  return <span className={"f_semibold " + colorClass}>{String(cell)}</span>;
};

export function alertsSeverityCell(
  t: (key: TranslationKey, args?: string[]) => string
): tableTypes.TableCellFormatter<string> {
  return (row, column) => {
    const severity = row[column];
    let colorClass = "";
    if (isAlertsSeverity(severity)) {
      colorClass = AlertsSeverityClassColors[severity];

      return (
        <span className={"f_semibold f_uppercase " + colorClass}>
          {t(AlertsSeverityTranslationKeys[severity])}
        </span>
      );
    }
    return <>{severity}</>;
  };
}

export const ticketsCountCell: tableTypes.TableCellFormatter<string> = (
  row,
  column
) => {
  const cell = row[column];
  const severity = row.name;

  let colorClass = "";
  if (isTicketsSeverity(severity)) {
    colorClass = TicketsSeverityClassColors[severity];
  }
  if (severity === "other") {
    colorClass = "f_blue";
  }
  return <span className={"f_semibold " + colorClass}>{String(cell)}</span>;
};

export function ticketsSeverityCell(
  t: (key: TranslationKey, args?: string[]) => string
): tableTypes.TableCellFormatter<string> {
  return (row, column) => {
    const severity = row[column];
    let colorClass = "";
    if (isTicketsSeverity(severity)) {
      colorClass = TicketsSeverityClassColors[severity];

      return (
        <span className={"f_semibold f_uppercase " + colorClass}>
          {t(TicketsSeverityTranslationKeys[severity])}
        </span>
      );
    }
    if (severity === "other") {
      return <span className={"f_semibold f_uppercase f_blue"}>{"other"}</span>;
    }
    return <>{severity}</>;
  };
}
