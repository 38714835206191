import { OpenAPIV3 } from "openapi-types";

export type DocumentationSection = {
  sectionName?: string;
  tags: Set<string>;
  paths: {
    endpoint: string;
    operations: EndpointOperation[];
  }[];
};

export type EndpointOperation = {
  method: HttpMethod;
  summary: string | undefined;
  description: string | undefined;
  tags: string[] | undefined;
  parameters: OpenAPIV3.ParameterObject[] | undefined;
  operationId: string | undefined;
  responses?: OpenAPIV3.ResponsesObject | undefined;
  requestBody?: OpenAPIV3.RequestBodyObject | undefined;
  security?: OpenAPIV3.SecurityRequirementObject[] | undefined;
};

export type HttpMethod = `${Uppercase<OpenAPIV3.HttpMethods>}` | undefined;

export function isReferenceObject(x: any): x is OpenAPIV3.ReferenceObject {
  return x.$ref !== undefined;
}

export function isSchemaObject(obj: any): obj is OpenAPIV3.SchemaObject {
  return obj && typeof obj === "object" && ("type" in obj || "anyOf" in obj);
}
