import { useState } from "react";
import { useInterval } from "@csis.com/tip/src/utils/useInterval";
import { twoDigits } from "./utils";

// A hh:mm:ss timer that counts down given an initialCount in seconds
export default function CountdownTimer({
  initialCount, // SECONDS!
  tooltipText,
  stopAtZero,
  dataTestId,
}: {
  initialCount: number;
  tooltipText?: string;
  stopAtZero?: boolean;
  dataTestId?: string;
}) {
  const [secondsRemaining, setSecondsRemaining] = useState(initialCount);

  const absoluteSecondsRemaining = Math.abs(secondsRemaining);
  const secondsToDisplay = absoluteSecondsRemaining % 60;
  const minutesRemaining = (absoluteSecondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

  useInterval(
    () => {
      if (secondsRemaining > 0 || stopAtZero === false) {
        setSecondsRemaining(secondsRemaining - 1);
      }
    },
    1000
    // passing null stops the interval
  );
  return (
    <div data-test-id={dataTestId} title={tooltipText}>
      {secondsRemaining < 0 ? "-" : ""}
      {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
      {twoDigits(secondsToDisplay)}
    </div>
  );
}
