import { insightsKeys } from "../constants";
import { QueryParams } from "../types";

// mapper for api requests to transform start_date and end_date to appropriate query params
export function mapInsightsStartAndEndDateToCorrectType(
  queryParams: Partial<QueryParams>
) {
  return Object.assign({}, queryParams, {
    [insightsKeys.CREATED_AFTER]: queryParams[insightsKeys.START_DATE],
    [insightsKeys.CREATED_BEFORE]: queryParams[insightsKeys.END_DATE],
  });
}
