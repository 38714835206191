// a helper function that receives a Blob
// and creates a download file process with the file

export function downloadBlobForUser(blob: Blob, filename: string) {
  const exportUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = exportUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
