import { axiosCsisApi } from "@csis.com/tip/src/App";
import { CredentialsSetting } from "../../types";

export async function fetchCredentialsSettingsApi() {
  return axiosCsisApi.getVictimNotificationPreferencesApi10ProfilePreferenceNotificationVictimReportGet();
}

export async function patchCredentialsSettingsApi(
  settings: CredentialsSetting
) {
  return axiosCsisApi.patchVictimNotificationPreferenceApi10ProfilePreferenceNotificationVictimReportPatch(
    // @ts-ignore can be null - api type is wrong
    settings
  );
}
