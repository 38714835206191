const Pacman = () => {
  return (
    <div className="pacman">
      <div className="pacman__top" />
      <div className="pacman__bottom" />
      <div className="pacman__404">
        4 <span>0</span> 4
      </div>
      <div className="pacman__feed" />
    </div>
  );
};

export default Pacman;
