import classNames from "classnames";
import React, { useCallback } from "react";
import Icon from "../../../icons/Icon";
import { BLUE, RED } from "../../../colors";
import { SMALL } from "../../../sizes";

export interface SearchableCellInterface {
  color?: RED | BLUE;
  onClick?: () => void;
  children: React.ReactNode;
  size?: SMALL;
  dataTestId?: string;
}

const SearchableCell: React.FC<SearchableCellInterface> = ({
  color,
  children,
  size,
  onClick,
  dataTestId,
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onClick) {
        onClick();
      }
    },
    [onClick]
  );

  const classes = classNames("searchable-cell", {
    "searchable-cell--blue": color === "blue",
    "searchable-cell--red": color === "red",
    "searchable-cell--small": size === "small",
  });

  return (
    <div
      title="Search by this field"
      className={classes}
      onClick={handleClick}
      data-test-id={dataTestId}
    >
      <div className={classes}>{children}</div>
      <div className="searchable-cell__icon">
        <Icon kind="global_search" size="small" />
      </div>
    </div>
  );
};

export default SearchableCell;
