import { PASSWORD_CRITERIA, PASSWORD_MIN_LENGTH } from "./constants";

export function checkPasswordStrength(password: string) {
  if (password.length === 0) {
    return 0;
  }

  let strength = 0;

  for (let i = 0; i < PASSWORD_CRITERIA.length; i++) {
    // @ts-expect-error
    if (new RegExp(PASSWORD_CRITERIA[i]).test(password)) {
      strength += 100 / PASSWORD_CRITERIA.length;
    }
  }

  if (password.length < PASSWORD_MIN_LENGTH) {
    strength = 25;
  }

  return strength;
}
