import { useSelector } from "react-redux";
import { defaultPreferences } from "../../userPreferences/defaultPreferences";
import { DateSpan, DateSpanMode } from "../shared/DateSpan/DateSpan";
import { getUserPrefsLocalization } from "../shared/DateSpan/selectors";

// This component "wraps" the DateSpan
// since we are reading the user preferences from the redux-store
// we need to read from the selector and pass the data down so we keep
// DateSpan "redux agnostic" and be used by other external projects
// keeping up with the philosophy that TIP components can be used outside of TIP
// so in order to use the DateSpan outside of TIP
// one needs to write its own DateSpanWrapper and create the "localization" there
export function DateSpanWrapper({
  date,
  mode,
}: {
  date?: string | null;
  mode?: DateSpanMode;
}) {
  const localization =
    useSelector(getUserPrefsLocalization) || defaultPreferences.localization;

  return <DateSpan date={date} mode={mode} localization={localization} />;
}
