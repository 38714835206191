import * as tableTypes from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { alertsKeys } from "../constants";
import { AlertsKey } from "../types";

export type AlertsColumns = tableTypes.ColumnInterface<
  AlertsKey,
  TranslationKey
>[];

export const columns: AlertsColumns = [
  { key: alertsKeys.TITLE, name: "title", isSortable: true },
  {
    key: alertsKeys.STATUS,
    name: "status",
    isSortable: true,
    isCenterAligned: true,
  },
  {
    key: alertsKeys.SEVERITY,
    name: "severity",
    isSortable: true,
    isCenterAligned: true,
  },
  { key: alertsKeys.RELEASED, name: "release_date", isSortable: true },
  { key: alertsKeys.ALERTER, name: "alerter", isSortable: true },
  {
    key: alertsKeys.DISPLAY_ID,
    name: "id",
    isSortable: true,
    isHidden: true,
  },
];
