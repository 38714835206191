import classNames from "classnames";
import React from "react";

export interface SwitchInterface {
  isOn?: boolean;
  // a "middle" state to indicate that is not on or off. This can only be set programatically and cannot be set by the user
  // to be only used for a switch button that aggregates more switch buttons
  isMiddleState?: boolean;
  name: string;
  tabIndex?: number;
  onToggle: () => void;
  isDisabled?: boolean;
  dataTestId?: string;
  tooltipText?: string;
}

const Switch: React.FC<SwitchInterface> = ({
  isOn,
  isMiddleState,
  isDisabled,
  onToggle,
  tabIndex = 0,
  name,
  dataTestId,
  tooltipText,
}) => {
  const handleChange = React.useCallback(() => {
    onToggle();
  }, [onToggle]);

  // when the switch is focused we can check/uncheck it with space
  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (event.key === " ") {
        onToggle();
      }
    },
    [onToggle]
  );

  const classes = classNames("switch", {
    "switch--on": isOn,
    "switch--middle": isMiddleState,
    "switch--disabled": isDisabled,
  });
  return (
    <label
      title={tooltipText}
      htmlFor={name}
      className={classes}
      onClick={(e) => e.stopPropagation()}
      data-test-id={dataTestId}
      tabIndex={tabIndex}
      onKeyPress={handleKeyPress}
    >
      <span className="switch__slider" />
      <input
        defaultChecked={isOn}
        onChange={handleChange}
        type="checkbox"
        id={name}
        title={tooltipText}
        role="switch"
        aria-checked={isOn}
      />
    </label>
  );
};

export default Switch;
