import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  IPRangeCreateBody,
  IPRangeUpdateBody,
} from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchIpRangesApi() {
  return axiosCsisApi.getIpRangesApi10OrganizationIpRangeGet();
}

export async function postIpRangesApi(data: IPRangeCreateBody) {
  return axiosCsisApi.addIpRangeApi10OrganizationIpRangePost(data);
}

export async function updateIpRangesApi(
  externalId: string,
  data: IPRangeUpdateBody
) {
  return axiosCsisApi.updateIpRangeApi10OrganizationIpRangeExternalIdPut(
    externalId,
    data
  );
}

export async function deleteIpRangesApi(externalId: string) {
  return axiosCsisApi.deleteRangeApi10OrganizationIpRangeExternalIdDelete(
    externalId
  );
}
