type Id = string;

const urlTemplates = {
  _products_phishing_urls_url_id: (id: Id) =>
    `/products/anti-phishing/urls/url/${id}`,
  _alerts_alert_id: (id: Id) => `/alerts/alert/${id}`,
  _tickets_ticket_id: (id: Id) => `/tickets/ticket/${id}`,
  _admin_retainers_retainer_id: (id: Id) => `/admin/retainers/retainer/${id}`,
  _admin_emergency_case_id: (id: Id) => `/admin/emergency/case/${id}`,
  _products_compromiseddata_cc_report_id: (id: Id) =>
    `/products/payment-cards-reports/report/${id}`,
  _products_compromiseddata_victims_report_id: (id: Id) =>
    `/products/credential-reports/report/${id}`,
  _products_threatinsights_article_id: (id: Id) =>
    `/products/threat-insights/article/${id}`,
  // EC confirmation
  _emergency_case_confirmation_id: (id: Id) => `/emergency-response/case/${id}`,

  // MDR
  _onboarding_mdfecustomer_id: (id: Id) => `/onboarding/mdfe-customer/${id}`,
} as const;

export default urlTemplates;
