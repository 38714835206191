/* eslint-disable sonarjs/no-duplicate-string */
import classNames from "classnames";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Button, IconMenu, Input, Link, Stack } from "@csis.com/components";
import { LinkWithOrgId } from "@csis.com/tip/src/components/LinkWithOrgId/LinkWithOrgId";
import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import useThemePreference from "../../hooks/useThemePreference";
import { DarkThemeSwitch } from "../DarkThemeSwitch/DarkThemeSwitch";
import News from "../News/News";
import OnboardingHelp from "../OnboardingHelp/OnboardingHelp";
import { getSelectedOnboardingStep } from "../OnboardingHelp/selectors";

const GlobalSearch = ({ id }: { id?: string }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslations();

  const [searchString, setSearchString] = useState("");

  const handleInputChange = (value: string) => {
    setSearchString(value);
  };

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        history.push(
          `${urls.globalsearch}?${searchKeys.SEARCH_STRING}=${searchString}`
        );
      }
    },
    [history, searchString]
  );

  return (
    <div
      style={{
        visibility:
          location.pathname === urls.globalsearch ? "hidden" : undefined,
      }}
      className="global-search"
    >
      <Input
        name={id ? "global_search_" + id : "global_search"}
        dataTestId={id ? "global-search_" + id : "global-search"}
        type="text"
        isSemiTransparent
        icon="global_search"
        value={searchString}
        hasShadow={false}
        placeholder={t("search_anything_here")}
        onKeyPress={handleKeyPress}
        onChange={handleInputChange}
        onFocusTransform={{ hasShadow: true }}
      />
    </div>
  );
};
const PageTitle = ({
  pageTitle,
  pageSubTitle,
}: {
  pageTitle?: string;
  pageSubTitle?: string;
}) => {
  return (
    <div className="page-title" role="heading" aria-level={1}>
      {pageTitle && <span>{pageTitle}</span>}
      {pageSubTitle && (
        <span className="f_semibold">{" - " + pageSubTitle}</span>
      )}
    </div>
  );
};

export const AccountMenu = ({ id }: { id?: string }) => {
  const selectedOnboardingStep = useSelector(getSelectedOnboardingStep);

  const classes = classNames("app-header__account-menu", {
    "onboarding-highlight": selectedOnboardingStep === "topbar-account",
  });
  const { themePreference, handleToggleTheme, handleToggleSystemTheme } =
    useThemePreference();

  return (
    <div className={classes}>
      <IconMenu
        name={id ? "profile-menu_" + id : "profile-menu"}
        icon="account"
        type="text"
        color="secondary"
        size="large"
        spacing="small"
        opensFromRight
        dataTestId={
          id ? "profile-menu-menuicon_" + id : "profile-menu-menuicon"
        }
        onSelect={() => null}
        options={[
          <div className="app-header__accountmenu-element app-header__accountmenu-element--first">
            <Stack isVertical align="center">
              <DarkThemeSwitch
                switchName="appheader-theme-switch"
                themePreference={themePreference}
                onToggleTheme={handleToggleTheme}
                onToggleSystemTheme={handleToggleSystemTheme}
                displaySystemToggle
                isInline
              />
            </Stack>
          </div>,
          <div className="app-header__accountmenu-element--link">
            <LinkWithOrgId
              withStyling={false}
              href={urls.settings_account}
              isFullWidth
              text="Account"
              dataTestId="account-menu-account"
            />
          </div>,
          <div className="app-header__accountmenu-element--link">
            <LinkWithOrgId
              withStyling={false}
              href={urls.settings_account_notifications}
              isFullWidth
              text="Notifications"
              dataTestId="account-menu-notifications"
            />
          </div>,
          <div className="app-header__accountmenu-element app-header__accountmenu-element--last">
            <Link
              href={urls.logout}
              isFullWidth
              icon="logout"
              text="Sign Out"
            />
          </div>,
        ]}
      />
    </div>
  );
};

export interface AppHeaderInterface {
  pageTitle?: string;
  pageSubTitle?: string;
}
const AppHeader: FC<AppHeaderInterface> = memo(
  ({ pageTitle, pageSubTitle }) => {
    useEffect(() => {
      document.title = pageTitle
        ? pageTitle + " - " + pageSubTitle
        : "CSIS Threat Intelligence Portal";
    }, [pageTitle, pageSubTitle]);

    return (
      <>
        <div className="app-header--mobile-below">
          <Stack justify="space-between" align="center" isExpanded>
            <GlobalSearch id={"secondary"} />
            <AccountMenu id={"secondary"} />
          </Stack>
        </div>
        <header className="app-header">
          <Stack justify="space-between" align="center">
            <GlobalSearch />
            <PageTitle pageTitle={pageTitle} pageSubTitle={pageSubTitle} />
            <div className="app-header-right">
              <Stack align="center" justify="right" gutterSize="big">
                <div className="news hide-on-mobile">
                  <Button
                    color="red"
                    size="small"
                    name="emergency response button"
                    text="emergency response"
                    icon="alert"
                    onButtonClick={() => {
                      window.open(
                        "https://www.csis.com/emergency-response/",
                        "_blank"
                      );
                    }}
                  />
                </div>
                <News />
                <OnboardingHelp />
                <AccountMenu />
              </Stack>
            </div>
          </Stack>
        </header>
      </>
    );
  }
);

export default AppHeader;
