import React from "react";

export interface NoDataInterface {
  text?: string;
}

const NoData: React.FC<NoDataInterface> = ({ text }) => {
  return (
    <div className="nodata">
      <div className="nodata__mockchart">
        <div className="nodata__mockchart__bar" />
        <div className="nodata__mockchart__bar" />
        <div className="nodata__mockchart__bar" />
        <div className="nodata__mockchart__bar" />
        <div className="nodata__mockchart__bar" />
      </div>
      <div className="nodata__title-container">
        <div className="nodata__title f_secondary f_semibold">
          {text ? text : "No data"}
        </div>
      </div>
    </div>
  );
};
export default NoData;
