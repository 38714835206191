const InlineFormError = ({ errorText }: { errorText?: string | null }) => {
  if (!errorText) {
    return null;
  }
  return (
    <div
      className="form__inline-error f_small f_semibold f_red"
      style={{ whiteSpace: "pre-line" }}
    >
      {errorText}
    </div>
  );
};

export default InlineFormError;
