import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const insightsKeys = {
  ID: "id",
  DISPLAY_ID: "display_id",
  TITLE: "title",
  SUMMARY: "summary",
  CATEGORY: "category",
  SEVERITY: "severity",
  WORDCOUNT: "wordcount",
  TAGS: "tags",
  CREATED: "created",
  IS_FEATURED: "is_featured",
  UPDATED: "updated",
  CREATED_AFTER: "created_after",
  CREATED_BEFORE: "created_before",
  TLP: "traffic_light_protocol",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [
  insightsKeys.SEVERITY,
  insightsKeys.CATEGORY,
  insightsKeys.TAGS,
  insightsKeys.SEARCH_STRING,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TWENTYFIVE;
export const TOOLTIP_TAGS_AND_FILTERS = "Add this filter to the search";
